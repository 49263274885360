import { createSlice } from "@reduxjs/toolkit";

const homeSlice=createSlice({
    name:"homes",
    initialState:{
        feeds:[],
        totalPage:0,
        count:1
    },
    reducers:{
        setHomeStateData:(state,action)=>{
            state[`${action.payload.key}`]=action.payload.value
        }
    }
})

export const {setHomeStateData}=homeSlice.actions

export default homeSlice.reducer