import { Avatar, Badge, Box, Button, Typography } from "@mui/material";
import { minHeight } from "@mui/system";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import SearchBar from "../components/Home/SearchBar";
import EnterTime from "../components/EnterTime";
import { pxToRem } from "../theme/typography";
import DenyReasonModal from "../components/DenyReasonModal";
import hit from "../services/apiManager";
import { image_url, post, teams } from "../services/endpoints";
import showToast from "../utils/showToast";
import { setLoading } from "../store/load";
import moment from "moment";
import _ from "lodash";

export default function Interaction() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false)
    const [open1, setOpen1] = useState(false)
    const { postid } = useLocation().state
    const [search, setSearch] = useState("")
    const [search1, setSearch1] = useState("")

    const [selectAll, setSelectAll] = useState(false)
    const [postDetail, setPostDetail] = useState(null)
    const [friends, setFriends] = useState([
    ])
    const [friends1, setFriends1] = useState([
    ])
    const [selected, setSelected] = useState([])
    const [unselectall, setUnselectall] = useState(false)
    const [denySelction, setDenySelction] = useState([])
    const [addUser, setAddUser] = useState(null)
    const [alreadyMemb, setAlreadyMemb] = useState([])
    const user = useSelector(state => state.auth?.user)
    const [hour, setHour] = useState("")
    const [minute, setMinute] = useState("")
    useEffect(() => {
        getAllIntercations(postid)
        getPostDetails(postid)
    }, [postid])

 

    const getAllIntercations = async (id) => {
        try {
            let res = await hit(`${post?.interact}?postId=${id}`, "get")
            if (res?.err) {
                showToast(res?.msg)
            } else {
                // setFriends()
                setFriends(_.sortBy(res?.data,[function(o) { return o?.user?.lastName?.toLowerCase(); }]))
                let temp = []
                res?.data?.forEach(e => {
                    temp.push(e?.user?._id)
                })
                setAlreadyMemb(temp)
            }
        } catch (e) {
        } finally {
        }
    }

    const getPostDetails = async (postId) => {
        try {
            let res = await hit("/posts/" + postId, "get")
            if (!res.err) {
                setPostDetail(res.data)
                let temp = []
                res?.data?.groups?.forEach(e => {
                    temp.push(e?._id)
                });
                getTeamMembers(temp)
            }
        } catch (err) {

        }
    }
    const getTeamMembers = async (temp) => {
        try {
            let res = await hit(`${teams?.members}`, "post", { "ids": temp })
            if (res?.err) {
                showToast(res?.msg)
            } else {
                let members = []
                res?.data?.forEach(e => {
                    members = [...e?.members]
                })
                let uniqueMembers=[]
                for(let member of members){
                    if(uniqueMembers?.map(x=>x._id)?.includes(member._id)){
                        continue
                    }
                    uniqueMembers.push(member)
                }
                
                // let uniqueMembers = _.uniqWith(members, (arrVal, othVal) => arrVal._id === othVal._id).filter(x => x._id != user?._id)
                setFriends1(_.sortBy(uniqueMembers,[function(o){return String(o?.lastName).toLowerCase()}]))
            }
        } catch (e) {
        } finally {
            dispatch(setLoading(false))
        }
    }
    const submit = async () => {
        try {
            dispatch(setLoading(true))
            let res = await hit(`${post?.verify}`, "post", { "users": selected, "postId": postid })
            if (res?.err) {
                showToast(res?.msg)
            } else {
                getAllIntercations(postid)
            }
        } catch (e) {
        } finally {
            dispatch(setLoading(false))
            setSelected([])
            setDenySelction([])
        }
    }
    const denyInteractions = async () => {
        try {
            dispatch(setLoading(true))
            let res = await hit(`${post?.deny}`, "post", { "ids": denySelction })
            if (res?.err) {
                showToast(res?.msg)
            } else {
                let ins = friends.filter(x => !denySelction.includes(x._id))
                
                setFriends(_.sortBy(ins,[function(o) { return o?.user?.lastName?.toLowerCase(); }]))
                getAllIntercations(postid)
            }
        } catch (e) {
        } finally {
            dispatch(setLoading(false))
            setSelected([])
            setDenySelction([])
        }
    }

    const addMinute = async (h, m) => {
        let time = (h * 60) + m
        // return
        dispatch(setLoading(true))
        try {
            let res = await hit(`${post?.addTime}`, "post", {
                "postId": postid,
                "userId": addUser?._id,
                "checkedIn": moment().unix() * 1000,
                "checkedOut": moment().add(time, "minute").unix() * 1000
            })
            if (res?.err) {
                showToast(res?.msg)
            } else {
                getAllIntercations(postid)
                showToast("Interaction added mannually")
            }
        } catch (e) {
        } finally {
            setHour("")
            setMinute("")
            setAddUser(null)
            dispatch(setLoading(false))
        }
    }

    return (
        <Box sx={{ px: "5%", pt: "20px", display: "flex", pr: "10%", overflowY: "auto", flex: 1, width: "100%", background: "linear-gradient(119deg,#E6F6F9,white,rgb(253,243,213))" }}>
            <Box sx={{ flex: 1, minHeight: "100px" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Typography sx={{ textAlign: "left", fontWeight: "600" }}>Interaction</Typography>
                    {/* <Box component={"img"} src="assets/plusyellow.svg" sx={{height:"20px",width:"20px"}} /> */}
                </Box>
                <br />
                <SearchBar value={search} onChange={t => setSearch(t)} placeholder={"Search..."} />

                <Box sx={{ display: "flex", minHeight: "400px", px: "10px", py: "20px", marginTop: "10px", flexDirection: "column", background: "white", boxShadow: "0px 5px 20px #26262626", borderRadius: "5px", }}>
                    {/* <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <Typography sx={{ textAlign: "left", fontWeight: "600" }}>Friends</Typography>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Typography sx={{ fontSize: pxToRem(14), marginRight: "5px" }}>Select All</Typography>
                            <Checks />
                        </Box>
                    </Box> */}
                    <br />
                    {friends?.filter(x=>{
                        if(search?.trim()!=""){
                            if(String(x?.user?.firstName+" "+x?.user?.lastName+x?.user?.email)?.toLowerCase()?.includes(search?.trim()?.toLowerCase())){
                                return true
                            }else{
                                return false
                            }
                        }
                        return true
                    })?.map((item, index) => (
                        <RenderList key={item?._id} postDetail={postDetail} item={item} index={index} arr={selected} unselect={unselectall} itemPressed={() => {
                            let temp = [...selected]
                            let deny = [...denySelction]
                            let ind = temp.indexOf(item?.user?._id)
                            let ind2 = deny.indexOf(item?._id)
                            if (ind == -1) {
                                temp.push(item?.user?._id)
                                deny.push(item?._id)
                            } else {
                                temp.splice(ind, 1)
                                deny.splice(ind2, 1)
                            }
                            setSelected(temp)
                            setDenySelction(deny)
                        }} />
                    ))}
                    <br />
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Button onClick={() => {
                            // navigate(-1)
                            denyInteractions()
                        }} sx={{ fontSize: pxToRem(16), background: "#FF5A5A", borderRadius: "5px", marginX: "auto", height: "45px", width: "200px" }} variant="contained">
                            Deny
                        </Button>
                        <Button onClick={() => {
                            submit()
                        }} sx={{ fontSize: pxToRem(16), background: "#28C26B", borderRadius: "5px", marginX: "auto", height: "45px", width: "200px" }} variant="contained">
                            Verify
                        </Button>
                    </Box>
                    <br />
                </Box>
            </Box>
            <Box sx={{ flex: 1, marginLeft: "5%", }}>
                <Typography sx={{ textAlign: "left", fontWeight: "600" }}>Add Member</Typography>
                <br />
                <SearchBar value={search1} onChange={t=>{
                    setSearch1(t)
                }} placeholder={"Search User..."} />
                <Box sx={{ display: "flex", minHeight: "400px", px: "10px", py: "20px", marginTop: "10px", flexDirection: "column", background: "white", boxShadow: "0px 5px 20px #26262626", borderRadius: "5px", }}>
                    {friends1?.filter(x=>{
                        if(search1?.trim()!=""){
                            if(String(x?.firstName+" "+x?.lastName+x?.email)?.toLowerCase()?.includes(search1?.trim()?.toLowerCase())||String(x?.email)?.toLowerCase()?.includes(search1?.trim()?.toLowerCase())){
                                return true
                            }else{
                                return false
                            }
                        }
                        return true
                    })?.map((item, index) => {
                        if (alreadyMemb?.includes(item?._id)) {
                            return (
                                <></>
                            )
                        }
                        return (
                            <AddMemberComponent
                                key={item?._id}
                                item={item}
                                index={index}
                                onPress={() => {
                                    // setVis(true)
                                    setOpen(true)
                                    setAddUser(item)
                                }}

                            />
                        )
                    })}

                    <br />
                    <br />
                </Box>
            </Box>
            <EnterTime 
             open={open} 
             setOpen={setOpen} 
             setHour={setHour}
             setMinute={setMinute}
             hour={hour}
             minute={minute}
             addMinute={addMinute}
            />
            <DenyReasonModal open={open1} setOpen={setOpen1} />
        </Box>
    )
}


const CheckComponent = ({ title }) => {
    const [isChecked, setIsChecked] = useState(true)
    return (
        <Box sx={{ minHeight: "60px", width: "100%", p: "10px", display: "flex", alignItems: "center", background: "white", boxShadow: "0px 5px 20px #26262626" }}>

            <Avatar
                src="https://picsum.photos/100"
                style={{ height: "40px", width: "40px" }}
            />
            <Box sx={{ marginLeft: "15px", flex: 1, display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                <Typography sx={{ fontWeight: "600", fontSize: pxToRem(14) }}>{title}</Typography>
                <Typography sx={{ fontWeight: "400", fontSize: pxToRem(12) }}>Total Time : 2:01:20 Hours</Typography>

            </Box>
            <Box onClick={() => {
                setIsChecked(!isChecked)
            }} component={"img"} sx={{ cursor: "pointer" }} src={isChecked ? "assets/groups/ios-checkbox-outline.svg" : "assets/groups/ios-checkbox.svg"} />
        </Box>
    )
}

const Checks = () => {
    const [isChecked, setIsChecked] = useState(true)

    return (
        <Box onClick={() => {
            setIsChecked(!isChecked)
        }} component={"img"} sx={{ cursor: "pointer" }} src={isChecked ? "assets/groups/ios-checkbox-outline.svg" : "assets/groups/ios-checkbox.svg"} />
    )
}

const AddMemberComponent = ({ item, onPress, }) => {
    return (
        <Box sx={{ minHeight: "60px", width: "100%", marginTop: "20px", p: "10px", display: "flex", alignItems: "center", background: "white", boxShadow: "0px 5px 20px #26262626" }}>

            <Avatar
                src={image_url + item?.profilePic}
                style={{ height: "40px", width: "40px" }}
            />
            <Box sx={{ marginLeft: "15px", flex: 1, display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                <Typography sx={{ fontWeight: "600", fontSize: pxToRem(14) }}>{item?.firstName} {item?.lastName}</Typography>
                <Typography sx={{ fontWeight: "400", fontSize: pxToRem(12) }}>{item?.email}</Typography>

            </Box>
            <Button onClick={onPress} variant="contained" >Add Member</Button>
        </Box>
    )
}



const RenderList = ({ item, index, postDetail, itemPressed, arr, unselect }) => {
    const [selected, setSelected] = useState(false)
    const [totalTime, setTotalTime] = useState(0)

    useEffect(() => {
        if (item?.isVerified) {
            setSelected(item?.isVerified)
        }
    }, [item])
    useEffect(() => {
        if (unselect == true) {
            setSelected(false)
        }
    }, [unselect])
    useEffect(() => {
        if (arr?.includes(item?._id)) {
            setSelected(true)
        }
    }, [arr])
    useEffect(() => {
        let time = 0;
        if (postDetail) {
            item?.checked_ins?.forEach((e, j) => {
                if (item?.checked_outs[j]) {
                    time = time + moment(item?.checked_outs[j]).diff(moment(e), "second")
                } else {
                    if (moment(postDetail?.scoreEndTime).toDate() > moment().toDate()) {
                        time = time + moment().diff(moment(e), "second")
                    } else {
                        time = time + moment(postDetail?.scoreEndTime).diff(moment(e), "second")
                    }
                }
            });
        }
        setTotalTime(time)
    }, [item, postDetail])

    const secondsToHHMMSS = (seconds) => {
        const HH = `${Math.floor(seconds / 3600)}`.padStart(2, '0');
        const MM = `${Math.floor(seconds / 60) % 60}`.padStart(2, '0');
        const SS = `${Math.floor(seconds % 60)}`.padStart(2, '0');
        return [HH, MM, SS].join(':');
    };

    return (
        <Box
            onClick={() => {
                if (item?.isVerified) {
                    return
                } else {
                    setSelected(!selected)
                    itemPressed()
                }
            }}
            sx={{ display: 'flex', cursor: "pointer", alignItems: 'center', justifyContent: 'space-between', marginHorizontal: "18px", backgroundColor: "white", padding: "8px", marginBottom: "16px", borderRadius: "4px", boxShadow: "0px 5px 20px #26262626" }}>
            <Box sx={{ display: 'flex', alignItems: 'center', }}>
                <Box component={"img"} sx={{ height: "60px", width: "60px", borderRadius: "60px", marginRight: "8px" }} src={image_url + item?.user?.profilePic} />
                <Box>
                    <Typography sx={{ fontSize: "16px", fontWeight: "500", marginBottom: "2px", textAlign: "left" }}>{item?.user?.firstName} {item?.user?.lastName}</Typography>
                    <Typography sx={{ fontWeight: "400", fontSize: pxToRem(12) , marginBottom: "4px", textAlign: "left" }}>{item?.user?.email}</Typography>

                    <Typography sx={{ fontSize: "10px", fontWeight: "600", marginBottom: "4px" }}>Total Time : <Typography component={"span"} style={{ fontSize: "10px", fontWeight: "500", marginBottom: "4px" }}>{secondsToHHMMSS(totalTime)} Hours</Typography></Typography>
                </Box>
            </Box>
            {
                item?.isVerified ?
                    <Typography sx={{ fontSize: "10px", fontWeight: "500", marginBottom: "4px" }}>Already Verified.</Typography>
                    :
                    <Box component={"img"} sx={{ cursor: "pointer" }} src={selected ? "assets/groups/ios-checkbox.svg" : "assets/groups/ios-checkbox-outline.svg"} />
            }
        </Box>
    )
}