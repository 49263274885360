import { Avatar, Badge, Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import hit from "../services/apiManager";
import { image_url, updateUser } from "../services/endpoints";
import { getUserDetailThunk } from "../store/auth";
import { getAllTeamThunk } from "../store/team";
import { pxToRem } from "../theme/typography";
import showToast from "../utils/showToast";

export default function Notifications() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const user = useSelector(state => state?.auth.user)
    const groups = useSelector(state => state?.team?.team)
    const notiList = useSelector(state => state?.auth?.user)?.notifications
    const groupNotiEnabled = useSelector(state => state?.auth?.user)?.groupNotis

    const list = [
        { title: "App", notiType: 7 },
        { title: "Chat", notiType: 5 },
    ]
    const [allGroups, setAllGroups] = useState([]) //todisplay
    const [enabledNoti, setEnabledNoti] = useState([]) //other than group notifications
    const [groupNotis, setGroupNotis] = useState([])//group notifications
    const [appEnabled, setAppEnabled] = useState(true)

    useEffect(() => {
        if (!enabledNoti.includes(7)) {
            setAppEnabled(false)
        } else {
            setAppEnabled(true)
        }
    }, [enabledNoti])
    useEffect(() => {
        setEnabledNoti(notiList)
    }, [notiList])
    useEffect(() => {
        dispatch(getAllTeamThunk())
    }, [])

    useEffect(() => {
        setGroupNotis(groupNotiEnabled)
    }, [groupNotiEnabled])
    useEffect(() => {
        setAllGroups(groups)
    }, [groups])

    const changeNotificationStatus = async (arr, type) => {
        arr.sort()
        try {
            if (type == "app") {
                if (arr.includes(7)) {
                    if (arr.length == 1) {
                        let temp = []
                        groups.forEach(e => {
                            temp.push(e?._id)
                        });
                        let res = await hit(updateUser, "patch", { "notifications": [1, 2, 3, 4, 5, 6, 7], "groupNotis": temp })
                        if (res?.err) {
                            showToast(res?.msg, "error")
                        } else {
                            showToast("Notification settings changed.")
                        }
                        return
                    }
                    let res = await hit(updateUser, "patch", { "notifications": arr })
                    if (res?.err) {
                        showToast(res?.msg, "error")
                    } else {
                        showToast("Notification settings changed.")
                    }
                } else {
                    let temp = []
                    let res = await hit(updateUser, "patch", { "notifications": temp, "groupNotis": temp })
                    if (res?.err) {
                        showToast(res?.msg)
                    } else {
                        showToast("Notification settings changed.")
                    }
                }
            } else {
                let res = await hit(updateUser, "patch", { "groupNotis": arr })
                if (res?.err) {
                    showToast(res?.msg)
                } else {
                    showToast("Notification settings changed.")
                }
            }
        } catch (e) {
        } finally {
            dispatch(getUserDetailThunk(user?._id))
        }
    }
    return (
        <Box sx={{ px: "5%", pt: "20px", display: "flex", pr: "10%", overflowY: "auto", flex: 1, width: "100%", background: "linear-gradient(119deg,#E6F6F9,white,rgb(253,243,213))" }}>
            <Box sx={{ flex: 1, minHeight: "100px" }}>
                <Typography sx={{ textAlign: "left", fontWeight: "600" }}>Notification</Typography>
                <Box sx={{ display: "flex", px: "10px", py: "20px", marginTop: "10px", flexDirection: "column", background: "white", boxShadow: "0px 5px 20px #26262626", borderRadius: "5px", minHeight: "100px" }}>
                    {list?.map((item, index) => {
                        return <NotiComp style={{ marginTop: "10px" }} item={item} index={index} key={index.toString()} isGroup={false} appEnabled={appEnabled} arr={enabledNoti} notiChange={(i) => {
                            let temp = []
                            temp = [...enabledNoti]
                            let index = temp.indexOf(i?.notiType)
                            if (i?.notiType != 5) {
                                if (index == -1) {
                                    temp.push(i?.notiType)
                                } else {
                                    temp.splice(index, 1)
                                }
                            } else {
                                if (index == -1) {
                                    temp.push(4)
                                    temp.push(5)
                                } else {
                                    let ind = temp.indexOf(4)
                                    temp.splice(index, 1)
                                    temp.splice(ind, 1)
                                }
                            }
                            changeNotificationStatus(temp, "app")
                        }} />
                    })}
                </Box>
            </Box>
            <Box sx={{ flex: 1, marginLeft: "5%", }}>
                <Typography sx={{ textAlign: "left", fontWeight: "600" }}>Group Notification</Typography>
                <Box sx={{ display: "flex", px: "10px", py: "20px", marginTop: "10px", flexDirection: "column", background: "white", boxShadow: "0px 5px 20px #26262626", borderRadius: "5px", minHeight: "100px" }}>
                {allGroups?.map((item, index) => {
                return <GNotiComp item={item} index={index} key={item?._id} isGroup={true} appEnabled={appEnabled} arr={groupNotis} notiChange={(i) => {
                    let temp = []
                    temp = [...groupNotis]
                    if (temp.includes(item._id)) {
                        let index = temp.indexOf(i?._id);
                        temp.splice(index, 1);
                    } else {
                        temp.push(i?._id)
                    }
                    changeNotificationStatus(temp, "group")
                }} style={{marginTop:"10px"}} />})}
                </Box>
            </Box>
        </Box>
    )
}

const NotiComp = ({ item, index, notiChange, arr, isGroup, appEnabled, style }) => {
    const [ton, setOn] = useState(false)
    const [val, setVal] = useState(false)
    useEffect(() => {
        if (isGroup) {
            if (arr?.includes(item?._id)) {
                setVal(true)
            } else {
                setVal(false)
            }
        } else {
            if (arr?.includes(item?.notiType)) {
                setVal(true)
            } else {
                setVal(false)
            }
        }
    }, [arr])
    const on_change = () => {
        if (item?.notiType == 7) {
            setVal(!val)
            notiChange(item)
        } else {
            if (!appEnabled) {
                return
            }
            setVal(!val)
            notiChange(item)
        }
    }
    return (
        <Box sx={[{ height: "45px", borderRadius: "5px", display: "flex", alignItems: "center", justifyContent: "space-between", px: "10px", background: "#0DB1D41A" }, style]}>
            <Typography sx={{ fontWeight: "500" }}>{item?.title}</Typography>
            <Box sx={{ cursor: "pointer" }} onClick={() => on_change()} component={"img"} src={val ? "assets/notis/on.svg" : "assets/notis/off.svg"} />
        </Box>
    )
}

const GNotiComp = ({item, index, notiChange, arr, isGroup, appEnabled, style  }) => {
    const [ton, setOn] = useState(false)
    const [val, setVal] = useState(false)
    useEffect(() => {
        if (isGroup) {
            if (arr?.includes(item?._id)) {
                setVal(true)
            } else {
                setVal(false)
            }
        } else {
            if (arr?.includes(item?.notiType)) {
                setVal(true)
            } else {
                setVal(false)
            }
        }
    }, [arr])
    const on_change = () => {
        if (item?.notiType == 7) {
            setVal(!val)
            notiChange(item)
        } else {
            if (!appEnabled) {
                return
            }
            setVal(!val)
            notiChange(item)
        }
    }
    return (
        <Box sx={[{ height: "60px", borderRadius: "5px", display: "flex", alignItems: "center", justifyContent: "space-between", px: "10px", background: "white", boxShadow: "0px 5px 20px #26262626" }, style]}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <Avatar src={image_url+item?.profilePic} />
                <Typography sx={{ fontWeight: "500", marginLeft: "10px" }}>{item?.name}</Typography>
            </Box>
            <Box sx={{ cursor: "pointer" }} onClick={() => on_change()} component={"img"} src={val ? "assets/notis/on.svg" : "assets/notis/off.svg"} />
        </Box>
    )
}