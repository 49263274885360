import { createSlice } from "@reduxjs/toolkit";

const gallerySlice=createSlice({
    name:"gallerys",
    initialState:{
        list:[],
    },
    reducers:{
        setGalleryStateData:(state,action)=>{
            state[`${action.payload.key}`]=action.payload.value
        }
    }
})

export const {setGalleryStateData}=gallerySlice.actions

export default gallerySlice.reducer