import { Box, Button, Typography } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddLinkModal from "../components/AddLinkModal";
import DeleteModal from "../components/DeleteModal";
import ImageModal from "../components/ImageModalShow";
import hit from "../services/apiManager";
import { gallery, image_url } from "../services/endpoints";
import { setGalleryStateData } from "../store/gallery";
import { setLoading } from "../store/load";
import { pxToRem } from "../theme/typography";
import showToast from "../utils/showToast";

export default function Library() {
    const [open, setOpen] = useState(false)
    const allData = useSelector(state => state?.gallery)?.list
    const dispatch = useDispatch()
    const setAllData = (v) => {
        dispatch(setGalleryStateData({ key: "list", value: v }))
    }
    const [pictures, setPictures] = useState([])
    const [videos, setVideos] = useState([])
    const [documents, setDocuments] = useState([])
    const [hypelinks, setHypelinks] = useState([])
    const [mediaType, setMediaType] = useState(null)
    const [currentMedia, setCurrentMedia] = useState(null)
    const [selectedThumb, setSelectedThumb] = useState(null)
    const [deleteModal, setDeleteModal] = useState(false)
    const [deletingItem, setDeletingItem] = useState(null)
    const [linkModal,setLinkModal]=useState(false)
    useEffect(() => {
        getGallery()
    }, [])
    useEffect(() => {
        let photo = []
        let video = []
        let docs = []
        let links = []
        allData?.forEach(e => {
            if (e?.extras?.destination?.includes("applications")||e?.link?.includes("files") || e?.extras?.destination?.includes("texts")) {
                docs.push(e)
            }
            if (e?.extras?.destination?.includes("images")||e?.link?.includes("photos")) {
                photo.push(e)
            }
            if (e?.extras?.destination?.includes("videos")||e?.link?.includes("videos")) {
                video.push(e)
            }
            if (e?.type == 1) {
                links.push(e)
            }
        });
        setPictures(photo)
        setVideos(video)
        setDocuments(docs)
        links.sort((a, b) => moment(b?.createdAt).toDate() - moment(a?.createdAt).toDate())
        setHypelinks(links)
    }, [allData])

    const getGallery = async () => {
        try {
            dispatch(setLoading(true))
            let res = await hit(`${gallery}?limit=100`, "get")
            if (res?.err) {
                showToast(res?.msg)
            } else {
                setAllData(res?.data?.results)
            }
        } catch (e) {
        } finally {
            dispatch(setLoading(false))
        }
    }
    const uploadFile = async (img) => {
        try {
            dispatch(setLoading(true))
            let formdata = new FormData()
            formdata.append("file", img)
            const res = await hit(`${gallery}`, "post", formdata)
            if (res?.err) {
                showToast(res?.msg, "error")
            } else {

            }
        } catch (e) {

        } finally {
            getGallery()
            dispatch(setLoading(false))
        }
    }


    const deleteLibItem = async (item) => {
        try {
            dispatch(setLoading(true))
            let res = await hit(`${gallery}/${item?._id}`, "delete")
            if (res?.err) {
                showToast(res?.msg, "error")
            } else {
                getGallery()
            }
        } catch (e) {
        } finally {
            setDeletingItem(null)
            dispatch(setLoading(false))
        }
    }
    const handelNo = () => {
        setDeletingItem(null)
        setDeleteModal(false)
    }


    function splitArray(array, part) {
        var tmp = [];
        for (var i = 0; i < array.length; i += part) {
            tmp.push(array.slice(i, i + part));
        }
        return tmp;
    }
    const addLinkToGallery = async (data) => {
        try {
            dispatch(setLoading(true))
            let res = await hit(`${gallery}/link`, "post",data)
            if (res?.err) {
                showToast(res?.msg, "error")
            } else {
                getGallery()
            }
        } catch (e) {
        } finally {
            dispatch(setLoading(false))
        }
    }
    return (
        <Box sx={{ px: "5%", pt: "20px", overflowY: "auto", display: "flex", flexDirection: "column", pr: "10%", flex: 1, width: "100%", background: "linear-gradient(119deg,#E6F6F9,white,rgb(253,243,213))" }}>
            <Box sx={{ display: "flex", width: "100%" }}>
                <Box sx={{ display: "flex", position: "relative", flexDirection: "column", flex: 1, justifyContent: "center", alignItems: "center", height: "150px", background: "#F3FDFF", border: "1.5px solid #0BB2D04D" }}>
                    <Box component={"img"} src="assets/library/pic.svg" />
                    <Typography sx={{ marginTop: "10px", fontWeight: "600" }}>Picture</Typography>
                    <input type="file" id="file-selector" accept="image/*" onChange={(e) => {
                        if (e.target.files[0]) {
                            let file = e.target.files[0]
                            if (file?.size <= 10000000) {
                                uploadFile(e.target.files[0])
                            } else {
                                showToast("Image cannot be more than 10mb", "warning")
                            }
                        }
                    }} style={{ height: "100%", width: "100%", marginX: "auto", cursor: "pointer", top: 0, position: "absolute", left: 0, opacity: 0, zIndex: 111 }}></input>

                </Box>
                <Box sx={{ flex: 1, display: "flex", position: "relative", flexDirection: "column", marginX: "4%", justifyContent: "center", alignItems: "center", height: "150px", background: "#F3FDFF", border: "1.5px solid #0BB2D04D" }}>
                    <Box component={"img"} src="assets/library/video.svg" />
                    <Typography sx={{ marginTop: "10px", fontWeight: "600" }}>Videos</Typography>
                    <input type="file" id="file-selector" accept="video/*" onChange={(e) => {
                        if (e.target.files[0]) {
                            let file = e.target.files[0]
                            if (file?.size <= 20000000) {
                                uploadFile(e.target.files[0])
                            } else {
                                showToast("video cannot be more than 20mb", "warning")

                            }
                        }
                    }} style={{ height: "100%", width: "100%", marginX: "auto", cursor: "pointer", top: 0, position: "absolute", left: 0, opacity: 0, zIndex: 111 }}></input>

                </Box>
                <Box sx={{ flex: 1, display: "flex", position: "relative", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "150px", background: "#F3FDFF", border: "1.5px solid #0BB2D04D" }}>
                    <Box component={"img"} src="assets/library/doc.svg" />
                    <Typography sx={{ marginTop: "10px", fontWeight: "600" }}>Documents</Typography>
                    <input type="file" id="file-selector" accept="application/*" onChange={(e) => {
                        if (e.target.files[0]) {
                            let file = e.target.files[0]
                            if (file?.size <= 10000000) {
                                uploadFile(e.target.files[0])
                            } else {
                                showToast("file cannot be more than 10mb", "warning")

                            }
                        }
                    }} style={{ height: "100%", width: "100%", marginX: "auto", cursor: "pointer", top: 0, position: "absolute", left: 0, opacity: 0, zIndex: 111 }}></input>

                </Box>

            </Box>
            <br />
            <Box sx={{ display: "flex", width: "100%" }}>
                <Box sx={{ flex: 1, textAlign: "left" }}>
                    <Typography sx={{ fontSize: pxToRem(16), fontWeight: "600" }}>Pictures</Typography>
                </Box>
                <Box sx={{ flex: 1, textAlign: "left", marginLeft: "5%" }}>
                    <Typography sx={{ fontSize: pxToRem(16), fontWeight: "600" }}>Videos</Typography>
                </Box>
            </Box>
            <Box sx={{ display: "flex", marginTop: "15px", width: "100%" }}>
                <Box sx={{ flex: 1, paddingBottom: "10px", minHeight: "100px", boxShadow: "0px 5px 20px #26262626", borderRadius: "5px", background: "white" }}>
                    {splitArray(pictures, 3)?.map(images => (
                        <ImageCompRow onDelete={(item) => {
                            setDeletingItem(item)
                            setDeleteModal(true)

                        }} setMediaType={setMediaType} setCurrentMedia={setCurrentMedia} images={images} setOpen={setOpen} />
                    ))}
                </Box>
                <Box sx={{ flex: 1, paddingBottom: "10px", marginLeft: "4%", boxShadow: "0px 5px 20px #26262626", borderRadius: "5px", background: "white", minHeight: "100px" }}>
                    {splitArray(videos, 2)?.map(vs => (
                        <VideoCompRow setSelectedThumb={setSelectedThumb} onDelete={(item) => {
                            setDeletingItem(item)
                            setDeleteModal(true)

                        }} setMediaType={setMediaType} setCurrentMedia={setCurrentMedia} videos={vs} setOpen={setOpen} />))}
                </Box>
            </Box>
            <br />
            <Typography sx={{ textAlign: "left", fontWeight: "600" }}>Documents</Typography>
            <Box sx={{ padding: "10px", marginBottom: "20px", boxShadow: "0px 5px 20px #26262626", marginTop: "15px", borderRadius: "5px", background: "white", }}>
                {splitArray(documents, 2)?.map(docs => (
                    <DocumentRow onDelete={(item) => {
                        setDeletingItem(item)
                        setDeleteModal(true)

                    }} docs={docs} />
                ))}
            </Box>
            <br />
            <Box sx={{display:"flex",justifyContent:"flex-start",alignItems:"center"}}>
            <Typography sx={{ textAlign: "left", fontWeight: "600" }}>HyperLinks</Typography>
            <Box onClick={()=>{
setLinkModal(true)
            }} component={"img"} sx={{ height: "20px", width: "20px" ,marginLeft:"15px",cursor:"pointer"}} src="assets/calendar/plus.svg" />
            </Box>
            <Box sx={{ padding: "10px", marginBottom: "20px", boxShadow: "0px 5px 20px #26262626", marginTop: "15px", borderRadius: "5px", background: "white", }}>
                {splitArray(hypelinks, 2)?.map(links => (
                    <LinkRow onDelete={(item) => {
                        setDeletingItem(item)
                        setDeleteModal(true)

                    }} links={links} />
                ))}
            </Box>
            <ImageModal mediaType={mediaType} thumblink={selectedThumb} link={currentMedia} open={open} setOpen={setOpen} />
            <DeleteModal
                open={deleteModal}
                title={"Are you sure\nyou want to delete?"}
                handleClose={() => handelNo()}
                onPressNo={() => handelNo()}
                onPressYes={() => {
                    setDeleteModal(false)
                    deleteLibItem(deletingItem)
                }}
            />
            <AddLinkModal onSubmit={(data)=>{
                addLinkToGallery(data)
                setLinkModal(false)
            }} open={linkModal} setOpen={setLinkModal} />
        </Box>
    )
}

const ImageCompRow = ({ setOpen, images, setCurrentMedia, setMediaType, onDelete }) => {
    return (
        <Box sx={{ display: "flex", marginTop: "10px", paddingX: "2%" }}>
            {images?.map((x, i) => <Box sx={{ width: "30%", marginX: i == 1 ? "5%" : "0%", borderRadius: "5px", height: "150px", position: "relative" }}>
                <Box onClick={() => {
                    setCurrentMedia(x?.link)
                    setMediaType(0)
                    setOpen(true)
                }} component={"img"} sx={{ cursor: "pointer", height: "100%", width: "100%" }} src={image_url + x?.link} />
                <Box onClick={() => {
                    onDelete(x)
                }} sx={{ height: "30px", width: "30px", display: "flex", justifyContent: "center", position: "absolute", zIndex: 111, cursor: "pointer", top: 5, right: 5, alignItems: "center", background: "rgb(254,90,89)", borderRadius: "60px" }}>
                    <Box component={"img"} src="assets/library/delete.svg" />
                </Box>
            </Box>)}
        </Box>

    )
}

const VideoCompRow = ({ videos, setCurrentMedia, setOpen, setMediaType, onDelete, setSelectedThumb }) => {
    return (
        <Box sx={{ display: "flex", marginTop: "10px", paddingX: "2%" }}>
            {videos?.map((x, i) => <Box sx={{ width: "45%", position: "relative", marginLeft: i == 1 ? "5%" : "0%" }}>
                <Box component={"video"} sx={{ width: "100%", borderRadius: "5px", height: "150px" }} src={image_url + x?.link} />
                <Box onClick={() => {
                    setCurrentMedia(x?.link)
                    setSelectedThumb(x?.thumb)
                    setOpen(true)
                    setMediaType(1)
                }} sx={{ width: "100%", display: "flex", cursor: "pointer", justifyContent: "center", alignItems: "center", background: "linear-gradient(70deg,#00000080,#000000)", position: "absolute", top: 0, borderRadius: "5px", height: "150px" }} >
                    <Box component={"img"} src="assets/library/play-alt.svg" />
                </Box>
                <Box onClick={() => {
                    onDelete(x)
                }} sx={{ height: "30px", width: "30px", display: "flex", justifyContent: "center", position: "absolute", zIndex: 111, cursor: "pointer", top: 5, right: 5, alignItems: "center", background: "rgb(254,90,89)", borderRadius: "60px" }}>
                    <Box component={"img"} src="assets/library/delete.svg" />
                </Box>
            </Box>)}
        </Box>
    )
}


const DocumentRow = ({ docs, onDelete }) => {
    return (<Box sx={{ display: "flex", marginTop: "10px", width: "100%" }}>
        {docs?.map((x, i) => <Box sx={{ height: "80px", padding: "10px", width: "50%", background: "#0DB1D41A", marginLeft: i == 1 ? "1%" : "0%", display: "flex", alignItems: "center", borderRadius: "5px" }}>
            <Box sx={{ height: "60px", width: "60px", display: "flex", justifyContent: "center", alignItems: "center", background: "#0BB2D0", borderRadius: "60px" }}>
                <Box component={"img"} src="assets/library/collection-pdf.svg" />
            </Box>
            <Box sx={{ display: "flex", flex: 1, }}>
                <Typography sx={{ fontWeight: "600", fontSize: pxToRem(14), marginLeft: "5px", textAlign: "justify" }}>{x?.link?.split("/").pop()}</Typography>
            </Box>
            <Box onClick={() => {
                onDelete(x)
            }} sx={{ height: "30px", width: "30px", display: "flex", justifyContent: "center", alignItems: "center", background: "rgb(254,90,89)", borderRadius: "60px" }}>
                <Box component={"img"} src="assets/library/delete.svg" />
            </Box>
            <Button onClick={() => {
                window.open(image_url + x?.link, "_blank")
            }} sx={{ borderRadius: 0, marginLeft: "5px" }} variant="contained" >
                Download
            </Button>
        </Box>)}
    </Box>)
}


const LinkRow = ({ links, onDelete }) => {
    return (<Box sx={{ display: "flex", marginTop: "10px", width: "100%" }}>
        {links?.map((x, i) => <Box sx={{ height: "80px", padding: "10px", width: "50%", overflow: "hidden", background: "#0DB1D41A", marginLeft: i == 1 ? "1%" : "0%", display: "flex", alignItems: "center", borderRadius: "5px", wordWrap: "break-word" }}>
            <Box sx={{ height: "60px", width: "60px", display: "flex", justifyContent: "center", alignItems: "center", background: "#0BB2D0", borderRadius: "60px" }}>
                {/* <Box component={"img"} src="assets/library/collection-pdf.svg" /> */}
                <Typography sx={{ color: "white", fontWeight: "600", fontSize: pxToRem(18) }}>H</Typography>
            </Box>
            <Box sx={{
                display: "flex", flex: 1, flexDirection: "column", wordWrap: "break-word"
            }}>
                <Typography sx={{ fontWeight: "600", fontSize: pxToRem(14), marginLeft: "5px", textAlign: "justify" }}>{x?.title}</Typography>
                <Typography color={"primary"} onClick={() => {
                    window.open(x?.link, "_blank")
                }} sx={{
                    fontWeight: "600", cursor: "pointer", fontSize: pxToRem(14), maxLines: 1, lineClamp: 2, WebkitLineClamp: 2, marginLeft: "5px", textAlign: "justify", inlineSize: "250px",
                    overflowWrap: "break-word", overflow: "hidden",
                    display: "-webkit-flex",
                }}>{x?.link}</Typography>
            </Box>
            <Box onClick={() => {
                onDelete(x)
            }} sx={{ height: "30px", width: "30px", display: "flex", justifyContent: "center", alignItems: "center", background: "rgb(254,90,89)", borderRadius: "60px" }}>
                <Box component={"img"} src="assets/library/delete.svg" />
            </Box>
            <Button onClick={() => {
                if (navigator?.clipboard) {
                    navigator.clipboard.writeText(x?.link).then(() => {
                        showToast('Access code copied to clipboard');
                        /* Resolved - text copied to clipboard successfully */
                    }, () => {
                        console.error('Failed to copy');
                        /* Rejected - text failed to copy to the clipboard */
                    });
                } else {
                    showToast('Clipboard not supported', "error");

                }
            }} sx={{ borderRadius: 0, marginLeft: "5px" }} variant="contained" >
                Copy
            </Button>
        </Box>)}
    </Box>)
}