import { combineReducers, Middleware } from 'redux'
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import { configureStore } from '@reduxjs/toolkit'
import auth from './auth'
import load from './load'
import team from './team'
import friends from './friends'
import notification from './notification'
import child from './child'
import scores from './scores'
import currentlocation from './currentlocation'
import interactions from './interactions'
import groupsChats from './groupsChats'
import test from './test'
import toast from './toast'
import post from './post'
import homes from './homes'
import storage from 'redux-persist/lib/storage'
import drafts from './drafts'
import gallery from './gallery'
import userFeeds from './userFeeds'
import friendFeeds from './friendFeeds'
import chat from './chat'
import miscDateRanges from './miscDateRanges'
import pointFilter from './pointFilter'
import teamIds from './teamIds'
import savedQuizes from './savedQuizes'
const reducers = combineReducers({
  auth,
  load,
  team,
  friends,
  child,
  notification,
  scores,
  currentlocation,
  interactions,
  groupsChats,
  test,
  toast,
  post,
  homes,
  drafts,
  gallery,
  userFeeds,
  friendFeeds,
  chat,
  miscDateRanges,
  pointFilter,
  teamIds,
  savedQuizes
})

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ["auth","miscDateRanges","pointFilter","interactionSlice"],
}

const persistedReducer = persistReducer(persistConfig, reducers)

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware => {
    const middlewares = getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
    return middlewares
  }
})
store.subscribe(() => {})
const persistor = persistStore(store)
export { store, persistor }
