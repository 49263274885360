import { createSlice } from "@reduxjs/toolkit";

const chatSlice=createSlice({
    name:"chat",
    initialState:{
        singleData:null,
        selectedGroup:null,
        selectedTab:0
    },
    reducers:{
        setSingleDataStore:(state,action)=>{
            state.singleData=action.payload
        },
        setSelectGroupStore:(state,action)=>{
            state.selectedGroup=action.payload
        },
        setSelectedTab:(state,action)=>{
            state.selectedTab=action?.payload
        }
    }
})

export const {setSingleDataStore,setSelectGroupStore,setSelectedTab}=chatSlice.actions

export default chatSlice.reducer