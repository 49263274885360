import { Avatar, Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { image_url, subgroups, teams } from "../services/endpoints";
import { setLoading } from "../store/load";
import { pxToRem } from "../theme/typography";
import showToast from "../utils/showToast";
import SearchBar from "../components/Home/SearchBar";
import DeleteModal from "../components/DeleteModal";
import PointsComp from "../components/ScoreBoard/PointsComp";
import hit from "../services/apiManager";
import _ from 'lodash'

export default function SubGroup() {
    const [subGroupDetail, setSubGroupDetail] = useState(null)
    const [groupDetail, setGroupDetail] = useState(null)
    const { subGroup } = useLocation().state ?? {}
    const dispatch = useDispatch()
    const user = useSelector(state => state.auth)?.user
    const [deleteSGroupModal, setDeleteSGroupModal] = useState(false)
    const [sMembers, setSMembers] = useState([])
    const navigate = useNavigate()
    const [showAddMember, setShowAddMember] = useState(false)
    const [search, setSearch] = useState("")

    useEffect(() => {
        if (subGroup?._id) {
            getGroupDetail()
            getParentGroupDetail()
        }
    }, [subGroup])

    const deleteSubGroup = async (id) => {
        try {
            setDeleteSGroupModal(false)
            dispatch(setLoading(false))
            const res = await hit(`${subgroups?.create}/${id}`, "delete")
            if (res?.err) {
                showToast(res?.msg, "error")
            } else {
                navigate(-1)
                showToast("Sub-Group deleted successfully")
            }
        } catch (e) {
        } finally {
            dispatch(setLoading(false))
        }
    }

    const getGroupDetail = async () => {
        try {
            dispatch(setLoading(true))
            const res = await hit(`${teams?.createTeam}/${subGroup?._id}`, "get")
            if (res?.err) {
                showToast(res?.msg, "error")
            } else {
                setSubGroupDetail(res?.data)
            }
        } catch (e) {
        } finally {
            dispatch(setLoading(false))
        }
    }

    const getParentGroupDetail = async () => {
        try {
            dispatch(setLoading(true))
            const res = await hit(`${teams?.createTeam}/${subGroup?.parentId}`, "get")
            if (res?.err) {
                showToast(res?.msg, "error")
            } else {
                setGroupDetail(res?.data)
            }
        } catch (e) {
        } finally {
            dispatch(setLoading(false))
        }
    }

    const addMem = async (id) => {
        try {
            dispatch(setLoading(true))
            let res = await hit(subgroups?.addMember, "post", { gId: subGroupDetail?._id, userId: id })
            if (res?.err) {
                showToast(res?.msg, "error")
            } else {
                showToast("Member added successfully.")
                setSMembers(res?.data?.members)
            }
        } catch (e) {
        } finally {
            getGroupDetail()
            dispatch(setLoading(false))
        }
    }

    const removeMem = async (id) => {
        try {
            if (user?._id == id) {
                return
            }
            dispatch(setLoading(true))
            let res = await hit(subgroups?.removeMember, "post", { gId: subGroup?._id, userId: id })
            if (res?.err) {
                showToast(res?.msg, "error")
            } else {
                showToast("Member removed successfully.")
                setSMembers(res?.data?.members)
            }
        } catch (e) {
        } finally {
            getGroupDetail()
            dispatch(setLoading(false))
        }
    }

    return (
        <Box sx={{ px: "5%", pt: "20px", overflowY: "auto", display: "flex", pr: "8%", flex: 1, width: "100%", background: "linear-gradient(119deg,#E6F6F9,white,rgb(253,243,213))" }}>
            <Box sx={{ flex: 1, minHeight: "100px" }}>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Typography sx={{ fontSize: pxToRem(16), fontWeight: "600", textAlign: "left" }}>{subGroupDetail?.name}</Typography>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Button
                            onClick={() => { setShowAddMember(!showAddMember) }}
                            variant="contained">Add Member
                        </Button>
                        <Button
                            onClick={() => { setDeleteSGroupModal(true) }}
                            color="error"
                            sx={{ marginLeft: "10px" }}
                            variant="contained">Delete Group
                        </Button>
                    </Box>
                </Box>
                <br />
                <Box sx={{ borderRadius: "5px", px: "10px", py: "20px", background: "white", boxShadow: "0px 5px 20px #26262626", minHeight: "100px" }}>
                    {_.sortBy(subGroupDetail?.members, [function (o) { return String(o.lastName).toLowerCase() }])?.map(item => {
                        return <MemberComponent subid={subGroupDetail?._id} groupId={subGroup?.parentId} removeMemberSubgroup={removeMem} item={item} point={item?.totalPoints?.totalPoints??0} title={item?.firstName} />
                    })}
                </Box>
                <Box sx={{ height: "20px" }} />
            </Box>
            <Box sx={{ flex: 1, marginLeft: "4%" }}>
                {showAddMember &&
                    <>
                        <Typography sx={{ textAlign: "left", fontWeight: "600" }}>Add Members In Sub group</Typography>
                        <br />
                        <SearchBar value={search} onChange={r => { setSearch(r) }} placeholder={"Search...."} />
                        <Box sx={{ display: "flex", minHeight: "400px", px: "10px", py: "20px", marginTop: "10px", flexDirection: "column", background: "white", boxShadow: "0px 5px 20px #26262626", borderRadius: "5px", }}>
                            <br />
                            {_.sortBy(groupDetail?.members, [function (o) { return String(o?.lastName).toLowerCase() }])?.filter(x => {
                                if (search?.trim() != "") {
                                    return (String(x?.firstName + x?.lastName + x?.email).toLowerCase()?.includes(search?.toLowerCase()))
                                } else {
                                    return true
                                }
                            })?.map(x => <CheckComponent onPressChecked={() => {
                                if (sMembers?.includes(x?._id)) {
                                    removeMem(x?._id)

                                } else {
                                    addMem(x?._id)
                                }
                            }} isChecked={sMembers?.includes(x?._id)} item={x} title={x?.firstName} />)}
                            <br />
                            <br />
                            <Button onClick={() => {
                                setShowAddMember(false)
                            }} sx={{ fontSize: pxToRem(16), borderRadius: "5px", marginX: "auto", height: "45px", width: "200px" }} variant="contained">
                                Done
                            </Button>
                            <br />
                        </Box>
                    </>}
                <Box height={"20px"} />
            </Box>
            <DeleteModal
                title={"You want to delete this sub-group?"}
                open={deleteSGroupModal}
                handleClose={() => {
                    setDeleteSGroupModal(false)
                }}
                onPressYes={() => deleteSubGroup(subGroupDetail?._id)}
                onPressNo={() => setDeleteSGroupModal(false)}
                onPressOut={() => setDeleteSGroupModal(false)}
            />
        </Box>
    )
}

const MemberComponent = ({ title, item, point, myScores, removeMemberSubgroup, groupId, subid }) => {
    const [showPoint, setShowPoint] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const navigate = useNavigate()
    return (
        <>
            <Box
                onClick={() => { navigate("/user/user_score", { state: { id: item?._id, gId: groupId, subid } }) }}
                sx={{ minHeight: "60px", width: "100%", p: "10px", cursor: "pointer", marginBottom: "15px", display: "flex", alignItems: "center", background: "white", boxShadow: "0px 5px 20px #26262626" }}>
                <Avatar
                    src={image_url + item?.profilePic}
                    style={{ height: "40px", width: "40px" }}
                />
                <Box sx={{ marginLeft: "15px", flex: 1, display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                    <Typography sx={{ fontWeight: "600", fontSize: pxToRem(14) }}>{title} {item?.lastName}</Typography>
                    <Typography sx={{ fontWeight: "400", fontSize: pxToRem(12) }}>{item?.email}</Typography>
                </Box>
                <Button variant="outlined">
                    {point}
                </Button>
                <Box onClick={(event) => {
                    event.stopPropagation()
                    setDeleteModal(true)
                }} component={"img"} sx={{ height: "25px", width: "25px", marginLeft: "5px", cursor: "pointer" }} src="assets/teams/delete.svg" />
            </Box>
            {showPoint && myScores?.filter(x => item?._id == x?.user?._id)?.map((i, j) => {
                return <PointsComp i={i} j={j} style={{ marginTop: "15px" }} admin={true} key={i?._id} adminView={true} />
            })}
            {showPoint && <br />}

            <DeleteModal
                title={"You want to remove " + `${title} ${item?.lastName} ` + "from team."}
                open={deleteModal}
                handleClose={() => { setDeleteModal(false); }}
                onPressNo={() => { setDeleteModal(false); }}
                onPressYes={() => {
                    setDeleteModal(false);
                    removeMemberSubgroup(item._id)
                }}
            />
        </>
    )
}

const CheckComponent = ({ title, isChecked, onPressChecked, item }) => {
    return (
        <Box sx={{ minHeight: "60px", width: "100%", marginBottom: "20px", p: "10px", display: "flex", alignItems: "center", background: "white", boxShadow: "0px 5px 20px #26262626" }}>
            <Avatar
                src={image_url + item?.profilePic}
                style={{ height: "40px", width: "40px" }}
            />
            <Box sx={{ marginLeft: "15px", flex: 1, display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                <Typography sx={{ fontWeight: "600", fontSize: pxToRem(14) }}>{title} {item?.lastName}</Typography>
                <Typography sx={{ fontWeight: "400", fontSize: pxToRem(12) }}>{item?.email}</Typography>

            </Box>
            <Box 
                onClick={() => {onPressChecked()}} 
                component={"img"} sx={{ cursor: "pointer" }} 
                src={isChecked ? "assets/groups/ios-checkbox.svg" : "assets/groups/ios-checkbox-outline.svg"} 
            />
        </Box>
    )
}