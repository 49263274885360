import { Box, Button, TextField, Typography } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getAllNotifications } from "../store/notification";
import { image_url, notificationsDelete, post, teams, uploads, verifyComments, verifynoti } from "../services/endpoints";
import { setLoading } from "../store/load";
import hit from "../services/apiManager";
import showToast from "../utils/showToast";
import moment from "moment";
import AddPictureModal from "../components/AddPictureModal";
import ScoreAccessModal from "../components/ScoreAccessModal";
import ScoreAccessDeniedModal from "../components/ScoreAccessDeniedModal";
import PostDeniedModal from "../components/PostDeniedModal";

export default function CommentsPage() {
    const { id, groupId } = useLocation()?.state
    const inputRef = useRef(null)
    const dispatch = useDispatch()
    const user = useSelector(state => state?.auth?.user)
    const isFocused = useLocation()
    const [postDetail, setPostDetail] = useState(null)
    const [comment, setComment] = useState("")
    const [comments, setComments] = useState([])
    const [isLiked, setIsLiked] = useState(false)
    const [showCameraModal, setShowCameraModal] = useState(false)
    const [showRejectModal, setShowRejectModal] = useState(false)
    const [rejectDetail, setRejectDetail] = useState(null)
    const [deniedDetail, setDeniedDetail] = useState(null)
    const [deniedModal, setDeniedModal] = useState(false)
    const [acceptModal, setAcceptModal] = useState(false)
    const [editItem, setEditItem] = useState(null)
    const [count, setCount] = useState(1)
    const [totalPage, setTotalPage] = useState(1)
    const [newNotiList, setNewNotiList] = useState([])
    const admins = useRef([])

    const isAdmin = useMemo(() => {
        return admins.current?.includes(user?._id)
    }, [admins.current, user])

    useEffect(() => {
        if (isFocused) {
            getAllComments(id, count)
            dispatch(getAllNotifications())
        }
        getDetails(id)
    }, [id, isFocused])

    useEffect(() => {
        getGroupAdmins(groupId)
    }, [groupId])

    useEffect(() => {
        if (comments?.length > 0) {
            let commentIds = comments?.map((x) => {
                return x._id
            })
            getNotiListData(commentIds)
        }
    }, [comments])

    const getNotiListData = async (comments) => {
        let res = await hit("notifications/comments", "post", { comments })
        if (!res.err) {
            setNewNotiList(res.data)
        }
    }

    const getAllComments = async (id, pg = 1) => {
        let commentsList = []
        let res = await hit(`${post?.comments}?&page=${pg}&limit=10&commentGroupId=${groupId}&parentPostId=${id}`, "get")
        if (res?.err) {
            showToast(res?.msg)
        } else {
            setTotalPage(res?.data?.totalPages)
            setCount(pg)
            if (pg == 1) {
                commentsList = res?.data?.results
            } else {
                let commentSliceData = comments.slice(0, ((pg - 1) * 10) + 1)
                let ts = [...commentSliceData, ...res?.data?.results]
                commentsList = ts
            }
            let newComentList = []
            for (let i = 0; i < commentsList?.length; i++) {
                let x = commentsList[i]
                if (x.isCommentVerified == 0 || x.isCommentVerified == false) {
                    if (x?.user?._id == user?._id) {
                        newComentList.push(x)
                    } else {
                        if (true) {
                            console.log(user)
                            newComentList.push(x)
                        } else {
                            console.log("Sunil", x)
                        }
                    }
                } else {
                    newComentList.push(x)
                }
            }
            setComments(newComentList)
        }
    }

    const getGroupAdmins = async (gId) => {
        try {
            let res = await hit(`${teams.getAdmins}`, "post", { ids: [gId] })
            if (!res.err) {
                let group = res.data[0]
                admins.current = group?.admins?.map(x => x._id)

            }
        } catch (err) {

        }
    }

    const getDetails = async (id) => {
        try {
            dispatch(setLoading(true))
            const res = await hit(`${post?.post}/${id}`, "get")
            if (res?.err) {
                showToast(res?.msg)
            } else {
                let temp = res?.data?.likes
                let fil = temp?.filter(x => x?._id == user?._id)
                if (fil?.length > 0) {
                    setIsLiked(true)
                } else {
                    setIsLiked(false)
                }
                setPostDetail(res?.data)
            }
        } catch (e) {
        } finally {
            dispatch(setLoading(false))
        }
    }

    const submit = async (cmt, type) => {
        if (cmt?.length == 0) {
            return
        }
        try {
            dispatch(setLoading(true))
            const res = await hit(`${post?.post}`, "post", {
                "txt": cmt,
                "parentPostId": id,
                "postType": "comment",
                "typeComment": type,
                "commentGroupId": groupId
            })
            if (res?.err) {
                showToast(res?.msg)
            } else {
                showToast("Comment successfully")
                getAllComments(id)
                setComment("")
            }
        } catch (error) {
        } finally {
            dispatch(setLoading(false))
        }
    }

    const likePost = async (id) => {
        try {
            let res = await hit(`${post?.post}/${id}/like`, "post")
            if (res?.err) {
                showToast(res?.msg)
            } else {
                getDetails(id)
                getAllComments(id)
            }
        } catch (e) {
        } finally {
        }
    }

    const uploadImage = async (img) => {
        if (!img) {
            return
        }
        try {
            let formdata = new FormData()
            formdata.append("file", img)
            let res = await hit(uploads, "post", formdata)
            if (res?.err) {
                showToast(res?.msg)
            } else {
                if (res?.data?.length > 0) {
                    if (editItem != null) {
                        editComment(editItem, res?.data[0])
                        return
                    }
                    submit(res?.data[0], 1)
                }
            }
        } catch (e) {
        } finally {
        }
    }

    const approve = async (id, approve) => {
        if (approve == 2) {
            setShowRejectModal(true)
            setRejectDetail(id)
            return
        }
        try {
            let res = await hit(`${post.postApproval}/${id}`, "patch", { isApproved: approve })
            if (res?.err) {
                showToast(res?.msg)
            } else {
                getDetails(id)
            }
        } catch (e) {
        } finally {
        }
    }

    const rejectPost = async (id, reason) => {
        let res = await hit(`${post.postApproval}/${id}`, "patch", { isApproved: 2, reason: reason.trim() })
        if (res?.err) {
            showToast(res?.msg)
        }
    }

    const notiAction = async (action, item) => {
        if (action == "verify") {
            try {
                dispatch(setLoading(true))
                if (postDetail?.isScorePost) {
                    let res = await hit(`${verifynoti}/${item?._id}`, "patch", { type: 1 })
                    if (res?.err) {
                        showToast(res?.msg)
                    } else {
                        setAcceptModal(true)
                        setNewNotiList(newNotiList?.filter(x => x?._id != item?._id))
                        deleteNotifications([item?._id])
                    }
                } else {
                    let res = await hit(`${verifyComments}`, "patch", { type: 1, commentId: item?._id })
                    if (res?.err) {
                        showToast(res?.msg)
                    } else {
                        // setAcceptModal(true)
                        showToast("comment approved !!")
                        let filtered = newNotiList?.filter(x => x?.commentId == item?._id)?.map(x => x._id)
                        // setNewNotiList(newNotiList?.filter(x => x?.commentId != item?._id))
                        deleteNotifications(filtered)
                        let t = setTimeout(() => {
                            clearTimeout(t)
                            setComments(comments.map(x => {
                                if (x._id == item?._id) {
                                    return { ...x, isCommentVerified: true }
                                }
                                return x
                            }))
                        }, 1000)
                    }
                }
            } catch (e) {
            } finally {
                dispatch(setLoading(false))
            }
        } else {
            setDeniedModal(true)
        }
    }

    const denyAcion = async (id, reason) => {
        try {
            dispatch(setLoading(true))
            if (postDetail?.isScorePost) {
                let res = await hit(`${verifynoti}/${id}`, "patch", { type: 2, reason })
                if (res?.err) {
                    showToast(res?.msg)
                } else {
                    showToast("Request denied")
                    setTimeout(() => {
                        deleteNotifications([id])
                    }, 900);
                    setNewNotiList(newNotiList?.filter(x => x?._id != id))
                    setComments(comments?.filter(x => x._id != res?.data?.commentId))
                }
            } else {
                let res = await hit(`${verifyComments}`, "patch", { type: 0, commentId: id, reason })
                if (res?.err) {
                    showToast(res?.msg)
                } else {
                    // setAcceptModal(true)
                    showToast("comment rejected !!")
                    let t = setTimeout(() => {
                        clearTimeout(t)
                        setNewNotiList(newNotiList?.filter(x => x?.commentId != id))
                        setComments(comments.filter(x => {
                            return x._id != id
                        }))
                    }, 1000)
                }
            }

        } catch (e) {
        } finally {
            dispatch(setLoading(false))
        }
    }

    const deleteNotifications = async (arr) => {
        if (arr.length == 0) {
            showToast("No items selected")
            return
        }
        try {
            // dispatch(setLoading(true))
            let res = await hit(notificationsDelete, "post", { list: arr })
            if (res?.err) {
                // showToast(res?.msg)
            } else {
            }
        } catch (e) {
        } finally {
            // setNewNotiList(newNotiList?.filter(x => !arr?.inlcudes(x?._id)))
            dispatch(getAllNotifications())
            dispatch(setLoading(false))
        }
    }

    const uploadPdf = async (file) => {
        try {
            dispatch(setLoading(false))
            let formdata = new FormData()
            formdata.append("file", file)
            let res = await hit(uploads, "post", formdata)
            if (res?.err) {
                showToast(res?.msg)
            } else {
                if (res?.data?.length > 0) {
                    submit(res?.data[0], 2)
                } else {
                    showToast("Something went wrong.")
                }
            }
        } catch (e) {
        } finally {
            dispatch(setLoading(false))
        }
    }

    const editComment = async (item, comment) => {
        try {
            let res = hit(`${post?.post}/${item?._id}`, "delete")
            if (res?.err) {
                showToast(res?.msg)
            } else {
                submit(comment, item?.typeComment)
                setEditItem(null)
            }
        } catch (e) {
        } finally {
        }
    }

    const handleFile = async (e, type) => {
        try {
            setShowCameraModal(false)
            if (e.target.files[0]) {
                // setProfile(e.target.files[0])
                if (type == "picture") {
                    let file = e.target.files[0]
                    if (file.size <= 10000000) {
                        uploadImage(e.target.files[0])
                    } else {
                        showToast("Image cannot be more than 10mb", "warning")
                    }
                } else {
                    let file = e.target.files[0]
                    if (file.size <= 10000000) {
                        uploadPdf(e.target.files[0])
                    } else {
                        showToast("file cannot be more than 10mb", "warning")
                    }
                }
            }
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <Box sx={{ px: "5%", pt: "20px", display: "flex", pr: "10%", overflowY: "auto", flex: 1, width: "100%", background: "linear-gradient(119deg,#E6F6F9,white,rgb(253,243,213))" }}>
            <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
                <Box sx={{ height: "60px", background: "white" }}>
                    {!postDetail?.isScorePost && postDetail?.isApproved == 0 ?
                        <Box sx={{ flexDirection: 'row', display: "flex", alignItems: "center", justifyContent: "space-around", marginVertical: "16px" }}>
                            <Button
                                variant="contained"
                                color="success"
                                sx={{ height: "36px", width: "40%", fontSize: "13.2px" }}
                                onPress={() => {
                                    approve(postDetail?._id, 1)
                                }}>
                                Approve
                            </Button>
                            <Button
                                variant="contained"
                                color="error"
                                sx={{ height: "36px", width: "40%", fontSize: "13.2px" }}
                                onPress={() => {
                                    approve(postDetail?._id, 2)
                                }}>
                                Reject
                            </Button>
                        </Box>
                        :
                        <Box sx={{ flexDirection: "row", display: "flex", padding: "16px", alignItems: "center" }}>
                            <Box
                                onClick={() => { likePost(id) }}
                                sx={{ height: "30px", display: "flex", cursor: "pointer", justifyContent: "center", alignItems: "center", background: "white", boxShadow: "0px 3px 5px #26262633", width: "30px", borderRadius: "40px" }}>
                                <Box component={"img"} sx={{ height: "15px", width: "15px", objectFit: "contain" }} src={isLiked ? "assets/post/heart.svg" : "assets/post/hearte.png"} />
                            </Box>
                            <Box sx={{ height: "24px", width: "50px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "30px", paddingHorizontal: "8px", marginLeft: "8px", boxShadow: "0px 3px 5px #26262633", }}>
                                <Typography sx={{ fontWeight: "500", fontSize: "10px" }}>{postDetail?.totalCommentsG ? (postDetail?.totalCommentsG[`${groupId}`] ?? postDetail?.totalComments) : postDetail?.totalComments}</Typography>
                            </Box>
                            {postDetail?.likes?.length > 0 ?
                                postDetail?.likes?.length == 1 ?
                                    <Typography sx={{ fontWeight: "500", marginLeft: "16px" }}>Liked by {postDetail?.likes[0]?.firstName}</Typography>
                                    :
                                    <Typography sx={{ fontWeight: "500", marginLeft: "16px" }}>Liked by {postDetail?.likes[0]?.firstName} and {(postDetail?.likes?.length) - 1 ?? 0} other</Typography>
                                :
                                <Typography sx={{ fontWeight: "500", marginLeft: "16px" }}>No likes yet</Typography>
                            }
                        </Box>
                    }
                </Box>
                <Box sx={{ background: "white", flex: 1, overflowY: "auto" }}>
                    {/* {JSON.stringify(comments)} */}
                    {comments?.map((item, index) => {
                        return (
                            <RenderComments
                                iScorePost={postDetail?.isScorePost}
                                isAdmin={isAdmin}
                                item={item}
                                list={newNotiList}
                                index={index}
                                imagePressed={() => {
                                    window.open(image_url + item?.txt, "_blank")
                                }}
                                onPressDeny={(item) => {
                                    if (postDetail?.isScorePost) {
                                        setDeniedDetail(item)
                                        notiAction("deny", item)
                                    } else {
                                        setDeniedDetail({ body: item?.user?.firstName, _id: item._id })
                                        notiAction("deny", item)
                                    }
                                }}
                                onPressVerify={(item) => {
                                    if (postDetail?.isScorePost) {
                                        setDeniedDetail(item)
                                        notiAction("verify", item)
                                    } else {
                                        notiAction("verify", item)
                                    }
                                }}
                                docPressed={() => { window.open(image_url + item?.txt, "_blank") }}
                                onPressEdit={() => {
                                    if (item?.typeComment == 0) {
                                        setEditItem(item)
                                        setComment(item?.txt)
                                        inputRef?.current?.focus()
                                    }
                                    if (item?.typeComment == 1) {
                                        setEditItem(item)
                                        setShowCameraModal(true)
                                        setComment("")
                                    }
                                }}
                            />
                        )
                    })}
                    {totalPage > count && <Button onClick={() => {
                        getAllComments(id, count + 1)
                    }} sx={{ marginTop: "20px", color: "#58C0DC" }} variant="outline">
                        Load More
                    </Button>}
                </Box>
                <Box sx={{ background: "white" }}>
                    <ChatInput
                        inputRef={inputRef}
                        comment={comment}
                        setComment={setComment}
                        editComment={editComment}
                        editItem={editItem}
                        submit={submit}
                        setShowCameraModal={setShowCameraModal}
                    />
                </Box>

            </Box>
            <AddPictureModal handleFile={(e, type) => handleFile(e, type)} open={showCameraModal} setOpen={setShowCameraModal} />
            <ScoreAccessModal open={acceptModal} setOpen={setAcceptModal} detail={deniedDetail}
                onPressOk={(id) => {
                    setAcceptModal(false)
                }} />
            <ScoreAccessDeniedModal
                open={deniedModal}
                setOpen={setDeniedModal}
                detail={deniedDetail}
                onPressOk={(r, data) => {
                    setDeniedModal(false)
                    denyAcion(data?._id, r)
                }}
                outPressed={() => {
                    setDeniedModal(false)
                }} />
            <PostDeniedModal
                open={showRejectModal}
                setOpen={setShowRejectModal}
                outPressed={() => setShowRejectModal(false)}
                onPressOk={(t) => {
                    setShowRejectModal(false)
                    rejectPost(rejectDetail, t)
                }}
            />
        </Box>
    )
}

const ChatInput = ({ inputRef, comment, setComment, editComment, editItem, submit, setShowCameraModal }) => {
    const [open, setOpen] = useState(false)
    return (
        <Box sx={{ height: "50px", marginX: "10px", display: "flex", alignItems: "center", marginY: "5px", border: "1.5px solid #0DB1D433", borderRadius: "50px" }}>
            <TextField variant="standard"
                placeholder={"Enter your message here"}
                multiline
                inputRef={inputRef}
                value={comment}
                onChange={e => {
                    setComment(e.target.value)
                }}
                InputProps={{
                    disableUnderline: true,
                    justifyContent: 'center',
                    sx: [{ fontSize: "14px", fontWeight: "500", height: "50px", }],
                }} sx={{ borderColor: "white", display: "flex", overflowY: "auto", flex: 1, px: "20px" }} />
            <Box onClick={() => {
                setShowCameraModal(true)
            }} sx={{ height: "40px", cursor: "pointer", width: "40px", display: "flex", justifyContent: "center", alignItems: "center", background: "rgb(207,239,246)", borderRadius: "40px" }}>
                <Box component={"img"} src="assets/chat/attach.svg" />
            </Box>
            <Box onClick={() => {
                if (editItem != null) {
                    editComment(editItem, comment)
                    return
                }
                submit(comment, 0)
            }} sx={{ height: "40px", width: "40px", display: "flex", justifyContent: "center", alignItems: "center", background: "rgb(13,177,212)", marginX: "10px", borderRadius: "40px" }}>
                <Box component={"img"} src="assets/chat/send.svg" />
            </Box>
        </Box>
    )
}

const RenderComments = ({ item, list, imagePressed, onPressDeny, onPressVerify, docPressed, onPressEdit, iScorePost }) => {
    let isImage = false
    let isDoc = false
    let isRejected = false
    const user = useSelector(state => state?.auth?.user)
    const [showEdit, setShowEdit] = useState(false)
    useEffect(() => {
        if (item?.user?._id == user?._id) {
            setShowEdit(true)
        }
    }, [user, item])
    if (item?.typeComment == 1) {
        isImage = true
    }
    if (item?.typeComment == 2) {
        isDoc = true
    }
    let ver = useMemo(() => {
        return list.filter(x => x?.commentId == item?._id)
    }, [list])

    if (item?.isCommentVerified == 2) {
        isRejected = true
    }
    return (
        <>
            <Box sx={{ paddingX: "20px", textAlign: "left", alignSelf: "flex-start", display: "flex", flexDirection: "column" }}>
                <Box sx={{ boxShadow: "0px 3px 5px #26262633", whiteSpace: "pre-wrap", backgroundColor: "white", padding: "12px", marginTop: "20px", borderRadius: "8px", maxWidth: "60%", minWidth: "20%" }}
                    onClick={() => {
                        isImage && imagePressed()
                        isDoc && docPressed()
                    }}>
                    <Typography sx={{ fontSize: "12px", fontWeight: "500" }}>{item?.user?.firstName} {item?.user?.lastName}</Typography>
                    {isImage ? <Box component={"img"} src={image_url + item?.txt} sx={{ height: "160px", width: "160px", marginTop: "6px", objectFit: "cover" }} />
                        :
                        isDoc ?
                            <Box component={"img"} src={"/assets/post/document.jpeg"} sx={{ height: "160px", width: "160px", marginTop: "6px" }} />
                            :
                            <Typography sx={{ fontWeight: "600", fontSize: "14px", marginTop: "8px" }}>{item?.txt}</Typography>
                    }
                </Box>
                {(ver?.length == 1) &&
                    <Box sx={{ flexDirection: "row", display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "8px", width: "100%" }}>
                        <Button
                            sx={{ height: "36px", width: "46%", fontSize: "12px" }} variant="contained" color="error"
                            onClick={() => onPressDeny(iScorePost ? ver[0] : item)} >
                            Deny
                        </Button>
                        <Button
                            sx={{ height: "36px", width: "46%", fontSize: "12px" }} variant="contained" color="success"
                            onClick={() => onPressVerify(iScorePost ? ver[0] : item)} >
                            Verify
                        </Button>
                    </Box>
                }
                <Box sx={{ flexDirection: "row", display: "flex", alignContent: "center", maxWidth: "60%", minWidth: "20%", justifyContent: 'space-between' }}>
                    <Typography sx={{ fontWeight: "500", fontSize: "10px", marginLeft: "6px", }}>{moment(item?.createdAt).fromNow()}</Typography>
                    {showEdit && <Box sx={{ cursor: "pointer" }}
                        onClick={onPressEdit}>
                        {isRejected && <Typography sx={{ fontWeight: "600", color: "red", fontSize: "12px", marginLeft: "6px", }}>Edit</Typography>}
                    </Box>}
                </Box>
            </Box>
        </>
    )
}