import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { pxToRem } from '../theme/typography';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "25vw",
    minHeight: "15vh",
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: "5px",
    p: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
};
export default function DeleteModal({ open, handleClose, onPressNo, onPressYes, title }) {

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
            <Box sx={style}>
                <Box style={{ minHeight: 120, padding: 16, borderRadius: 4 }}>
                    <Typography sx={{ textAlign: "center",fontWeight:"600", fontSize: "14px" }}>Are you sure?</Typography>
                    <Typography sx={{ textAlign: "center",fontFamily: "600", fontSize: "12px" }}>{title ? title : "You want to delete this post?"}</Typography>
                    <Box style={{ display: "flex", justifyContent: "space-around", marginTop: "20px" }}>
                        <Button
                            variant='contained'
                            sx={{ height: "40px", width: "40%" }}
                            onClick={onPressYes}
                        >
                            Yes
                            </Button>
                        <Button
                        variant='contained'
                            title={"No"}
                            sx={{ height: "40px", width: "40%" }}
                            onClick={onPressNo}
                        >
                            No
                            </Button>
                    </Box>
                </Box>
            </Box>
        </Modal>
        </div>
    )
}