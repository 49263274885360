

import {createSlice} from '@reduxjs/toolkit'
import hit from '../services/apiManager'

const initialState={
    adminGroups:[],
    memberGroups:[]
}

const testSlice=createSlice({
    name:"teamIds",
    initialState,
    reducers:{
        setData:(state,action)=>{
            state.adminGroups=action.payload.gAdmins
            state.memberGroups=action.payload.gMembers
        }
    }
})


export const {setData}=testSlice.actions

export const getAllTeamIds = () => {
    return async (dispatch) => {
        try {
           
            let res = await hit("/teams/teamIdList", "get")
            if (res?.data) {
                dispatch(setData(res?.data))
            }
        } catch (err) {
            console.error(err)
        }finally{
        }
    }
}

export default testSlice.reducer