import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Typography, useTheme } from '@mui/material';
import { DateRangePicker } from 'rsuite';
import { setLoading } from "../../store/load";
import { toast } from 'react-toastify';
import showToast from "../../utils/showToast";
import { pxToRem } from "../../theme/typography";
import hit from "../../services/apiManager";
import moment from 'moment';
import { scores, teams } from '../../services/endpoints';
import VisibilityIcon from '@mui/icons-material/Visibility';

const ScoreButtons = (props) => {
    let { groupId, isAdmin, adminView, setDates, setSelectedQuarter } = props

    const user = useSelector(state => state?.auth?.user);

    const [groupDetails, setGroupDetails] = useState(null)

    const [localStartDate, setLocalStartDate] = useState('')
    const [localEndDate, setLocalEndDate] = useState('')
    const [selected, setSelected] = useState('')

    const [apiMode, setApiMode] = useState(null)
    const [justPoints, setJustPoints] = useState(null)

    const dispatch = useDispatch()
    const theme = useTheme()

    const quarter = [{
        title: "Daily", points: 0, show: 0
    }, {
        title: "Weekly", points: 0, show: 0
    }, {
        title: "Monthly", points: 0, show: 0
    }, {
        title: "Miscellaneous", points: 0, show: 1
    }]

    function splitArray(array, part) {
        var tmp = [];
        for (var i = 0; i < array.length; i += part) {
            tmp.push(array.slice(i, i + part));
        }
        return tmp;
    }

    const saveMisDate = async (startDate, endDate) => {
        // update to db if changed by admin
        let lastMode = apiMode
        let newMode = 'all'
        setLocalStartDate(startDate)
        setLocalEndDate(endDate)
        setDates(startDate, endDate)
        if (startDate != '' && endDate != '') {
            newMode = 'miscellaneous'
        } else {
            setSelected('')
            setSelectedQuarter('all')
        }
        setApiMode(newMode)
        if (lastMode == newMode) {
            setTimeout(async () => {
                await getJustPointsData(groupId, startDate, endDate)
            }, 1000)
        }
        if (isAdmin) {
            let editData = {
                miscBoardStartDate: startDate,
                miscBoardEndDate: endDate
            }
            await hit("teams/" + groupId, "patch", editData)
        }
    }

    const getGroupDetail = async (id) => {
        let res = await hit(`${teams?.createTeam}/${id}`);
        if (res?.err) {
            showToast(res?.msg)
        } else {
            console.log("sunil >>>>>>", res?.data)
            setGroupDetails(res?.data)
        }
    }

    const getJustPointsData = async (id, startDate = null, endDate = null) => {
        setLoading(true)
        let query = `?groups=${id}&mode=${apiMode}`;
        if (adminView == "User" || !isAdmin) {
            query = query.concat(`&user=${user?._id}`)
        }
        if (apiMode == 'miscellaneous') {
            query = query.concat(
                `&startDate=${moment(startDate || localStartDate).format('DD-MM-YYYY')}
                &endDate=${moment(endDate || localEndDate).format('DD-MM-YYYY')}`,
            );
        }
        let res = await hit(`${scores?.getscore}/average${query}`);
        if (res?.err) {
            showToast(res?.msg)
        } else {
            setJustPoints(res?.data?.sum)
        }
        setLoading(false)
    }

    const updateGroupDetailsData = async (value) => {
        dispatch(setLoading(true))
        let res = await hit("teams/" + groupId, "patch", { showMiscBoard: value })
        if (!res.err) {
            getGroupDetail(groupId)
        } else {
            toast.error("something went wrong while switching value")
        }
        dispatch(setLoading(false))
    }

    const manageClickOnQuater = (item) => {
        if (selected == item?.title) {
            setSelected("")
            setSelectedQuarter("all")
        } else {
            if (item?.title == "Miscellaneous") {
                // please check dates is being selected
                if (localStartDate == "" || localEndDate == "") {
                    toast.error("Please select dates before getting miscellaneous data")
                    return false
                }
            }
            setSelected(item?.title)
            setSelectedQuarter(item?.title)
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            let mode = ''
            if (groupDetails) {
                if (isAdmin) {
                    if (groupDetails?.miscBoardStartDate == "" || groupDetails?.miscBoardEndDate == "") {
                        mode = "all"
                        setLocalStartDate('')
                        setLocalEndDate('')
                        setDates('', '')
                    } else {
                        mode = "miscellaneous"
                        setLocalStartDate(groupDetails?.miscBoardStartDate)
                        setLocalEndDate(groupDetails?.miscBoardEndDate)
                        setDates(groupDetails?.miscBoardStartDate, groupDetails?.miscBoardEndDate)
                    }
                } else {
                    // non admin
                    if (groupDetails?.showMiscBoard) {
                        if (groupDetails?.miscBoardStartDate == "" || groupDetails?.miscBoardEndDate == "") {
                            mode = "all"
                            setLocalStartDate('')
                            setLocalEndDate('')
                            setDates('', '')
                        } else {
                            mode = "miscellaneous"
                            setLocalStartDate(groupDetails?.miscBoardStartDate)
                            setLocalEndDate(groupDetails?.miscBoardEndDate)
                            setDates(groupDetails?.miscBoardStartDate, groupDetails?.miscBoardEndDate)
                        }
                    } else {
                        mode = "all"
                        setLocalStartDate('')
                        setLocalEndDate('')
                        setDates('', '')
                    }
                }
                setApiMode(mode)
            }
        };
        fetchData();
    }, [groupDetails])

    useEffect(() => {
        const fetchData = async () => {
            if (groupId) {
                await getGroupDetail(groupId);
            }
        };
        fetchData();
    }, [groupId]);

    useEffect(() => {
        const fetchPoints = async () => {
            if (apiMode) {
                await getJustPointsData(groupId)
            }
        }
        fetchPoints()
    }, [apiMode])

    useEffect(() => {
        const fetchPointsAdminView = async () => {
            if (adminView) {
                await getJustPointsData(groupId)
            }
        }
        fetchPointsAdminView()
    }, [adminView])

    return (
        <>
            {splitArray(quarter, 2).map((items) => (
                <Box sx={{ display: "flex", marginTop: "10px" }} key={Math.random()}>
                    {items?.map((item, index) => {
                        let point = 0;
                        if (item?.title === "Daily") {
                            point = justPoints?.todaySumofPoints ?? 0;
                        } else if (item?.title === "Weekly") {
                            point = justPoints?.weeklySumofPoints ?? 0;
                        } else if (item?.title === "Monthly") {
                            point = justPoints?.monthlySumofPoints ?? 0;
                        } else {
                            if (apiMode != "all") {
                                point = justPoints?.totalSumofPoints ?? 0;
                            } else {
                                point = 0;
                            }
                        }

                        return (
                            <Box
                                key={index}
                                sx={[
                                    {
                                        position: 'relative',
                                        zIndex: 0,
                                        boxShadow: "0px 5px 20px #26262626",
                                        height: "80px",
                                        cursor: "pointer",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "space-between",
                                        alignItems: "flex-start",
                                        px: "10px",
                                        py: "10px",
                                        borderRadius: "5px",
                                        flex: 1,
                                        background: selected === item?.title ? theme.palette.primary.main : "white",
                                    },
                                    { marginLeft: index !== 0 ? "4%" : "0%" },
                                ]}>
                                <Box sx={{ display: "flex" }}>
                                    <Typography
                                        sx={{
                                            fontWeight: "600",
                                            color: selected === item?.title ? "white" : "black",
                                        }}>
                                        {item?.title}
                                    </Typography>
                                    {item?.show && isAdmin ? (
                                        <Box
                                            sx={{ cursor: "pointer", marginLeft: "5px" }}
                                            style={{ position: "absolute", right: 80, top: 15, zIndex: 9999999 }}
                                            onClick={() =>
                                                updateGroupDetailsData(!groupDetails?.showMiscBoard)
                                            }
                                            component={"img"}
                                            src={
                                                groupDetails?.showMiscBoard
                                                    ? "assets/notis/on.svg"
                                                    : "assets/notis/off.svg"
                                            }
                                        />
                                    ) : null}
                                </Box>
                                <Box onClick={(e) => e.stopPropagation()} display={"flex"} alignItems={"center"}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            background: "white",
                                            border: `1px solid ${theme.palette.primary.main}`,
                                            marginRight: "5px",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            padding: "4px",
                                            borderRadius: "4px",
                                        }}>
                                        <Typography
                                            sx={{
                                                color: theme.palette.primary.main,
                                                fontSize: "14px",
                                                fontWeight: "600",
                                            }}>
                                            {Math.round(point)}
                                        </Typography>
                                        <VisibilityIcon
                                            sx={{ cursor: "pointer", marginLeft: "5px" }}
                                            style={
                                                item?.show?
                                                { position: "absolute", right: 10, top: 5, zIndex: 9999999 }
                                                : { position: "absolute", right: 10, bottom: 15, zIndex: 9999999 }
                                            }
                                            onClick={() =>
                                                manageClickOnQuater(item)
                                            }
                                        />
                                    </Box>
                                    {item?.show ? (
                                        isAdmin || (!isAdmin && !groupDetails?.showMiscBoard) ? (
                                            <>
                                                {/* {console.log("sunil ", localStartDate, groupDetails?._id, groupDetails)} */}
                                                <DateRangePicker
                                                    placeholder="Select Date Range"
                                                    format="MMM, dd, yyyy"
                                                    style={{ color: theme.palette.primary.main }}
                                                    value={[
                                                        localStartDate ? new Date(localStartDate) : '',
                                                        localEndDate ? new Date(localEndDate) : ''
                                                    ]}
                                                    placement="auto"
                                                    onChange={(v) => {
                                                        if (v && v.length > 0) {
                                                            if (v.length === 1) {
                                                                saveMisDate(v[0], v[0]);
                                                            } else if (v.length === 2) {
                                                                saveMisDate(v[0], v[1]);
                                                            }
                                                        } else {
                                                            saveMisDate('', '');
                                                        }
                                                    }}
                                                />
                                            </>
                                        ) : (
                                            <Typography sx={{ fontWeight: "normal", fontSize: pxToRem(12) }}>
                                                {`${localStartDate !== "" ? moment(localStartDate).format("MMM DD, YYYY") : ''} - ${localEndDate !== "" ? moment(localEndDate).format("MMM DD, YYYY") : ''}`}
                                            </Typography>
                                        )
                                    ) : null}
                                </Box>
                            </Box>
                        );
                    })}
                </Box>
            ))}
        </>
    );
}
export default ScoreButtons