import { Avatar, Badge, Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Input from "../components/Login/Input";
import { pxToRem } from "../theme/typography";

export default function SubsPage() {
    const navigate = useNavigate()
    return (
        <Box sx={{ px: "5%", pt: "20px", display: "flex", pr: "10%", overflowY: "auto", flex: 1, width: "100%", background: "linear-gradient(119deg,#E6F6F9,white,rgb(253,243,213))" }}>
            <Box sx={{ flex: 1, minHeight: "100px" }}>
                <Box sx={{ display: "flex", px: "10px", py: "20px", marginTop: "10px", flexDirection: "column", background: "white", boxShadow: "0px 5px 20px #26262626", borderRadius: "5px", minHeight: "100px" }}>
                    <Box component={"img"} src="assets/subs/sub.png" sx={{height:"350px",objectFit:"contain"}} />
                    <Typography sx={{ fontWeight: "600" }}>Want Culture Code for a Group</Typography>
                    <br />
                    <Button onClick={() => {
                        navigate(-1)
                    }} sx={{ fontSize: pxToRem(16), fontWeight: "400", background: "#F5BA16", borderRadius: "5px", marginX: "auto", height: "45px", width: "250px" }} variant="contained">
                        See Group Options
                    </Button>
                    <br />
                    <Typography sx={{ fontWeight: "400", fontSize: pxToRem(10) }}>INCLUDES 1 WEEK FREE TRIAL</Typography>
                    <Button onClick={() => {
                        navigate(-1)
                    }} sx={{ fontSize: pxToRem(16), fontWeight: "400", marginTop: "5px", borderRadius: "5px", marginX: "auto", height: "45px", width: "250px" }} variant="contained">
                        Confirm
                    </Button>
                    <br />
                    <Box sx={{ width: "90%", mx: "auto" }}>
                        <Typography sx={{ fontSize: pxToRem(10), fontWeight: "600" }}>The subscription automatically renews for US $4.99/month at the end of the month.</Typography>
                        <Typography sx={{ fontSize: pxToRem(10) }}>
                            Payment will be charged to your Apple ID account at the confirmation of purchase Subscriptions automatically renews unless it is cancelled at least 24 hours before the end of the current period. Your account will be charged for renewal within 24 hours prior to the end of the current period. You can manage and cancel your subscriptions by going to your account settings on the App Store after purchase.
                        </Typography>
                    </Box>
                </Box>
                <br />
            </Box>
            <Box sx={{ flex: 1, marginLeft: "5%", }}>
            </Box>
        </Box>
    )
}

