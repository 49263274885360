import { Box, Typography, Button, Modal } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setLoading } from "../store/load";
import { pxToRem } from "../theme/typography";
import { image_url } from "../services/endpoints";
import showToast from "../utils/showToast";
import hit from "../services/apiManager";
import { CancelOutlined, CheckCircle, BalanceOutlined } from '@mui/icons-material';
import moment from "moment";

export default function Quiz() {
    const { gId, postId } = useLocation().state
    const dispatch = useDispatch()
    const userMe = useSelector(state => state.auth?.user)
    const navigate = useNavigate()
    const [quizTitle, setQuizTitle] = useState("")
    const [quizDesc, setQuizDesc] = useState("")
    const [quizResult, setQuizResult] = useState(false)
    const [isResult, setIsResult] = useState(false)
    const [scoredPoints, setScoredPoints] = useState(0)
    const [quizTotalEarningPoints, setQuizTotalEarningPoints] = useState(0)
    const [allowSubmit, setAllowSubmit] = useState(true)
    const [delayInMins, setDelayInMins] = useState(null)
    const [quizData, setQuizData] = useState([])
    const [data, setData] = useState([])
    const REGEX = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm;

    const updateQuizData = async (point, total, data) => {
        if (!allowSubmit) {
            showToast(`You can submit the answer for this question after ${delayInMins} Minutes`, "error")
            return false
        }
        dispatch(setLoading(true))
        let res = await hit("/posts/quiz/" + postId, "patch", { point, datas: data, total, gId })
        if (!res.err) {
            showToast("Quiz submitted successfully!")
            setScoredPoints(res?.data?.scoredPoints)
            setQuizTotalEarningPoints(res?.data?.quizTotalEarningPoints)
            setIsResult(res?.data?.is_result)
            setQuizResult(true)
            setTimeout(() => {
                navigate(-1)
            }, 10000)
        } else {
            showToast(res.msg, "error")
        }
        dispatch(setLoading(false))
    }

    const getPostData = async () => {
        dispatch(setLoading(true))
        let res = await hit("/posts/" + postId, "get")
        if (!res.err) {
            if (res?.data) {
                setQuizData(res?.data?.quizData)
                setQuizTitle(res?.data?.quizTitle)
                setQuizDesc(res?.data?.quizDesc)
                let quizedBy = res?.data?.quizedBy?.filter(x => x?.user == userMe?._id)
                quizedBy.sort((a, b) => b?.lastUpdate - a?.lastUpdate)
                if (quizedBy[0]) {
                    setData(quizedBy[0].datas?.map(x => {
                        res?.data?.quizData?.find(d => d._id == x?._id)
                        return ({ ...x, point: 0 })
                    }))
                    let datas = quizedBy[0].datas
                    setQuizData(res?.data?.quizData?.map(x => {
                        let q = datas?.find(d => d._id == x?._id)
                        return ({
                            ...x, editable: q?.ans != x?.ansKey
                        })
                    }))
                }
            }
        } else {
            showToast(res.msg, "error")
        }
        dispatch(setLoading(false))
    }

    const getQAR = async () => {
        let res = await hit("/posts/quiz/qar/" + postId, "get")
        if (!res.err) {
            if (res?.data) {
                let answered_on = res?.data?.answered_on
                let currentTime = moment().unix()
                let diff = currentTime - answered_on
                if (diff <= 600) {
                    setAllowSubmit(false)
                    let temp = Math.ceil((600 - diff) / 60)
                    setDelayInMins(temp)
                }
            }
        }
    }

    const Paragraph = ({ paragraph }) => {
        if (!paragraph || paragraph.trim().length === 0) {
            return null;
        }
        const paragraphArray = paragraph.match(/\S+|\n/g);
        return (
            <div style={{ flex: 1, maxWidth: "400px", wordWrap: "break-word", whiteSpace: "pre-wrap" }}>
                {paragraphArray.map((word, index) => {
                    if (word.match(REGEX)) {
                        return (
                            <a key={index} href={word} className="text-blue-400">
                                {word}
                            </a>
                        );
                    } else {
                        return (
                            <span key={index}>
                                {word}{(word === '\n' || word === ' ') ? '' : " "}
                            </span>
                        );
                    }
                })}
            </div>
        );
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '1px solid #000',
        boxShadow: 24,
        borderRadius: "10px",
        pt: 3,
        px: 5,
        pb: 6,
        textAlign: 'center'
    };

    useEffect(() => {
        getQAR()
        getPostData()
    }, [])

    return (
        <>
            <Modal
                open={quizResult}
                onClose={() => setQuizResult(false)}
                aria-labelledby="Quiz Result"
                aria-describedby="Quiz Scored">
                <Box sx={style}>
                    {isResult == "passed" ?
                        <CheckCircle color={"success"} fontSize={"large"} />
                        : isResult == "partial" ? <BalanceOutlined color={"error"} fontSize={"large"} />
                            : <CancelOutlined color={"error"} fontSize={"large"} />}
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {isResult == "passed" ? "Congratulations" :
                            isResult == "partial" ? "Oh !!! Few answers are correct"
                                : "Oops !!! Wrong Answer"}
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {isResult == "passed" ?
                            `Great !!! You have Scored ${scoredPoints} Point${scoredPoints > 1 ? "s" : ""}`
                            : isResult == "partial" ? `Well Tried !!! You have scored ${scoredPoints} Point${scoredPoints > 1 ? "s" : ""} out of ${quizTotalEarningPoints} Point${scoredPoints > 1 ? "s" : ""} and now you can take the rest and try again in 10 mins to improve your scores`
                                : "You haven't scored any point due to wrong answer. now you can take the rest and try again in 10 mins to improve your scores"
                        }
                    </Typography>
                </Box>
            </Modal>
            <Box sx={{ px: "5%", pt: "20px", display: "flex", pr: "10%", overflowY: "auto", flex: 1, width: "100%", background: "linear-gradient(119deg,#E6F6F9,white,rgb(253,243,213))" }}>
                <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ fontSize: pxToRem(20), fontWeight: "600" }}>{quizTitle}</Typography>
                    <Typography sx={{ fontSize: pxToRem(14), fontWeight: "400", textAlign: "center" }}> <Paragraph paragraph={quizDesc} /></Typography>
                    {quizData?.map((x, i) => (
                        <Box sx={{ textAlign: "left" }}>
                            <Typography sx={{ fontSize: pxToRem(14), fontWeight: "500", marginTop: "20px" }}>Q{i + 1}. {x?.qn}</Typography>
                            <Box display={"flex"} marginTop={"10px"}>
                                {x?.photos?.map((x, id) => {
                                    return <Box sx={{ position: "relative", marginRight: "10px" }}>
                                        <Box component={"img"} sx={{ height: "150px", width: "150px", borderRadius: "10px", border: "1px solid", borderColor: "rgb(16,176,212)", objectFit: "contain" }} src={typeof x === 'string' ? image_url + x : URL.createObjectURL(x)} />
                                    </Box>
                                })}
                            </Box>
                            {x?.options?.map((op, ind) => {
                                let findQ = data?.find(d => d?._id == x?._id)
                                let isChecked = false
                                let point = x?.ansKey == op ? x?.point : 0
                                if (findQ?.ans == op) {
                                    isChecked = true
                                }
                                return (
                                    <Box sx={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                                        <Box onClick={() => {
                                            if (x?.editable == false) {
                                                return
                                            }
                                            let findQ = data?.find(d => d?._id == x?._id)
                                            if (findQ) {
                                                setData(data?.filter(d => d?._id != x?._id).concat({ qn: x?.qn, ans: op, point, _id: x?._id }))
                                            } else {
                                                setData(data.concat({ qn: x?.qn, ans: op, point, _id: x?._id }))
                                            }
                                        }} component={"img"} sx={{ cursor: "pointer", width: "18px", height: "18px" }} src={isChecked ? "assets/posts/check-circle-solid.svg" : "assets/posts/check-circle.svg"} />
                                        <Typography sx={{ marginLeft: "10px", fontSize: pxToRem(14), color: (x?.editable == false && op == x?.ansKey) ? "green" : "black" }}>{op}</Typography>
                                    </Box>
                                )
                            })}
                        </Box>
                    ))}
                    <Button
                        onClick={() => {
                            if (data?.length < quizData?.length) {
                                showToast("Please complete all questions", "warning")
                                return
                            }
                            let earned = data?.map(x => x?.point).reduce((a, b) => a + b, 0)
                            let total = quizData?.map(x => x?.point).reduce((a, b) => a + b, 0)
                            updateQuizData(earned, total, data?.map(x => ({ qn: x?.qn, ans: x?.ans, _id: x?._id })))
                        }}
                        sx={{ width: "15vw", height: "50px", mx: "auto" }} variant="contained" >
                        Submit
                    </Button>
                </Box>
            </Box>
        </>
    )
}