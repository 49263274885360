import { createSlice } from "@reduxjs/toolkit";

const pointFilterSlice = createSlice({
    name: "pointFilter",
    initialState: {
        filters: {}
    },
    reducers: {
        updatePointfilter: (state, action) => {
            state.filters = action.payload
        },
    }
})

export const { updatePointfilter } = pointFilterSlice.actions

export default pointFilterSlice.reducer