import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { pxToRem } from "../theme/typography";

export default function ThreeSelectionTab({t1,t2,t3,style,textStyle,setCurrentTab}) {
    const [selected,setSelected]=useState(0)
    return (
        <Box  sx={[{ display: "flex", background: "#0DB1D410", borderRadius: "60px", marginTop: "10px", marginX: "auto", height: "40px", width: "100%", boxShadow: "0px 5px 10px #2626260D" },style]}>
            <Box onClick={(e)=>{
                setSelected(0)
                setCurrentTab(0)
            }}  sx={{cursor:"pointer", flex: 1,borderRadius:"60px", display: "flex",background:selected==0?"#0DB1D4":"transparent", justifyContent: "center", alignItems: "center" }}>
                <Typography sx={[{color:selected==0?"white":"black",fontSize:pxToRem(14),fontWeight:selected==0?"600":"400"},textStyle]}>{t1}</Typography>
            </Box>
            <Box onClick={(e)=>{
                setSelected(1)
                setCurrentTab(1)
            }} sx={{ cursor:"pointer",flex: 1,borderRadius:"60px", display: "flex",background:selected==1?"#0DB1D4":"transparent", justifyContent: "center", alignItems: "center" }}>
                <Typography sx={[{color:selected==1?"white":"black",fontSize:pxToRem(14),fontWeight:selected==1?"600":"400"},textStyle]}>{t2}</Typography>
            </Box>
            <Box onClick={(e)=>{
                setSelected(2)
                setCurrentTab(2)
            }} sx={{ cursor:"pointer",flex: 1,borderRadius:"60px", display: "flex",background:selected==2?"#0DB1D4":"transparent", justifyContent: "center", alignItems: "center" }}>
                <Typography sx={[{color:selected==2?"white":"black",fontSize:pxToRem(14),fontWeight:selected==2?"600":"400"},textStyle]}>{t3}</Typography>
            </Box>
        </Box>
    )
}

