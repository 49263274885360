import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { pxToRem } from '../theme/typography';
import { LocalizationProvider, StaticDatePicker, StaticTimePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from "moment";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minHeight: "15vh",
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: "5px",
    p: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
};

export default function TimePickerModal({ open, setOpen, value, onChange }) {
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [date, setDate] = React.useState(moment())

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>

            <div>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                            <Box sx={{ width: "20px" }} />
                            <Box onClick={() => {
                                handleClose()
                            }} sx={{ width: "20px", height: "20px", cursor: "pointer" }} component="img" src='assets/android-cancel.png' />
                        </Box>
                        <Box sx={{ flex: 1, minHeight: "100px" }}>
                            <StaticTimePicker
                                defaultValue={value}
                                onAccept={value => {
                                    onChange(moment(value))
                                    setOpen(false)
                                }}
                                onClose={() => {
                                    setOpen(false)
                                }}
                            />
                        </Box>
                    </Box>
                </Modal>
            </div>
        </LocalizationProvider>
    );
}