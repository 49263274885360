import { Box, Typography, Button, TextField, useTheme, useMediaQuery } from "@mui/material";
import { pxToRem } from "../../theme/typography";
import { useState } from "react";
import hit from "../../services/apiManager";
import showToast from "../../utils/showToast";
import { useDispatch } from "react-redux";
import { setLoading } from "../../store/load";

export default function LandingPageContactUsSection() {
    const theme = useTheme()
    const [email, setEmail] = useState("")
    const [message, setMessage] = useState("")
    const [name, setName] = useState("")
    const isMobile = useMediaQuery(theme.breakpoints.down("md"))
    const dispatch = useDispatch()
    const sendSupport = async () => {
        try {
            if (name == "" || email == "" || message == "") {
                showToast("Please fill the contact form", "error")
                return
            }
            dispatch(setLoading(true))
            let res = await hit("/supports/add", "post", { email, message, name })
            if (!res.err) {
                showToast("Send Successfully!")
            } else {
                showToast(res?.msg, "error")

            }
        } catch (err) {

        } finally {
            dispatch(setLoading(false))
        }
    }

    return (
        <Box sx={{ display: "flex", width: "100vw", alignItems: "center", marginTop: "50px", px: { xs: "10px", md: "8%" } }}>
            <Box sx={{ flex: 1, display: "flex", flexDirection: "column", marginRight: "10px", justifyContent: "flex-start" }}>
                <Typography sx={{ fontSize: pxToRem(30), fontWeight: "600", textAlign: "left" }}>Contact <Typography component={"span"} color="primary" sx={{ fontSize: pxToRem(30), fontWeight: "600" }}>Us</Typography></Typography>
                <br />
                <Typography lineHeight={"30px"} textAlign={"left"}>Email us with any questions or inquiries. We would be happy to answer them as soon as we can.</Typography>
                <br />
                <TextField value={name} onChange={e => {
                    setName(e.target.value)
                }} placeholder="Name*" />
                <TextField onChange={e => {
                    setEmail(e.target.value)
                }} value={email} sx={{ marginY: "10px" }} placeholder="Email*" />
                <TextField onChange={e => {
                    setMessage(e.target.value)
                }} value={message} placeholder="Message*" multiline={true} rows={5} />
                <br />
                <Button onClick={() => {
                    sendSupport()
                }} sx={{ width: "200px", marginTop: "20px", height: "50px", fontSize: pxToRem(12), borderRadius: 0 }} variant="contained">Send</Button>
            </Box>
            {!isMobile && <Box display={"flex"} flex={1}>
                <img src="assets/landing/contact.png" alt="about logo" style={{ flex: 1, width: "30vw", objectFit: "contain", height: "30vw" }} />
            </Box>}
        </Box>
    )
}