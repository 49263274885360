

import { Box, Button, Typography } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SearchBar from "../components/Home/SearchBar";
import PostComponent from "../components/PostComponent";
import GroupScoreProfile from "../components/ScoreBoard/GroupScoreProfile";
import ScoreCard from "../components/ScoreBoard/ScoreCard";
import ThreeSelectionTab from "../components/threeSelectionTab";
import { pxToRem } from "../theme/typography";
import FriendsComp from "../components/Profile/FriendsComp";
import EventComponent from "../components/EventComponent";
import AddMemberModal from "../components/AddMemberModal";

export default function FullGroupDetails() {
    const navigate = useNavigate()
    const showTeams = useSelector(state => state.test)?.showTeams
    const [currentTab, setCurrentTab] = useState(0)
    const [open, setOpen] = useState(false)
    return (
        <Box sx={{ px: "5%", pt: "20px", overflowY: "auto", display: "flex", flexDirection: "column", pr: "10%", flex: 1, width: "100%", background: "linear-gradient(119deg,#E6F6F9,white,rgb(253,243,213))" }}>


            <Box sx={{ display: "flex", width: "100%", }}>
                <Box sx={{ flex: 1, minHeight: "100px" }}>
                    <GroupScoreProfile />
                    <br />
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Button startIcon={<Box sx={{ height: "15px", width: "15px" }} component={"img"} src="assets/home/chat.png" ></Box>} variant="contained" sx={{ height: "45px", flex: 1 }}>Group Chat</Button>
                        <Button onClick={() => {
                            setOpen(true)
                        }} variant="contained" sx={{ height: "45px", flex: 1, marginLeft: "20px", background: "#F5BA16" }}><span style={{ fontSize: pxToRem(20) }}>+</span>&nbsp;Add Members</Button>
                    </Box>
                    <br />
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Button onClick={() => {
                            navigate("/user/sub")
                        }} variant="outlined" sx={{ height: "45px", flex: 1 }}>Create SubGroup</Button>
                        <Button onClick={() => {
                            navigate("/user/manual")
                        }} variant="outlined" sx={{ height: "45px", flex: 1, marginLeft: "20px" }}>Manual Submission</Button>
                    </Box>
                    <br />
                    <Box sx={{ height: "20px" }} />
                </Box>
                <Box sx={{ flex: 1, marginLeft: "4%" }}>
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <Typography sx={{ fontSize: pxToRem(16), fontWeight: "600" }}>My Subgroups</Typography>
                        <Typography sx={{ fontSize: pxToRem(14) }}>Views All</Typography>
                    </Box>
                    <Box sx={{ display: "flex" ,marginTop:"10px",justifyContent:"space-between"}}>
                        <SubComponent title={"Subgroup 001"} />
                        <SubComponent title={"Subgroup 003"} />
                    </Box>
                    <Box sx={{ display: "flex" ,marginTop:"10px",justifyContent:"space-between"}}>
                        <SubComponent title={"Subgroup 004"} />
                        <SubComponent title={"Subgroup 005"} />
                    </Box>
                    <ThreeSelectionTab style={{ background: "white",marginTop:"20px" }} setCurrentTab={setCurrentTab} t1="Feed" t2="Members" t3={"Calendars"} />
                    {currentTab == 0 &&
                        [1, 2, 4, 5].map((x, i) => (
                            <PostComponent containerStyle={{ marginTop: "20px" }} />
                        ))
                    }
                    {currentTab == 1 &&
                        <FriendsComp />
                    }
                    {currentTab == 2 &&
                        <Box sx={{ boxShadow: "0px 5px 20px #26262626", p: "10px", borderRadius: "5px", background: "white", marginTop: "20px" }}>
                            <EventComponent />
                            <br />
                            <EventComponent />
                        </Box>
                    }
                    <Box sx={{ height: "20px" }} />
                </Box>

            </Box>

            <AddMemberModal open={open} setOpen={setOpen} />
        </Box>
    )
}

const SubComponent = ({ title }) => {
    const [show, setShow] = useState(false)
    return (
        <Box sx={{ width: "47%",padding:"10px",borderRadius:"5px",textAlign:"left", background: "white", boxShadow: "0px 5px 20px #26262626" }}>
            <Typography sx={{fontSize:pxToRem(18),fontWeight:"600"}}>{title}</Typography>
            <Typography sx={{fontSize:pxToRem(14),marginY:"7px",fontWeight:"400"}}>Average Points</Typography>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Typography color={"primary"} sx={{ fontSize: pxToRem(22) ,fontWeight:"600"}}>{show ? "2760" : "****"}</Typography>
                <Box onClick={()=>{
                    setShow(!show)
                }} sx={{ height: "20px", width: "20px" }} component="img" src={show ? "assets/login/eye.svg" : "assets/signup/eye-off.svg"} />
            </Box>
        </Box>
    )
}