import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { image_url } from '../services/endpoints';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setPostData, setToInitalPostData } from '../store/post';

const style = {
  position: 'absolute' ,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "60vw",
  minHeight:"60vh",
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius:"5px",
  p: "10px",
  display:"flex",
  flexDirection:"column",
  alignItems:"center"
};

export default function ImageModal({open,setOpen,link,mediaType,thumblink}) {
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const dispatch=useDispatch()
  const navigate=useNavigate()
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
            <Box component={(mediaType==0||mediaType==undefined)?"img":"video"} controls={true} src={link?image_url+link:"https://picsum.photos/500"} sx={{height:"55vh",width:"100%",objectFit:"contain"}} />
            <Button onClick={()=>{
                handleClose()
                dispatch(setToInitalPostData())
                navigate("/user/post1")
                if(mediaType==0){
                  dispatch(setPostData({key:"photos",value:[link]}))
                }else{
                  dispatch(setPostData({key:"videos",value:[link]}))
                  dispatch(setPostData({key:"thumbnails",value:[thumblink]}))

                }
            }} sx={{width:"150px",marginX:"auto",marginY:"20px"}} variant="contained" >Post</Button>
        </Box>
      </Modal>
    </div>
  );
}