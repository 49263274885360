import { Box, Button, Modal, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { pxToRem } from "../theme/typography";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../store/load";
import hit from "../services/apiManager";
import showToast from "../utils/showToast";
import { logoutThunk } from "../store/auth";
import { clearDraftData } from "../store/drafts";
import { clearTeam } from "../store/team";
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "25vw",
    minHeight: "25vh",
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: "5px",
    p: "5px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
};
export default function Settings() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const user = useSelector(state => state.auth)?.user
    const [modalOpen, setModalOpen] = useState(false)

    const deleteAccount = async () => {
        try {
            dispatch(setLoading(true))
            const res = await hit(`/users/${user?._id}`, "delete")
            if (res?.err) {
                showToast(res?.msg, "error")
            } else {
                localStorage.clear()
                showToast("Account delete successfully", "success")
                dispatch(logoutThunk())
                dispatch(clearTeam())
                dispatch(clearDraftData())
                navigate("/landing", { replace: true })
            }
        } catch (err) {
        } finally {
            dispatch(setLoading(false))
        }
    }
    return (
        <Box sx={{ px: "5%", pt: "20px", display: "flex", pr: "10%", overflowY: "auto", flex: 1, width: "100%", background: "linear-gradient(119deg,#E6F6F9,white,rgb(253,243,213))" }}>
            <Box sx={{ flex: 1, minHeight: "100px", textAlign: "left" }}>
                <Typography sx={{ fontSize: pxToRem(16), fontWeight: "600" }}>Account Settings</Typography>
                <Box sx={{ width: "100%", borderRadius: "5px", marginTop: "20px", background: "white", boxShadow: "0px 5px 20px #26262626", p: "10px", minHeight: "100px" }}>
                    <RowComp onClick={() => navigate("/user/editProfile")} title={"Edit Profile"} />
                    <br />
                    <RowComp onClick={() => navigate("/user/changePassword")} t title={"Change Password"} />
                    {/* <br />
                    <RowComp onClick={() => navigate("/user/subs")} title={"Subscription"} /> */}
                    <br />
                    <RowComp onClick={() => navigate("/user/access")} title={"My Access Code"} />
                    <br />
                    <RowComp onClick={() => navigate("/user/chatSettings")} title={"Chat Settings"} />
                    <br />
                    <RowComp onClick={() => navigate("/user/notis")} title={"Notification"} />
                    <br />
                    <br />
                    <Box sx={{ display: "flex", marginBottom: "25px", width: "100%", justifyContent: "center", alignItems: "center" }}>
                        <Button variant="contained" sx={{ width: "150px", background: "rgb(254,90,89)", borderRadius: "0px", marginX: "auto" }} onClick={() => setModalOpen(true)}>
                            Delete Account
                        </Button>
                    </Box>
                </Box>
            </Box>
            <Box sx={{ flex: 1, marginLeft: "5%", minHeight: "100px", textAlign: "left" }}>
                <Typography sx={{ fontSize: pxToRem(16), fontWeight: "600" }}>Contact Us</Typography>
                <Box sx={{ width: "100%", borderRadius: "5px", marginTop: "20px", background: "white", boxShadow: "0px 5px 20px #26262626", p: "10px", minHeight: "100px" }}>
                    <RowComp onClick={() => navigate("/user/terms")} title={"Terms & Services"} />
                    <br />
                    <RowComp onClick={() => navigate("/user/aboutUs")} title={"About Us"} />
                    <br />
                    <RowComp onClick={() => navigate("/user/contact")} title={"Contact Us"} />
                    <br />
                    <RowComp onClick={() => navigate("/user/faq")} title={"FAQ"} />
                    <br />
                    <br />
                </Box>
            </Box>
            {
                modalOpen &&
                <Modal
                    open={modalOpen}
                    onClose={() => setModalOpen(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <h6 style={{ marginTop: 20, textAlign: 'center' }}>Are you sure want to Delete Account?</h6>
                        <div className="d-flex" style={{ marginTop: 15 }}>
                            <Button sx={{ fontSize: pxToRem(14), fontWeight: "400", marginTop: "5px", borderRadius: "5px", marginX: "auto", height: "45px", width: "100px", }} variant="contained"
                                onClick={() => deleteAccount()}
                            >
                                Yes
                            </Button>
                            <Button style={{ marginLeft: 23 }} sx={{ fontSize: pxToRem(14), fontWeight: "400", marginTop: "5px", borderRadius: "5px", marginX: "auto", height: "45px", width: "100px", backgroundColor: 'red' }} variant="contained" onClick={() => setModalOpen(false)}>
                                No
                            </Button>
                        </div>
                    </Box>
                </Modal>
            }
        </Box>
    )
}

const RowComp = ({ title, onClick }) => {
    return (
        <Box onClick={() => {
            if (onClick) {
                onClick()
            }
        }} sx={{ cursor: "pointer", height: "50px", padding: "10px", background: "#0DB1D41A", display: "flex", alignItems: "center", borderRadius: "5px" }}>
            <Typography sx={{ fontWeight: "600", fontSize: pxToRem(14), flex: 1, marginLeft: "5px" }}>{title}</Typography>
            <Box component={"img"} sx={{ height: "10px", width: "10px", rotate: "180deg", objectFit: "contain" }} src="assets/settings/direction.png" />
        </Box>
    )
}