import { Box, Button, Typography } from "@mui/material";
import { pxToRem } from "../theme/typography";
import Calendar from 'react-calendar'
import Input, { DatePickerInput, DropDownInput } from '../components/Login/Input'
import EventComponent from '../components/EventComponent'
import '../css/Calendar.css';
import { useEffect, useState } from "react";
import moment from "moment";
import { setLoading } from "../store/load";
import hit from "../services/apiManager";
import { events, image_url, scores } from "../services/endpoints";
import { useDispatch, useSelector } from "react-redux";
import showToast from "../utils/showToast";
import DatePickerModal from "../components/DatePickerModal";
import TimePickerModal from "../components/TimePickerModal";
import { Avatar } from "rsuite";
import { getAllTeamThunk } from "../store/team";
// import { getAllScoreData } from "../store/scores";
import DeleteModal from "../components/DeleteModal";

export default function TeamCalendarComp({ group }) {
    const [value, onChange] = useState(null);


    const dispatch = useDispatch()
    const user = useSelector(state => state?.auth?.user)

    const [allEvents, setAllEvents] = useState([])
    const [isFetching, setIsFetching] = useState(false)
    const [showPopOver, setShowPopOver] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [deleteId, setDeleteId] = useState("")
    // const timeZone = RNLocalize.getTimeZone()
    const [monthChanged, setMonthChanged] = useState(moment().format("YYYY-MM-DD"))
    const [monthlyEvents, setMonthlyEvents] = useState([])
    const [scoringDay, setScoringDay] = useState([])
    const [nonScoringEvent, setNonScoringEvent] = useState([])
    const [selectedDate, setSelectedDate] = useState(null)
    const [eventParticular, setEventParticular] = useState([])
    const [scoreDetail, setScoreDetail] = useState([])

    const [currentDate, setCurrentDate] = useState(moment().format("YYYY-MM-DD"))

    useEffect(() => {
        setSelectedDate(null)
        let date = moment(monthChanged).format("MM-DD-YYYY")
        getInteractionDetails()
        // getAllEvents(date)
        // return () => { date };
    }, [monthChanged])

    useEffect(() => {
        let date = moment(monthChanged).format("MM-DD-YYYY")
        getAllEvents(date)
    }, [scoreDetail])


    useEffect(() => {
        let temp = allEvents?.filter(x => moment(x?.startDateTime ? x?.startDateTime : x?.currentDate ? x?.currentDate : x?.scoreStartTime).format("DD") == moment(selectedDate).format("DD"))
        setEventParticular(temp)
    }, [selectedDate, allEvents])

    const getInteractionDetails = async () => {
        try {
            dispatch(setLoading(true))
            let res = await hit(`${scores?.getscore}?user=${user?._id}`, "get")
            if (res?.err) {
                showToast(res?.msg, "error")
            } else {
                setScoreDetail(res?.data)
            }
        } catch (e) {
        } finally {
        }
    }
    const getAllEvents = async (month) => {
        try {
            let res = await hit(`${events?.event}?groups=${group?._id}&date=${month}&populate=user&limit=100`, "get")
            if (res?.err) {
                showToast(res?.msg, "error")
            } else {
                let upcomingArray = []
                let score = []
                let nonScore = []
                let scoreDet = scoreDetail
                let temp = res?.data?.events
                let temp2 = res?.data?.posts?.results
                temp.map(item => {
                    if (item?.isScoring == true) {
                        score.push(moment(item?.startDateTime).format("YYYY-MM-DD"))
                    } else {
                        nonScore.push(moment(item?.startDateTime).format("YYYY-MM-DD"))
                    }
                    upcomingArray.push(moment(item?.startDateTime).format("YYYY-MM-DD"))
                })
                let fTemp2 = []
                temp2 = temp2?.filter(x => {
                    let endTime = moment(x?.scoreEndTime)
                    if (endTime.toDate() < moment().toDate()) {
                        return false
                    }
                    return true
                })
                temp2.map(item => {
                    let filterArr = []
                    filterArr.push(...scoreDet.filter(x => (x?.postId == item?._id && x?.user == user?._id)))
                    if (item?.scoreCount > filterArr.length) {
                        let startTime = moment(item?.scoreStartTime).unix()
                        let endTime = moment(item?.scoreEndTime).unix()
                        if ((endTime > moment().unix() && moment().unix() > startTime)) {
                            let i = item
                            i = { ...i, currentDate }
                            score.push(moment(currentDate).format("YYYY-MM-DD"))
                            upcomingArray.push(moment(currentDate).format("YYYY-MM-DD"))
                            fTemp2.push(i)
                        } else if (moment().unix() > startTime) {
                            score.push(moment(item?.scoreStartTime).format("YYYY-MM-DD"))
                            upcomingArray.push(moment(item?.scoreStartTime).format("YYYY-MM-DD"))
                            fTemp2.push(item)
                        }
                    }
                })
                let finalTemp = temp.concat(fTemp2);
                setAllEvents(finalTemp.sort(function (a, b) {
                    return Math.abs(moment(a?.startDateTime ? a?.startDateTime : a?.scoreStartTime).unix() - moment().unix()) - Math.abs(moment(b?.startDateTime ? b?.startDateTime : b?.scoreStartTime).unix() - moment().unix())
                }))
                setScoringDay(score)
                setNonScoringEvent(nonScore)
                setMonthlyEvents(upcomingArray)
                // saveEvents(temp, temp2)
            }
        } catch (e) {
        } finally {
            dispatch(setLoading(false))
        }
    }
    const attendEve = async (id, type) => {
        try {
            dispatch(setLoading(true))
            let res = await hit(`${events?.attend}/${id}`, "post", { type })
            if (res?.err) {
                showToast(res?.msg, "error")
            } else {
                showToast("Response recorded.")
                let date = moment(monthChanged).format("MM-DD-YYYY")
                getAllEvents(date)
            }
        } catch (e) {
        } finally {
            dispatch(setLoading(false))
        }
    }
    const deleteEvent = async (id) => {
        setDeleteModal(false)
        try {
            dispatch(setLoading(true))
            let res = await hit(`${events?.event}/${id}`, "delete")
            if (res?.err) {
                showToast(res?.msg, "error")
            } else {
                showToast("Event deleted successfully.")
                let date = moment(monthChanged).format("MM-DD-YYYY")
                getAllEvents(date)
            }
        } catch (e) {
        } finally {
            dispatch(setLoading(false))
            setDeleteId("")
        }
    }

    useEffect(() => {
        dispatch(getAllTeamThunk())
        // dispatch(getAllScoreData())
    }, [])

    return (
        <Box >
            <Box sx={{ flex: 1, minWidth: "30vw", minHeight: "100px" }}>
                <Calendar formatShortWeekday={(locale, date) => {
                    return moment(date).format("dd").charAt(0)
                }}
                    onActiveStartDateChange={({ action, activeStartDate, value, view }) => {

                        if (view == "month") {
                            dispatch(setLoading(true))
                            setMonthChanged(moment(activeStartDate).format("YYYY-MM-DD"))
                            onChange(null)
                            setSelectedDate(null)
                        }
                    }}
                    tileContent={({ date, view }) => {
                        let scoring = scoringDay.includes(moment(date).format("YYYY-MM-DD"))
                        let nonScoring = nonScoringEvent.includes(moment(date).format("YYYY-MM-DD"))
                        let nonscount = nonScoringEvent.filter(x => x == moment(date).format("YYYY-MM-DD"))?.length
                        let isMarked = monthlyEvents.includes(moment(date).format("YYYY-MM-DD"))
                        let eventCounts = monthlyEvents.filter(x => x == moment(date).format("YYYY-MM-DD")).length
                        let isSelected = moment(date).format("YYYY-MM-DD") == selectedDate
                        const today = moment(date).format("YYYY-MM-DD") == moment().format("YYYY-MM-DD")
                        if (isMarked) {
                            return (
                                <Box
                                    sx={{ position: "relative" }}
                                >
                                    {scoring &&
                                        <Box sx={{ background: "#09ab34", display: "flex", height: "10px", width: "10px", borderRadius: "10px", position: 'absolute', right: 0, top: -22, justifyContent: "center", alignItems: "center" }}>
                                            <Typography sx={{ fontWeight: "600", color: "white", fontSize: "8px" }}>{eventCounts - nonscount}</Typography>
                                        </Box>
                                    }
                                    {nonScoring &&
                                        <Box sx={{ background: "#FF5A5A", display: "flex", height: "10px", width: "10px", borderRadius: "10px", position: 'absolute', right: 0, top: -22, justifyContent: "center", alignItems: "center" }}>
                                            <Typography style={{ fontWeight: "600", color: "white", fontSize: "8px" }}>{nonscount}</Typography>
                                        </Box>
                                    }
                                </Box>
                            );
                        }
                        return null
                    }}
                    onClickDay={(value, event) => {
                        onChange(value)
                        setSelectedDate(moment(value).format("YYYY-MM-DD"))
                    }} value={value} />

                <>
                    {selectedDate == null ?
                        <Typography style={{ fontWeight: "600", textAlign: "left", marginLeft: "16px", marginTop: "16px", marginBottom: "8px" }}>{moment(monthChanged).format("MMMM")}</Typography>
                        :
                        <Typography style={{ fontFamily: "600", textAlign: "left", marginLeft: "16px", marginTop: "16px", marginBottom: "8px" }}>{moment(monthChanged).format("MMMM")} {moment(selectedDate).format("Do")}</Typography>
                    }
                    {selectedDate == null ? allEvents?.map((i, j) => {
                        return (
                            <EventComponent
                                i={i} j={j} key={i?._id}
                                onPressYes={() => {
                                    attendEve(i?._id, "attend")
                                }}
                                onPressNo={() => {
                                    attendEve(i?._id, "unattend")
                                }}
                                onPressM={() => {
                                    attendEve(i?._id, "maybe")
                                }}
                                onPressDelete={() => {
                                    setDeleteModal(true)
                                    setDeleteId(i?._id)
                                }}
                                onPressPost={() => {
                                    // navigation.navigate("IndividualPost", { id: i?._id })
                                }}
                            />
                        )
                    })
                        :
                        eventParticular.map((i, j) => {
                            return (
                                <EventComponent
                                    i={i} j={j} key={i?._id}
                                    onPressYes={() => {
                                        attendEve(i?._id, "attend")
                                    }}
                                    onPressNo={() => {
                                        attendEve(i?._id, "unattend")
                                    }}
                                    onPressM={() => {
                                        attendEve(i?._id, "maybe")
                                    }}
                                    onPressDelete={() => {
                                        setDeleteModal(true)
                                        setDeleteId(i?._id)
                                    }}
                                    onPressPost={() => {
                                        // navigation.navigate("IndividualPost", { id: i?._id })
                                    }}
                                />
                            )
                        })}
                    <Box sx={{ height: "20px" }} />
                </>
                <DeleteModal
                    open={deleteModal}
                    title={"You want to delete this event?"}
                    handleClose={() => {
                        setDeleteModal(false)
                    }}
                    onPressNo={() => setDeleteModal(false)}
                    onPressOut={() => setDeleteModal(false)}
                    onPressYes={() => deleteEvent(deleteId)}
                />
            </Box>
        </Box>
    )
}


