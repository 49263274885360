import { Avatar, Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { image_url } from "../../services/endpoints";
import { pxToRem } from "../../theme/typography";

export default function ProfileComp({style,userData}) {
    const userStore=useSelector(state=>state?.auth?.user)
    const [user,setUser]=useState(null)
    useEffect(()=>{
        if(userData){
            setUser(userData)
        }else{
            setUser(userStore)
        }
    },[userStore,userData])

   

    return (
        <Box sx={[{ position:"relative", padding: "5px",flex:1,},style]}>
            <Box sx={{ height: "270px", borderRadius: "5px", width: "100%", objectFit: "cover" }} component="img" src={image_url+user?.coverPic}/>
            <Avatar src={image_url+user?.profilePic} sx={{ height: "100px",border:"3px solid white", width: "100px",position:"absolute",left:"41%" ,top:"220px"}} />
            <Typography sx={{marginTop:"52px",fontWeight:"600",fontSize:pxToRem(24),textAlign:"center"}}>{user?.firstName} {user?.lastName}</Typography>
            <Typography sx={{fontWeight:"400"}}>{user?.email}</Typography>
       </Box>
    )
}