import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { pxToRem } from '../theme/typography';
import { QRCodeCanvas } from "qrcode.react";
import showToast from '../utils/showToast';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setLoading } from '../store/load';
import { base_url, sendEmail } from '../services/endpoints';
import hit from '../services/apiManager';
import Input from './Login/Input';
import { saveAs } from 'file-saver';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "25vw",
    minHeight: "15vh",
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: "5px",
    p: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
};

export default function AddMemberModal({ open, setOpen, selectedTeam }) {
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [type,setType]=useState("code")
    const [email,setEmail]=useState("")
    const dispatch=useDispatch()
    const submitMail = async () => {
        if (email.trim() == "") {
            showToast("Email is reqiured", "warning")
            return
        }
        try {
            dispatch(setLoading(true))
            let res = await hit(sendEmail, "post", { to: email, subject: "Group acess code.", text: selectedTeam?.accessCode })
            if (res?.err) {
                showToast(res?.msg, "error")
            } else {
                showToast("Code sent sucessfully.")
                setEmail("")
                setOpen(false)
                setType("code")
            }
        } catch (e) {
        } finally {
            dispatch(setLoading(false))
        }
    }
    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                        <Box sx={{ width: "20px" }} />
                        <Typography sx={{ fontSize: pxToRem(16), fontWeight: "600" }}>Add Member</Typography>
                        <Box onClick={() => {
                            handleClose()
                        }} sx={{ width: "20px", height: "20px", cursor: "pointer" }} component="img" src='assets/android-cancel.png' />
                    </Box>
                    <Box sx={{ flex: 1, minHeight: "100px" }}>
                        <Box sx={{ display: "flex", py: "20px", marginTop: "10px", flexDirection: "column", background: "white", borderRadius: "5px", minHeight: "100px" }}>
                            <Typography sx={{ fontSize: pxToRem(14), textAlign: "center" }}>
                                You can add members below through copy/paste of access Code or selection of "Add Members via Friends or Email.
                            </Typography>
                            {/* <Box component={"img"} src="assets/access/qr.png" sx={{ height: "100px", objectFit: "contain" }} /> */}
                           {type=="code"&&<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <QRCodeCanvas
                                    id="qrCode"
                                    value={selectedTeam?.accessCode}
                                    size={100}
                                    bgColor={"white"}
                                    level={"H"}
                                    style={{ marginTop: "10px", marginBottom: "10px" }}
                                />
                            </Box>}
                            {type=="code"&&<Box sx={{ width: "100%", marginX: "auto", marginTop: "20px" }}>
                                <Typography sx={{ fontWeight: "400", marginLeft: "5px", fontSize: pxToRem(10), fontWeight: "600", textAlign: "left" }}>Access Code</Typography>
                                <Box sx={{ height: "45px", display: "flex", alignItems: "center", marginTop: "5px", borderRadius: "5px", background: "rgb(232,232,232)" }}>
                                    <Typography sx={{ fontWeight: "500", flex: 1, textAlign: "left", fontWeight: "500", paddingLeft: "10px" }}>{selectedTeam?.accessCode}</Typography>
                                    <Box onClick={() => {
                                        if (navigator?.clipboard) {
                                            navigator.clipboard.writeText(selectedTeam?.accessCode).then(() => {
                                                showToast('Access code copied to clipboard');
                                                /* Resolved - text copied to clipboard successfully */
                                            }, () => {
                                                console.error('Failed to copy');
                                                /* Rejected - text failed to copy to the clipboard */
                                            });
                                        } else {
                                            showToast('Clipboard not supported', "error");

                                        }
                                    }} sx={{ height: "45px", cursor: "pointer", width: "100px", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "5px", background: "#F5BA16" }}>
                                        <Typography sx={{ color: "white", fontSize: pxToRem(14), fontWeight: "500" }}>Copy Code</Typography>
                                    </Box>
                                </Box>
                            </Box>}
                            {type=="email"&&
                                <Box>
                                <Input placeholder={"Type email ...."} title={"Email"} value={email} containerStyle={{ marginTop: "10px" }} onChange={t => setEmail(t)} titleStyle={{ textAlign: "left" }} />
                                <Button onClick={() => {
                                    submitMail()
                                }} variant="contained" sx={{ marginY: "10px" }} >
                                    Send
                                </Button>
                            </Box>
                            }
                            <br />
                            <Button onClick={() => {
                                // handleClose()
                                if(type=="code"){
                                    setType("email")
                                }else if(type=="email"){
                                    setType("code")
                                    // submitMail()
                                }
                               
                            }} sx={{ fontSize: pxToRem(14), fontWeight: "400", marginTop: "5px", borderRadius: "5px", marginX: "auto", height: "45px", width: "300px" }} variant="contained">
                                {type=="code"?"Add Members Via Friends or email":"Add Members Via QR Code or access code"}
                            </Button>
                            <Button onClick={async() => {
                                try{
                                    dispatch(setLoading(true))
                                    let res=await hit("/pdfs","post",{groupName:selectedTeam?.name,accessCode:selectedTeam?.accessCode},{responseType: 'blob'})
                                    const pdfBlob = new Blob([res.data], { type: 'application/pdf' });
                                    saveAs(pdfBlob, 'generatedDocument.pdf')
                                }catch(err){

                                }finally{
                                    dispatch(setLoading(false))
                                }
                            }} sx={{ fontSize: pxToRem(14), fontWeight: "400", marginTop: "5px", borderRadius: "5px", marginX: "auto", height: "45px", width: "300px" }} variant="contained">
                                Download PDF
                            </Button>
                        </Box>
                        <br />
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}