import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { pxToRem } from '../theme/typography';
import { useTheme } from '@mui/material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "25vw",
    minHeight: "15vh",
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: "5px",
    p: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
};

export default function AddPictureModal({ open, setOpen, handleFile }) {
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const theme = useTheme()
    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                        <Box sx={{ width: "20px" }} />
                        <Typography sx={{ fontSize: pxToRem(16), fontWeight: "600" }}>Add Picture</Typography>
                        <Box onClick={() => {
                            handleClose()
                        }} sx={{ width: "20px", height: "20px", cursor: "pointer" }} component="img" src='assets/android-cancel.png' />
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "space-around", alignItems: "center", minHeight: "100px" }}>
                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <Box sx={{ borderWidth: "2px", position: "relative", borderStyle: "solid", borderColor: theme.palette.primary.main, display: "flex", background: "#D0EEEF", height: "50px", width: "50px", borderRadius: "50px", justifyContent: "center", alignItems: "center" }}>
                                <Box component={"img"} src="assets/modal/pdf.png" sx={{ height: "15px", width: "15px", position: "relative" }} />
                                <input type="file" id="file-selector" accept="application/pdf, */xls" onChange={(e)=>handleFile(e,"pdf")} style={{ height: "80px", width: "80px", cursor: "pointer", top: -10, position: "absolute", left: 0, opacity: 0, zIndex: 111 }}></input>
                            </Box>
                            <Typography>Documents</Typography>
                        </Box>
                        <Box sx={{ width: "40px" }} />
                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <Box sx={{ display: "flex", height: "50px", position: "relative", width: "50px", borderRadius: "50px", justifyContent: "center", alignItems: "center" }}>
                                <Box component={"img"} src="assets/modal/gallery.png" sx={{ height: "50px", width: "50px", position: "relative" }} />
                                <input type="file" id="file-selector" accept="image/png, image/jpeg" onChange={(e)=>handleFile(e,"picture")} style={{ height: "80px", cursor: "pointer", width: "80px", top: -10, position: "absolute", left: 0, opacity: 0, zIndex: 111 }}></input>
                            </Box>
                            <Typography>Gallery</Typography>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}