import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { pxToRem } from '../theme/typography';
import Input from './Login/Input';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "25vw",
    minHeight: "15vh",
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: "5px",
    p: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
};

export default function EnterTime({ open, setOpen,setHour,setMinute,hour,minute,addMinute }) {
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box sx={{ display: "flex", justifyContent: "center",width:"100%", alignItems: "center" }}>
                        <Typography sx={{ fontSize: pxToRem(16), fontWeight: "600" }}>Enter Time</Typography>
                    </Box>
                    <Box sx={{ flex: 1, minHeight: "100px" }}>
                        <Box sx={{ display: "flex", py: "20px", marginTop: "10px", flexDirection: "column", background: "white", borderRadius: "5px", minHeight: "100px" }}>
                            <Box sx={{display:"flex",alignItems:"center"}}>
                                <Input value={hour} inputProps={{type:"number"}} onChange={t=>setHour(t)} title={"Hours"} />
                                <Box sx={{width:"10px"}} />
                                <Input value={minute} inputProps={{type:"number"}} onChange={t=>setMinute(t)} title={"Minutes"} />
                            </Box>
                            <br />
                            <Button onClick={()=>{
                            handleClose()
                            addMinute(Number(hour==""?0:hour),Number(minute==""?0:minute))
                        }} sx={{ fontSize: pxToRem(14), fontWeight: "400", marginTop: "5px", borderRadius: "5px", marginX: "auto", height: "45px", width: "300px" }} variant="contained">
                               Add
                            </Button>
                        </Box>
                        <br />
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}