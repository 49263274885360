import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { pxToRem } from '../theme/typography';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material';
import { image_url } from '../services/endpoints';
import { setSelectedTab, setSingleDataStore } from '../store/chat';
import { Padding } from '@mui/icons-material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "25vw",
    minHeight: "15vh",
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: "5px",
    p: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
};

export default function ViewedByModal({ open, setOpen, selectedGroup, arr }) {
    const handleOpen = () => setOpen(true);
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const handleClose = () => setOpen(false);
    const [userType, setUserType] = React.useState("Viewed")
    const [viewedBy, setViewedBy] = React.useState([])
    const [notViewed, setNotViewed] = React.useState([])
    const userMe = useSelector(state => state.auth)?.user
    const teamList = useSelector(state => state?.team)?.team
    const theme = useTheme()

    React.useEffect(() => {
        if (arr?.memb) {
            const remainingObjects = arr?.memb.filter(obj => !arr?.viewed.some(selectedObj => obj._id === selectedObj._id));
            setNotViewed(remainingObjects)
        }
        return () => {
            setUserType("Viewed")
        }
    }, [arr])

    React.useEffect(() => {
        if (arr?.viewed) {
            let temp = teamList?.filter(x => x?._id == selectedGroup)
            let newArr = []
            arr?.viewed?.forEach(e => {
                if (temp[0]?.members?.includes(e?._id)) {
                    newArr.push(e)
                }
            });
            setViewedBy(newArr)
        }
    }, [arr, selectedGroup, teamList])

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                        <Box sx={{ width: "20px" }} />
                        <Typography sx={{ fontSize: pxToRem(16), fontWeight: "600" }}>Viewed By ({viewedBy?.length}/{arr?.memb?.length})</Typography>
                        <Box onClick={() => {
                            handleClose()
                        }} sx={{ width: "20px", height: "20px", cursor: "pointer" }} component="img" src='assets/android-cancel.png' />
                    </Box>
                    <Box sx={{ flex: 1, minHeight: "100px", minWidth: "20vw" }}>
                        <Box sx={{ display: "flex", py: "20px", marginTop: "10px", flexDirection: "column", background: "white", borderRadius: "5px", minHeight: "100px" }}>
                            <Box sx={{ backgroundColor: "white", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", borderRadius: "30px", marginTop: "16px", marginHorizontal: "2px", boxShadow: 24 }}>
                                <Button
                                    sx={{ backgroundColor: userType == "Viewed" ? theme.palette.primary.main : null, borderRadius: "30px", flex: 1 }}
                                    onClick={() => {
                                        setUserType("Viewed")
                                    }}>
                                    <Typography sx={{ color: userType == "Viewed" ? "white" : theme.palette.text.primary, opacity: userType == "Viewed" ? 1 : 0.4 }}>Viewed</Typography>
                                </Button>
                                <Button
                                    sx={{ backgroundColor: userType == "Not Viewed" ? theme.palette.primary.main : null, borderRadius: "30px", flex: 1 }}
                                    onClick={() => {
                                        setUserType("Not Viewed")
                                    }}>
                                    <Typography style={{ color: userType == "Not Viewed" ? "white" : theme.palette.text.primary, opacity: userType == "Not Viewed" ? 1 : 0.4 }}>Not Viewed</Typography>
                                </Button>
                            </Box>
                            <br />
                            {userType == "Viewed" &&
                                <Box style={{height:400, overflow:'scroll', padding: 15 }}>
                                    {viewedBy?.map((item) => (
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: "space-between", paddingVertical: "10px", marginTop: "10px", borderBottomWidth: "0.6px", borderColor: theme.palette.primary.main }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <Box component={"img"} src={image_url + item?.profilePic} style={{ height: "50px", width: "50px", borderRadius: "60px", marginRight: "12px" }} />
                                                <Box style={{ maxWidth: '60%', minWidth: "40%" }}>
                                                    <Typography sx={{ fontWeight: "500" }}>{item?.firstName} {item?.lastName}</Typography>
                                                    <Typography sx={[{ fontWeight: "500", fontSize: "10px" }]}>{item?.email}</Typography>
                                                </Box>
                                            </Box>
                                            {userMe?._id != item?._id &&
                                                <Box>
                                                    <Box component={"img"} onClick={() => {
                                                        dispatch(setSelectedTab(0))
                                                        dispatch(setSingleDataStore({
                                                            group: selectedGroup,
                                                            groupDetails: teamList?.find(x => x._id == selectedGroup),
                                                            user: item
                                                        }))
                                                        handleClose()
                                                        navigate("/user/chat")

                                                    }} src={"/assets/modal/chat.png"} style={{ height: "26px", cursor: "pointer", width: "26px" }} />
                                                </Box>
                                            }
                                        </Box>
                                    ))}
                                </Box>
                            }
                            {userType != "Viewed" &&
                                <Box style={{height:400, overflow:'scroll', padding: 15 }}>
                                    {notViewed?.map((item, index) => (
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: "space-between", paddingVertical: "10px", marginTop: "10px", borderBottomWidth: "0.6px", borderColor: theme.palette.primary.main }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <Box component={"img"} src={image_url + item?.profilePic} style={{ height: "50px", width: "50px", borderRadius: "60px", marginRight: "12px" }} />
                                                <Box style={{ maxWidth: '60%', minWidth: "40%" }}>
                                                    <Typography sx={{ fontWeight: "500" }}>{item?.firstName} {item?.lastName}</Typography>
                                                    <Typography sx={[{ fontWeight: "500", fontSize: "10px" }]}>{item?.email}</Typography>
                                                </Box>
                                            </Box>
                                            {userMe?._id != item?._id &&
                                                <Box>
                                                    <Box onClick={() => {

                                                        dispatch(setSelectedTab(0))
                                                        dispatch(setSingleDataStore({
                                                            group: selectedGroup,
                                                            groupDetails: teamList?.find(x => x._id == selectedGroup),
                                                            user: item
                                                        }))
                                                        handleClose()
                                                        navigate("/user/chat")
                                                    }} component={"img"} src={"/assets/modal/chat.png"} style={{ cursor: "pointer", height: "26px", width: "26px" }} />
                                                </Box>
                                            }
                                        </Box>
                                    ))}
                                </Box>
                            }
                            <br />
                        </Box>
                        <br />
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}