import { Box, Popover, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { drafts, image_url, post } from "../services/endpoints";
import { setLoading } from "../store/load";
import { setPostData, setToInitalPostData } from "../store/post";
import { getAllTeamThunk } from "../store/team";
import { pxToRem } from "../theme/typography";
import { getAllTeamIds } from "../store/teamIds";
import DeleteModal from "../components/DeleteModal";
import SearchBar from "../components/Home/SearchBar";
import PostComponent from "../components/PostComponent";
import TwoSelectionTab from "../components/TwoSelectionTab";
import hit from "../services/apiManager";
import showToast from "../utils/showToast";
import moment from "moment";

export default function Draft() {
    const dispatch = useDispatch()
    const allTeams = useSelector(state => state?.team?.team)
    const [filterFeed, setFilterFeed] = useState([])
    const [selected, setSelected] = useState(0)
    const [deleteId, setDeleteId] = useState("")
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [manualList, setManualList] = useState([])
    const [search, setSearch] = useState("")
    const [searchManual, setSearchManual] = useState("")
    const [feed, setFeed] = useState([])
    const [totalPage, setTotalPage] = useState(1)
    const [filterManualList, setFilterManualList] = useState([])
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        dispatch(getAllTeamIds())
        dispatch(getAllTeamThunk())
    }, [])

    useEffect(() => {
        getAllFeeds(1);
        getAllDrafts()
    }, [location])

    useEffect(() => {
        if (search?.length > 0) {
            let temp = feed?.filter(x => {
                let sText = search?.trim()?.toLowerCase()
                let gTexts = x?.groups?.map(g => g?.name).join(",")
                if (gTexts?.toLowerCase()?.includes(sText)) {
                    return true
                }
                if (x?.category?.toLowerCase()?.includes(sText)) {
                    return true
                }
                return false
            })
            setFilterFeed(temp)
        }
        if (search?.length == 0) {
            setFilterFeed(feed)
        }
    }, [search])

    useEffect(() => {
        if (feed) {
            setFilterFeed(feed)
        }
    }, [feed])

    const getAllFeeds = async (pg) => {
        try {
            dispatch(setLoading(true))
            let res = await hit(`/drafts/posts?page=${pg}&isScorePost=true&populate=user,groups&limit=100&global=true&date=${moment().unix() * 1000}&sortBy={"scoreStartTime":1}`, "get")
            if (res?.err) {
                showToast(res?.msg, "error")
            } else {
                setFeed(res?.data?.results)
                setTotalPage(res?.data?.totalPages)
            }
        } catch (e) {
        } finally {
            dispatch(setLoading(false))
        }
    }

    const deletePost = async (id) => {
        setShowDeleteModal(false)
        try {
            dispatch(setLoading(true))
            let res = await hit(`${post?.post}/${id}`, "delete")
            if (res?.err) {
                showToast(res?.msg)
            } else {
                showToast("Post deleted successfully")
                setFeed(feed.filter(x => x._id != id))
            }
        } catch (e) {
        } finally {
            setDeleteId("")
            dispatch(setLoading(false))
        }
    }

    const deleteF = (id) => {
        setDeleteId(id)
        setTimeout(() => {
            setShowDeleteModal(true)
        }, 700);
    }

    const setEditData = (item) => {
        dispatch(setPostData({ key: "id", value: item?._id }))
        dispatch(setPostData({ key: "isScorePost", value: item?.postType1 == "quiz" ? false : item?.isScorePost }))
        dispatch(setPostData({ key: "isThemedPost", value: item?.postType1 == "theme" ? true : false }))
        dispatch(setPostData({ key: "isPollPost", value: item?.postType1 == "poll" ? true : false }))
        dispatch(setPostData({ key: "isQuizPost", value: item?.postType1 == "quiz" ? true : false }))
        dispatch(setPostData({ key: "txt", value: item?.txt }))
        dispatch(setPostData({ key: "groups", value: item?.groups?.map(x => x?._id) }))
        dispatch(setPostData({ key: "themeColor", value: item?.themeColor }))
        dispatch(setPostData({ key: "pollEndTime", value: moment(item?.pollEndTime) }))
        dispatch(setPostData({ key: "scoreStartTime", value: moment(item?.scoreStartTime) }))
        dispatch(setPostData({ key: "scoreEndTime", value: moment(item?.scoreEndTime) }))
        dispatch(setPostData({ key: "photos", value: item?.photos }))
        dispatch(setPostData({ key: "thumbnails", value: item?.thumbnails }))
        dispatch(setPostData({ key: "attachments", value: item?.attachments ?? [] }))
        dispatch(setPostData({ key: "videos", value: item?.videos }))
        dispatch(setPostData({ key: "pollOptions", value: item?.pollOptions }))
        dispatch(setPostData({ key: "category", value: item?.category }))
        dispatch(setPostData({ key: "scoreBy", value: item?.scoreBy }))
        dispatch(setPostData({ key: "verifyBy", value: item?.verifyBy }))
        dispatch(setPostData({ key: "isApproved", value: item?.isApproved }))
        dispatch(setPostData({ key: "scorePoint", value: item?.scorePoint }))
        dispatch(setPostData({ key: "scorePoint1", value: item?.scorePoint1 }))
        dispatch(setPostData({ key: "scorePoint2", value: item?.scorePoint2 }))
        dispatch(setPostData({ key: "scoreCount", value: item?.scoreCount }))
        dispatch(setPostData({ key: "eventLocation", value: item?.eventLocation }))
        dispatch(setPostData({ key: "quizTitle", value: item?.quizTitle }))
        dispatch(setPostData({ key: "quizDesc", value: item?.quizDesc }))
        dispatch(setPostData({ key: "quizData", value: item?.quizData }))
        dispatch(setPostData({ key: "quizScoreCount", value: item?.quizScoreCount }))
        navigate('/user/post1')
    }

    const getAllDrafts = async () => {
        try {
            dispatch(setLoading(true))
            let res = await hit(drafts, "get")
            let temp = [...res?.data]
            temp.sort((a, b) => a?.data?.startDate - b?.data?.startDate)
            setManualList(temp)
        } catch (e) {
        } finally {
            dispatch(setLoading(false))
        }
    }

    useEffect(() => {
        if (searchManual.length > 0) {
            let temp = manualList?.filter(x => {
                let team = allTeams?.find(g => g?._id == x?.data?.gid)
                if (team) {
                    if (team?.name?.toLowerCase()?.includes(searchManual?.toLowerCase())) {
                        return true
                    }
                }
                if (x?.data?.title?.toLowerCase()?.includes(searchManual?.toLowerCase())) {
                    return true
                }
                if (x?.data?.category?.toLowerCase()?.includes(searchManual?.toLowerCase())) {
                    return true
                }
                return false
            })
            setFilterManualList(temp)
        }
        if (searchManual?.length == 0) {
            setFilterManualList(manualList)
        }
    }, [searchManual])

    useEffect(() => {
        if (manualList) {
            setFilterManualList(manualList)
        }
    }, [manualList])

    const deleteManual = async (id) => {
        try {
            dispatch(setLoading(true))
            let res = await hit(`${drafts}/${id}`, "delete")
            if (res?.err) {
                showToast(res?.msg, "error")
            } else {
                showToast("Deleted sucessfully.")
                getAllDrafts()
            }
        } catch (e) {
        } finally {
            setDeleteId("")
            dispatch(setLoading(false))
        }
    }

    return (
        <Box sx={{ px: "5%", pt: "20px", overflowY: "auto", display: "flex", flexDirection: "column", pr: "10%", flex: 1, width: "100%", background: "linear-gradient(119deg,#E6F6F9,white,rgb(253,243,213))" }}>
            <Typography sx={{ fontWeight: "600", textAlign: "left", fontSize: pxToRem(18) }}>Draft Post</Typography>
            <Box sx={{ width: "40vw" }}>
                <TwoSelectionTab t1="Post" t2={"Manuals"} setCurrentTab={v => { setSelected(v)}} />
                <br />
                <SearchBar 
                    value={selected == 0 ? search : searchManual} onChange={(t) => selected == 0?setSearch(t) : setSearchManual(t)}
                    onClickSearch={() => {}} 
                    placeholder={selected == 0?"Search Post" : "Search Manuals"} 
                />
                {selected == 0 && 
                <>
                    {filterFeed?.map((i, j) => (
                        <PostComponent 
                            i={i}
                            j={j}
                            key={i?._id}
                            isDraft={true}
                            onPressEdit={() => {
                                dispatch(setToInitalPostData())
                                setEditData(i)
                            }}
                            onPressDelete={(id) => {
                                deleteF(id)
                            }}
                        />
                    ))}
                </>}
                {selected == 1 &&
                    filterManualList?.map((i, j) => {
                        let endDate = i?.data?.endDate;
                        if (endDate) {
                            const endDateString = endDate.toString();
                            if (endDateString.length === 10) { // Check if the length is 10 (Unix date format)
                                endDate = endDate*1000;
                            }
                        }
                        if (moment(endDate).toDate() < moment().toDate()) { //in microseconds date format
                            return (
                                <></>
                            )
                        }
                        return (
                            <RenderList item={i} key={i?._id}
                                onPressMain={() => {
                                    navigate("/user/manual", { state: { data: i, gid: i?.data?.gid } })
                                }}
                                onPress={() => {
                                    navigate("/user/manual", { state: { data: i, gid: i?.data?.gid } })
                                }}
                                onPressDelete={() => {
                                    deleteManual(i?._id)
                                }}
                            />
                        )
                    })
                }
            </Box>
            <DeleteModal
                open={showDeleteModal}
                handleClose={() => {
                    setShowDeleteModal(false)
                    setDeleteId("")
                }}
                onPressNo={() => {
                    setShowDeleteModal(false)
                    setDeleteId("")
                }}
                onPressYes={() => {
                    deletePost(deleteId)
                }}
            />
        </Box>
    )
}

const RenderList = ({ item, onPress, onPressMain, onPressDelete }) => {
    const groups = useSelector(state => state?.team?.team)
    const [popAnchor, setPopAnchor] = useState(null)
    const [pic, setPic] = useState("public/cover.png")
    let data = item?.data

    useEffect(() => {
        let data = item?.data
        let g = groups?.filter(x => x?._id == data?.gid)
        if (g[0]?.coverPic) {
            setPic(g[0]?.coverPic)
        }
    }, [groups, item])

    if (!data?.gid) {
        return (
            <>No Post Available</>
        )
    }
    
    return (
        <>
            <Box sx={[{ background: "white", textAlign: "left", marginBottom: "10px", px: "16px", py: "8px", marginTop: "12px", borderRadius: "4px", boxShadow: "0px 5px 20px #26262626", }]} onClick={onPressMain}>
                <Box sx={{ display: "flex", alignItems: 'center', justifyContent: 'space-between' }}>
                    <Box sx={{ display: "flex", alignItems: 'center' }}>
                        <Box component={"img"} src={image_url + pic} sx={{ height: "60px", width: "60px", borderRadius: "60px", marginRight: "8px" }} />
                        <Box>
                            <Typography sx={{ fontWeight: "600", fontSize: "18px", }}>{pic[0]?.name}</Typography>
                            <Typography sx={{ fontWeight: "600", fontSize: "13.2px", marginTop: "2px" }}>{data?.category}</Typography>
                        </Box>
                    </Box>
                    <Box 
                        onClick={(e) => {
                            setPopAnchor(e.currentTarget)
                            e.stopPropagation()
                        }} 
                        component={"img"} 
                        src={"assets/post/dots.png"} 
                        sx={{ height: "16px", cursor: "pointer", width: "16px", marginLeft: "5px", objectFit: "contain" }} 
                    />
                </Box>
                <Typography sx={{ fontSize: "14px", fontWeight: "500", marginTop: "4px" }}>Event Title: {data?.title}</Typography>
                <Typography sx={{ fontSize: "10px", fontWeight: "500", marginTop: "6px" }}>Post on: <Typography sx={{ fontWeight: "600", display: "inline" }}>{moment((data?.startDate)).format("MMM, DD, YYYY")}</Typography></Typography>
                <Typography sx={{ fontSize: "10px", fontWeight: "500", marginTop: "2px" }}>Closed on: <Typography sx={{ fontWeight: "600", display: "inline" }}>{moment((data?.endDate)).format("MMM, DD, YYYY")}</Typography></Typography>
                <Typography sx={{ fontSize: "10px", fontWeight: "500", marginTop: "2px" }}>Points to be earned: <Typography sx={{ fontWeight: "600", display: "inline" }}>{data?.points} Points</Typography></Typography>
            </Box >
            <PopOverMenu anchorEl={popAnchor} setAnchorEl={setPopAnchor} onPressDelete={() => {
                onPressDelete(item?._id)
            }} onPressEdit={e => {
                onPress()
            }} />
        </>
    )
}

const PopOverMenu = ({ anchorEl, setAnchorEl, onPressDelete, onPressEdit }) => {
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}>
            <Typography onClick={e => {
                onPressDelete()
            }} sx={{ py: 1, px: 2, fontSize: pxToRem(14), fontWeight: "600", cursor: "pointer" }}>Delete draft</Typography>

            <Typography onClick={e => {
                onPressEdit()
            }} sx={{ py: 1, px: 2, fontSize: pxToRem(14), fontWeight: "600", cursor: "pointer" }}>Edit draft</Typography>
        </Popover>
    )
}