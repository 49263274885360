import { createSlice } from "@reduxjs/toolkit";

const interactionSlice = createSlice({
    name: "interactionS",
    initialState: { list: [] },
    reducers: {
        setInteractionList: (state, action) => {
            state.list = action.payload
        }
    }
})

export const { setInteractionList } = interactionSlice.actions

export default interactionSlice.reducer