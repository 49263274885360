import { Avatar, Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { categories, drafts, image_url, manuals, subgroups, teams } from "../services/endpoints";
import { setLoading } from "../store/load";
import { pxToRem } from "../theme/typography";
import moment from "moment";
import hit from "../services/apiManager";
import DatePickerModal from "../components/DatePickerModal";
import SearchBar from "../components/Home/SearchBar";
import Input, { DatePickerInput, DropDownInput } from "../components/Login/Input";
import TimePickerModal from "../components/TimePickerModal";
import showToast from "../utils/showToast";
import _ from 'lodash'

export default function ManualSubmission() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { gid, data } = useLocation()?.state ?? {}
    const [categoriesOpt, setCategoriesOpt] = useState([])
    const [dropdownCat, setDropdownCat] = useState("")
    const [category, setCategory] = useState("")
    const [inputCat, setInputCat] = useState("")
    const [points, setPoints] = useState("")
    const [title, setTitle] = useState("")
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState(moment().add(2, "day").add("2", "hour").toDate())
    const [isDatePickerVisible, setIsDatePickerVisible] = useState(false)
    const [isTimePicker, setIsTimePickerVisible] = useState(false)
    const [endOf, setEndOf] = useState("")
    const [showNext, setShowNext] = useState(false)
    const [draftId, setDraftId] = useState(null)
    const [allGropus, setAllGropus] = useState([])
    const [allAdmins, setAllAdmins] = useState([])
    const [allsubgroups, setAllsubgroups] = useState([])
    const [adminsSelected, setAdminsSelected] = useState([])
    const [selected, setSelected] = useState([])
    const [search, setSearch] = useState("")
    const user = useSelector(state => state?.auth?.user)

    const getGroupDetails = async (id) => {
        dispatch(setLoading(true))
        let res = await hit(`${teams?.createTeam}/${id}`, "get")
        if (res?.err) {
            showToast(res?.msg, "error")
        } else {
            setAllAdmins(res?.data?.admins)
            setAllGropus(res?.data?.members)
        }
        dispatch(setLoading(false))
    }

    const getAllSubGroup = async (teamId) => {
        const res = await hit(`${subgroups?.create}/?parentId=${teamId}`, "get")
        if (res?.err) {
            showToast(res?.msg, "error")
        } else {
            let newArr = res?.data?.filter(x => x?.createdBy == user?._id)
            setAllsubgroups([...newArr])
        }
    }

    const getCategories = async () => {
        let res = await hit(categories, "get")
        if (res?.err) {
            showToast(res?.msg, "error")
        } else {
            setCategoriesOpt(res?.data)
        }
    }

    const submit = async () => {
        let draftStartDate = new Date()
        if (title.trim() == "") {
            showToast("Title is required.", "warning")
            return
        }
        if (category.trim() == "") {
            showToast("Category is required.", "warning")
            return
        }
        if (points.trim() == "") {
            showToast("Points is required.", "warning")
            return
        }
        const currentTime = moment().valueOf();
        let endDateUnix = moment(endDate).unix() * 1000;
        if (moment(endDateUnix).valueOf() < currentTime) {
            showToast("End date & time should be greater than current time", "warning");
            return
        }
        let body = {
            title,
            points,
            category,
            startDate: moment(draftStartDate).unix() * 1000,
            endDate: moment(endDate).unix() * 1000,
            gid
        }
        dispatch(setLoading(true))
        let res = await hit(drafts, "post", { "data": body, "type": 1 })
        if (res?.err) {
            showToast(res?.msg, "error")
        } else {
            setStartDate(draftStartDate)
            setShowNext(true)
            setDraftId(res?.data?._id) // set draft id to draftId
        }
        dispatch(setLoading(false))
    }

    const handleConfirm = (date) => {
        if (endOf == "start") {
            setStartDate(moment(date).toDate())
        } else {
            let start = moment(startDate).toDate()
            let end = moment(date).toDate()
            if (start > end) {
                hideDatePicker();
                showToast("End date must be ahead of the start date.", "warning")
                return
            }
            setEndDate(moment(date).toDate())
        }
        hideDatePicker();
    };

    const hideDatePicker = () => {
        setIsDatePickerVisible(false);
        setIsTimePickerVisible(false);
        setEndOf("")
    };

    const submitManual = async () => {
        if (!draftId) {
            showToast("Invalid Draft Information", "warning")
            return
        }
        if (selected?.length == 0) {
            showToast("No members.", "warning")
            return
        }
        const currentTime = moment().valueOf();
        let endDateUnix = moment(endDate).unix() * 1000;
        if (moment(endDateUnix).valueOf() < currentTime) {
            showToast("End date & time should be greater than current time", "warning");
            return
        }
        dispatch(setLoading(true))
        let manualInputData = {
            memebers: selected,
            title,
            point: Number(points),
            groups: [gid],
            category,
            startDate: moment(startDate).unix() * 1000,
            endDate: moment(endDate).unix() * 1000,
            draft_id: draftId
        }
        let res = await hit(manuals, "post", manualInputData)
        if (res?.err) {
            showToast(res?.msg, "error")
        } else {
            showToast("Submitted Successfully")
            navigate(-1)
        }
        dispatch(setLoading(false))
    }

    const addMemb = async (temp) => {
        if (!draftId) {
            showToast("Invalid Draft Information", "warning")
            return
        }
        let body = {
            title,
            points,
            category,
            startDate: moment(startDate).unix() * 1000,
            endDate: moment(endDate).unix() * 1000,
            gid,
            members: temp
        }
        dispatch(setLoading(true))
        let res = await hit(`${drafts}/${draftId}`, "patch", { "data": body })
        if (res?.err) {
            showToast(res?.msg)
        } else {
            setSelected(temp)
        }
        dispatch(setLoading(false))
    }

    const updateManual = async () => {
        if (!draftId) {
            showToast("Invalid Draft Information", "warning")
            return
        }
        if (title.trim() == "") {
            showToast("Title is required.", "warning")
            return
        }
        if (category.trim() == "") {
            showToast("Category is required.", "warning")
            return
        }
        if (points.trim() == "") {
            showToast("Points is required.", "warning")
            return
        }
        let body = {
            title,
            points,
            category,
            startDate: (moment(startDate).unix()) * 1000,
            endDate: (moment(endDate).unix()) * 1000,
            gid,
            members: selected
        }
        dispatch(setLoading(true))
        let res = await hit(`${drafts}/${draftId}`, "patch", { data: body, type: 1 })
        if (res?.err) {
            showToast(res?.msg, "error")
        }
        dispatch(setLoading(false))
    }

    const addAdmins = async (arr) => {
        if (!draftId) {
            showToast("Invalid Draft Information", "warning")
            return
        }
        dispatch(setLoading(true))
        let res = await hit(`${drafts}/${draftId}`, "patch", { "admins": arr })
        if (res?.err) {
            showToast(res?.msg, "error")
        } else {
            setAdminsSelected(arr)
        }
        dispatch(setLoading(false))
    }

    useEffect(() => {
        getCategories()
    }, [])

    useEffect(() => {
        if (gid) {
            getGroupDetails(gid)
            getAllSubGroup(gid)
        }
    }, [gid])

    useEffect(() => {
        if (data) {
            setDraftId(data?._id)
            let newData = data?.data
            setCategory(newData?.category)
            setPoints(newData?.points)
            setTitle(newData?.title)
            setStartDate(moment(newData?.startDate).toDate())
            setEndDate(moment(newData?.endDate).toDate())
            if (data?.admins) {
                setAdminsSelected(data?.admins)
            }
            if (newData?.members) {
                setSelected(newData?.members)
            }
            setShowNext(true)
        }
    }, [data])

    useEffect(() => {
        if (inputCat.trim() == "") {
            return
        }
        setCategory(inputCat)
        setDropdownCat("")
    }, [inputCat])

    useEffect(() => {
        if (dropdownCat == "") {
            return
        }
        setCategory(dropdownCat)
        setInputCat("")
    }, [dropdownCat])

    return (
        <Box sx={{ px: "5%", pt: "20px", display: "flex", pr: "10%", overflowY: "auto", flex: 1, width: "100%", background: "linear-gradient(119deg,#E6F6F9,white,rgb(253,243,213))" }}>
            <Box sx={{ flex: 1, minHeight: "100px" }}>
                <Typography sx={{ textAlign: "left", fontWeight: "600" }}>
                    Manual submission
                </Typography>
                <Box sx={{ display: "flex", px: "10px", minHeight: "100px", py: "20px", marginTop: "10px", flexDirection: "column", background: "white", boxShadow: "0px 5px 20px #26262626", borderRadius: "5px", }}>
                    <Input
                        value={title}
                        onChange={v => setTitle(v)}
                        titleStyle={{ textAlign: "left" }}
                        title="Event title"
                    />
                    <br />
                    <DropDownInput
                        value={category}
                        onChange={v => { setCategory(v) }}
                        options={categoriesOpt?.map(x => x.name)}
                        img={"assets/posts/arrow_ios.svg"}
                        titleStyle={{ textAlign: "left" }}
                        title={"Category"}
                    />
                    <br />
                    <Typography sx={{ fontWeight: "600" }}>OR</Typography>
                    <Input
                        value={category}
                        onChange={v => { setCategory(v) }}
                        inputContainerStyle={{ background: "#F6F6F6" }}
                        titleStyle={{ textAlign: "left" }}
                        placeholder="Type your own Category..."
                        showPoints={false} title={"Category"}
                    />
                    <br />
                    <Input
                        value={points}
                        inputProps={{ type: "number" }}
                        onChange={v => setPoints(v)}
                        rightText={
                            <Typography
                                color={"primary"}
                                sx={{ fontSize: pxToRem(12), fontWeight: "600" }}>
                                Points
                            </Typography>
                        }
                        isPass={true}
                        titleStyle={{ textAlign: "left" }}
                        title="How many points is it worth"
                    />
                    <br />
                    <Box sx={{ display: "flex", textAlign: "left", marginTop: "10px" }}>
                        <DatePickerInput
                            onClick={() => {
                                setIsDatePickerVisible(true)
                                setEndOf("end")
                            }}
                            inputContainerStyle={{ background: "#EBEBEB" }}
                            title={"End Date"}
                            value={moment(endDate).format("MMM, DD, YYYY")}
                            containerStyle={{ flex: 1 }}
                        />
                        <DatePickerInput
                            onClick={() => {
                                setIsTimePickerVisible(true)
                                setEndOf("end")
                            }}
                            inputContainerStyle={{ background: "#EBEBEB" }}
                            title={"End Time"}
                            value={moment(endDate).format("hh:mm A")}
                            containerStyle={{ flex: 1, marginLeft: "4%" }}
                        />
                    </Box>
                    <br />
                    <Button
                        onClick={() => {
                            if (draftId) {
                                updateManual()
                            } else {
                                submit()
                            }
                        }}
                        sx={{ fontSize: pxToRem(16), borderRadius: "5px", marginX: "auto", height: "45px", width: "200px" }} variant="contained">
                        Next
                    </Button>
                    <br />
                </Box>
            </Box>
            <Box sx={{ flex: 1, marginLeft: "5%", }}>
                {showNext &&
                    <>
                        <Typography sx={{ textAlign: "left", fontWeight: "600" }}>Posting To</Typography>
                        <br />
                        <SearchBar
                            value={search}
                            onChange={v => setSearch(v)}
                            onClickSearch={() => { }}
                            placeholder={"Search..."}
                        />
                        <Box sx={{ display: "flex", minHeight: "400px", px: "10px", py: "20px", marginTop: "10px", flexDirection: "column", background: "white", boxShadow: "0px 5px 20px #26262626", borderRadius: "5px", }}>
                            <Typography sx={{ textAlign: "left", fontWeight: "600" }}>Admins</Typography>
                            <br />
                            {_.sortBy(allAdmins, [function (o) { return String(o?.lastName).toLowerCase() }])?.filter(x => String(x?.firstName + x?.lastName + x?.email).toLowerCase().includes(search))?.map((item, index) => <CheckComponent style={{ marginTop: index == 0 ? "0%" : "10px" }} setIsChecked={v => {
                                if (v) {
                                    addAdmins([...adminsSelected, item?._id])
                                } else {
                                    addAdmins(adminsSelected?.filter(x => x != item?._id))
                                }
                            }} isChecked={adminsSelected?.includes(item?._id)} item={item} title={item?.firstName} />)}
                            <br />
                            {(allsubgroups?.length > 0) && <Typography sx={{ textAlign: "left", fontWeight: "600" }}>SubGroups</Typography>}
                            {(allsubgroups?.length > 0) && <>
                                {allsubgroups?.map((item, index) => {
                                    return (
                                        <SubGroupComp search={search} setIsChecked={(v, id) => {
                                            if (v) {
                                                addMemb([...selected, id])
                                            } else {
                                                addMemb(selected?.filter(x => x != id))
                                            }
                                        }} members={allGropus} item={item} selected={selected} style={{ marginTop: "10px" }} />
                                    )
                                })}
                            </>}
                            {(allsubgroups?.length > 0) && <br />}
                            <Typography sx={{ textAlign: "left", fontWeight: "600" }}>Other Participants</Typography>
                            {_.sortBy(allGropus, [function (o) { return String(o.lastName).toLowerCase() }])?.filter(x => String(x?.firstName + x?.lastName + x?.email).toLowerCase().includes(search))?.map((item, index) => {
                                let showMem = true
                                allsubgroups?.forEach(sub => {
                                    sub?.members?.forEach(mem => {
                                        if (mem == item?._id) {
                                            showMem = false
                                        }
                                    })
                                })
                                if (!showMem) { return null; }
                                return (<CheckComponent style={{ marginTop: "10px" }} setIsChecked={v => {
                                    if (v) {
                                        addMemb([...selected, item?._id])
                                    } else {
                                        addMemb(selected?.filter(x => x != item?._id))
                                    }
                                }} isChecked={selected?.includes(item?._id)} item={item} title={item?.firstName} />)
                            })}
                            <br />
                            <Button
                                onClick={() => submitManual()}
                                sx={{ fontSize: pxToRem(16), borderRadius: "5px", marginX: "auto", height: "45px", width: "200px" }}
                                variant="contained">
                                Save In Manual
                            </Button>
                            <br />
                        </Box>
                    </>}
            </Box>
            <DatePickerModal open={isDatePickerVisible} setOpen={setIsDatePickerVisible} value={endOf == "start" ? moment(startDate) : moment(endDate)} onChange={v => {
                handleConfirm(moment(v).toDate())
            }} />
            <TimePickerModal open={isTimePicker} setOpen={setIsTimePickerVisible} value={endOf == "start" ? moment(startDate) : moment(endDate)} onChange={v => {
                handleConfirm(moment(v).toDate())
            }} />
        </Box>
    )
}

const CheckComponent = ({ title, item, isChecked, setIsChecked, style }) => {
    return (
        <Box sx={[{ minHeight: "60px", width: "100%", p: "10px", display: "flex", alignItems: "center", background: "white", boxShadow: "0px 5px 20px #26262626" }, style]}>

            <Avatar
                src={image_url + item?.profilePic}
                style={{ height: "40px", width: "40px" }}
            />
            <Box sx={{ marginLeft: "15px", flex: 1, display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                <Typography sx={{ fontWeight: "600", fontSize: pxToRem(14) }}>{title} {item?.lastName}</Typography>
                <Typography sx={{ fontWeight: "400", fontSize: pxToRem(12) }}>{item?.email}</Typography>

            </Box>
            <Box onClick={() => {
                setIsChecked(!isChecked)
            }} component={"img"} sx={{ cursor: "pointer" }} src={isChecked ? "assets/groups/ios-checkbox.svg" : "assets/groups/ios-checkbox-outline.svg"} />
        </Box>
    )
}

const SubGroupComp = ({ item, search, style, selected, members, setIsChecked }) => {
    const [show, setShow] = useState(false)
    const [membersAll, setAllMembers] = useState([])

    useEffect(() => {
        let l = []
        for (let m of members) {
            if (item?.members?.includes(m?._id)) {
                l.push(m)
            }
        }
        setAllMembers(l)
    }, [members, item])

    return (
        <>
            <Box onClick={() => {
                setShow(!show)
            }} sx={[{ height: "80px", px: "20px", background: "white", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "flex-start", borderRadius: "10px", boxShadow: "0px 5px 20px #26262626" }, style]}>
                <Typography sx={{ fontWeight: "600" }}>{item?.name}</Typography>
                <Typography sx={{ fontSize: "14px" }}>Participants</Typography>
                <Typography color="primary">{item?.members?.length}</Typography>

            </Box>
            {show &&
                _.sortBy(membersAll, [function (o) { return String(o?.lastName).toLowerCase() }])?.filter(x => String(x?.firstName + x?.lastName + x?.email).toLowerCase().includes(search))?.map(memberDetails => {
                    if (!memberDetails) return null;
                    return <CheckComponent setIsChecked={v => {
                        setIsChecked(v, memberDetails?._id)
                    }} isChecked={selected?.includes(memberDetails?._id)} style={{ marginTop: "10px" }} item={memberDetails} title={memberDetails?.firstName} />
                })
            }
        </>
    )
}