import { Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import GroupCard from "../components/Home/GroupCard";
import GroupSwipper from "../components/Home/GroupSwipper";
import SearchBar from "../components/Home/SearchBar";
import PostComponent from "../components/PostComponent";
import FriendsComp from "../components/Profile/FriendsComp";
import ProfileComp from "../components/Profile/ProfileComp";
import TwoSelectionTab from "../components/TwoSelectionTab";
import { pxToRem } from "../theme/typography";
import { setFriendFeedStateData } from "../store/friendFeeds";
import { image_url, post, teams, userDetail } from "../services/endpoints";
import showToast from "../utils/showToast";
import { setLoading } from "../store/load";
import hit from "../services/apiManager";
import { setInteractionList } from "../store/interactions";
import DeleteModal from "../components/DeleteModal";
import ViewedByModal from "../components/ViewedByModal";
import PostDeniedModal from "../components/PostDeniedModal";
import { getAllNotifications } from "../store/notification";
// import { getAllScoreData } from "../store/scores";
import { getAllTeamThunk } from "../store/team";
import MakeAdminModal from "../components/MakeAdminModal";

export default function Profile() {
    const [currentTab, setCurrentTab] = useState(0)
    const navigate = useNavigate()
    const userMine = useSelector(state => state?.auth?.user)
    const { userData, groupDetail } = useLocation().state ?? {}
    const [user, setUser] = useState(userData)
    const feeds = useSelector(state => state.friendFeeds?.feeds)
    const interactionList = useSelector(state => state?.interactions?.list)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showRejectModal, setShowRejectModal] = useState(false)
    const [rejectDetail, setRejectDetail] = useState(null)
    const [deleteId, setDeleteId] = useState("")
    const [showViewdBy, setShowViewdBy] = useState(false)
    const [viewedByDetail, setViewedByDetail] = useState([])
    const [selectedGroup, setSelectedGroup] = useState(null)
    const [teamslist, setTeams] = useState([])
    const [isAdmin,setIsAdmin]=useState(false)
    const [showPopOver, setShowPopOver] = useState(false)

    const setFeeds = (value) => {
        dispatch(setFriendFeedStateData({ key: "feeds", value: value }))
    }
    const count = useSelector(state => state.friendFeeds?.count)
    const setCount = (value) => {
        dispatch(setFriendFeedStateData({ key: "count", value: value }))
    }
    const totalPage = useSelector(state => state.friendFeeds?.totalPage)
    const setTotalPage = (value) => {
        dispatch(setFriendFeedStateData({ key: "totalPage", value: value }))
    }
    const location = useLocation()
    const dispatch = useDispatch()

    useEffect(() => {
        getAllUserFeeds(1)
    }, [user])

    useEffect(() => {
        if (userData) {
            getUserDetails(userData?._id)
            getUserTeams(userData?._id)
        }
    }, [userData])

    const getUserDetails = async (id) => {
        try {
            dispatch(setLoading(true))
            let res = await hit(`${userDetail}/${id}`, "get")
            if (res?.err) {
                showToast(res?.msg, "error")
            } else {
                setUser(res?.data)
            }
        } catch (e) {
        } finally {
            dispatch(setLoading(false))
        }
    }

    useEffect(() => {
        dispatch(getAllNotifications())
        // dispatch(getAllScoreData())
        getuserInteractions()
        getGroups()
        dispatch(getAllTeamThunk())
    }, [location])
    const getAllUserFeeds = async (pg) => {
        try {
            dispatch(setLoading(true))
            let res = await hit(`${post?.post}?page=${pg}&postType=post&populate=likes,user,viewsBy,pollSelected.user,groups.members&user=${user?._id}`, "get")
            if (res?.err) {
                showToast(res?.msg)
            } else {
                setFeeds(res?.data?.results)
                setTotalPage(res?.data?.totalPages)
            }
        } catch (e) {
        } finally {
            dispatch(setLoading(false))
        }
    }
    const refreshFeeds = async (pg) => {
        try {
            dispatch(setLoading(true))

            let res = await hit(`${post?.post}?page=${pg}&postType=post&populate=likes,user,viewsBy,pollSelected.user,groups.members&user=${user?._id}`, "get")
            if (res?.err) {
                showToast(res?.msg)
            } else {
                setTotalPage(res?.data?.totalPages)
                let temp = []
                let x = feeds
                let result = []
                result = res?.data?.results
                temp = x.concat(result)
                setFeeds(temp)
            }
        } catch (e) {
        } finally {
            setCount(pg)
            dispatch(setLoading(false))
        }
    }
    const viewPost = async (id) => {
        try {
            let res = await hit(`${post?.viewPost}/${id}`, "patch")
        } catch (e) {
        } finally {
        }
    }

    const getuserInteractions = async () => {
        try {
            let res = await hit(`${post?.interact}?user=${user?._id}`, "get")
            if (res?.err) {
                showToast(res?.msg)
            } else {
                dispatch(setInteractionList(res?.data))
            }
        } catch (e) {
        } finally {
            dispatch(setLoading(false))
        }
    }

    const getGroups = async () => {
        try {
            let res = await hit(teams.createTeam, "get")
            if (res?.data) {
                let newA = res?.data.filter(x => x?.active != 0)
                // setAllGroup(newA)

                // dispatch(getGroups)
            } else {
                showToast(res?.msg)
            }
        } catch (err) {
        } finally {
        }
    }
    const likePost = async (id) => {
        try {
            let res = await hit(`${post?.post}/${id}/like`, "post")
            if (res?.err) {
                showToast(res?.msg)
            }
        } catch (e) {
        } finally {
        }
    }

    const deletePost = async (id) => {
        setShowDeleteModal(false)
        try {
            dispatch(setLoading(true))
            let res = await hit(`${post?.post}/${id}`, "delete")
            if (res?.err) {
                showToast(res?.msg)
            } else {
                showToast("Post deleted successfully")
                getAllUserFeeds(count)
            }
        } catch (e) {
        } finally {
            setDeleteId("")
            dispatch(setLoading(false))
        }
    }
    const rejectPost = async (detail, reason) => {
        let temp = [...feeds]
        let index = temp.indexOf(detail)
        temp.splice(index, 1)
        setFeeds(temp)
        try {
            let res = await hit(`${post.postApproval}/${detail?._id}`, "patch", { isApproved: 2, reason: reason.trim() })
        } catch (e) {
        } finally {
        }
    }

    const deleteF = (id) => {
        setDeleteId(id)
        setTimeout(() => {
            setShowDeleteModal(true)
        }, 700);
    }

    const votePoll = async (selected, id) => {
        try {
            dispatch(setLoading(true))
            let res = await hit(post?.poll, "post", { "postId": id, "selected": selected })
            if (res?.err) {
                showToast(res?.msg)
            } else {
                getAllUserFeeds(count)
            }
        } catch (e) {
        } finally {
            dispatch(setLoading(false))
        }
    }

    const approve = async (id, approve, ind) => {
        if (approve == 2) {
            setShowRejectModal(true)
            setRejectDetail(ind)
            return
        }
        try {
            let res = await hit(`${post.postApproval}/${id}`, "patch", { isApproved: approve })
        } catch (e) {
        } finally {
        }
    }

    function componentDidMount(id) {
        if (navigator.geolocation) {
            dispatch(setLoading(true))
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    let myloc = { latitude: position.coords.latitude, longitude: position.coords.longitude }
                    getAddress(position?.coords, id, myloc)
                },
                (error) => {
                    dispatch(setLoading(false))
                    console.log("errorrrr===>>>", error.code, error.message);
                }
            );
        }
    }

    function getAddress(cords, id, myloc) {
        fetch(
            'https://maps.googleapis.com/maps/api/geocode/json?latlng=' +
            (cords?.latitude ?? 0.0) +
            ',' +
            (cords?.longitude ?? 0.0) +
            '&key=' +
            'AIzaSyAPOiIA8anXQrrCQQXamQ_60HOkDHtri_Q',
        )
            .then(async res => {
                let resjson = await res.json();
                if (resjson.results.length > 0) {
                    let addressComponent = resjson.results[0].address_components;
                    let address = addressComponent[1]?.long_name + ' ' + addressComponent[2]?.long_name + ' ' + addressComponent[3]?.long_name + ' ' + addressComponent[5]?.long_name
                    interactPost(id, address, myloc)
                }
            })
            .catch(error => console.log('results error => ', error.message));
    }
    const interactPost = async (id, add, myloc) => {
        if (myloc == null) {
            dispatch(setLoading(false))
            return
        }
        try {
            let res = await hit(post?.interact, "post", {
                "postId": id,
                "last_checkin_location": {
                    "lat": myloc?.latitude,
                    "lng": myloc?.longitude,
                    "address": add
                },
            })
            if (res?.err) {
                showToast(res?.msg)
                dispatch(setLoading(false))
            } else {
                getuserInteractions()
            }
        } catch (e) {
        } finally {
            dispatch(setLoading(false))

        }
    }
    const getUserTeams = async (id) => {
        try {
            let res = await hit(`${teams?.createTeam}?user=${id}`, "get")
            if (res?.err) {
                showToast(res?.msg)
            } else {
                setTeams(res?.data)
            }
        } catch (e) {
        } finally {
        }
    }
    const showButton = () => {
        let f = Boolean(groupDetail)
        if (f) {
            if (groupDetail?.createdBy?._id == user?._id) {
                return false
            }
            if (groupDetail?.admins?.map(x => x?._id)?.includes(userMine?._id)) {
                return true
            }
        }
        return false
    }
  

    useEffect(()=>{
        if(groupDetail?.admins?.map(x => x?._id)?.includes(user?._id)){
            setIsAdmin(true)
        }else{
            setIsAdmin(false)
        }
    },[groupDetail])

    const revokeAdmin = async () => {
        try {
            dispatch(setLoading(true))
            let res = await hit(teams?.removeAdmin, "post", { userId: user?._id, gId: groupDetail?._id })
            if (res?.err) {
                showToast(res?.msg,"error")
            } else {
                showToast("Admin removed")
                let temp = []
                temp = res?.data?.admins
                let admin = temp.includes(user?._id)
                if (admin) {
                    setIsAdmin(true)
                } else {
                    setIsAdmin(false)
                }
            }
        } catch (e) {
        } finally {
            dispatch(setLoading(false))
        }
    }
    const makeAdmin = async (scoring) => {
        try {
            setShowPopOver(false)
            dispatch(setLoading(true))
            let res = await hit(teams?.addAdmin, "post", { userId: user?._id, gId: groupDetail?._id, ableToScore: scoring })
            if (res?.err) {
                showToast(res?.msg,"error")
            } else {
                showToast("Admin added succesfully")
                let temp = []
                temp = res?.data?.admins
                let admin = temp.includes(user?._id)
                if (admin) {
                    setIsAdmin(true)
                } else {
                    setIsAdmin(false)
                }
            }
        } catch (e) {
        } finally {
            dispatch(setLoading(false))
        }
    }
    return (
        <Box sx={{ px: "5%", pt: "20px", display: "flex", pr: "10%", overflowY: "auto", flex: 1, width: "100%", background: "linear-gradient(119deg,#E6F6F9,white,rgb(253,243,213))" }}>
            <Box sx={{ flex: 1, minHeight: "100px" }}>
                <ProfileComp userData={user} />
                <br />
                <GroupSwipper teams={teamslist} />
                <br />
                <Typography sx={{ textAlign: "center" }}>
                    {user?.bio}
                </Typography>
                <br />
                {showButton() && <Button onClick={()=>{
                    if(isAdmin){
                        revokeAdmin()
                    }else{
                        setShowPopOver(true)
                    }
                }} color={isAdmin ? "warning" : "primary"} variant="contained">
                    {isAdmin ? "Remove Admin" : "Make Admin"}
                </Button>}
                <Box sx={{ height: "20px" }} />

            </Box>
            <Box sx={{ flex: 1, marginLeft: "5%", minHeight: "100px" }}>
                <Typography sx={{ fontWeight: "600", textAlign: "left" }}>Feeds</Typography>

                {feeds.map((i, j) => {
                    let isCheckedin = "in"
                    let temp = interactionList?.filter(x => x?.postId == i?._id)
                    if (temp?.length > 0) {
                        if (temp[0]?.current_status == "in") {
                            isCheckedin = "out"
                        } else {
                            isCheckedin = "in"
                        }
                    }
                    return (
                        <PostComponent
                            i={i} j={j}
                            key={i?._id}
                            onPressProfile={() => {
                                viewPost(i?._id)
                                if (user?._id == i?.user?._id) {
                                    // navigation?.navigate("Profile")
                                    return
                                }
                                // navigation?.navigate("FriendsProfile", { user: i?.user?._id, team: "noteam" })
                            }}
                            postLiked={liked => {
                                likePost(liked);
                                viewPost(i?._id)
                            }}
                            onPressDelete={id => {
                                viewPost(i?._id)
                                deleteF(id)
                            }}
                            onPressComment={(groupId) => {
                                viewPost(i?._id)
                                navigate("/user/comments", { state: { id: i?._id, from: 'home' ,groupId} })
                            }}
                            onPressPost={(data) => {
                                viewPost(i?._id)
                                if (data?.type == "photo") {
                                    window.open(image_url + data?.img, "_blank")
                                } else {
                                    let ind = i?.thumbnails?.indexOf(data?.img)
                                    let vid = i?.videos[ind]
                                    window.open(image_url + vid, "_blank")
                                }
                            }}
                            selectPoll={(selected) => {
                                viewPost(i?._id)
                                votePoll(selected, i?._id)
                            }}
                            onPressApprove={() => {
                                viewPost(i?._id)
                                approve(i?._id, 1)
                            }}
                            onPressReject={() => {
                                approve(i?._id, 2, i)
                            }}
                            onPressCheck={() => {
                                viewPost(i?._id)
                                componentDidMount(i?._id)
                            }}
                            onPressInteraction={() => {
                                viewPost(i?._id)
                                if (i?.verifyBy?.includes(user?._id)) {
                                    navigate("/user/interaction", { state: { postid: i?._id } })
                                }
                            }}
                            isCheckedin={isCheckedin}
                            onPressViewed={(members, grpId) => {
                                viewPost(i?._id)
                                setSelectedGroup(grpId)
                                setTimeout(() => {
                                    setShowViewdBy(true)
                                    setViewedByDetail({ viewed: i?.viewsBy, memb: members })
                                }, 400);
                            }}
                        />
                    )
                })}
                {totalPage > count && <Button onClick={() => {
                    refreshFeeds(count + 1)
                }} sx={{ marginTop: "20px", color: "#58C0DC" }} variant="outline">
                    Load More
                </Button>}
            </Box>
            <MakeAdminModal
            profilePic={image_url+user?.profilePic}
            title={"Will this admin be able to score?"}
                open={showPopOver}
                handleClose={() => {
                    setShowPopOver(false)
                    setDeleteId("")
                }}
                onPressNo={() => {
                    setShowPopOver(false)
                    setDeleteId("")
                }}
                onPressYes={(v) => {
                    makeAdmin(v)
                }}
            />
            <DeleteModal
                open={showDeleteModal}
                handleClose={() => {
                    setShowDeleteModal(false)
                    setDeleteId("")
                }}
                onPressNo={() => {
                    setShowDeleteModal(false)
                    setDeleteId("")
                }}
                onPressYes={() => {
                    deletePost(deleteId)
                }}
            />
            <ViewedByModal
                open={showViewdBy}
                arr={viewedByDetail}
                setOpen={setShowViewdBy}
                selectedGroup={selectedGroup}
                onPressCancel={() => {
                    setShowViewdBy(false)
                }}

            />
            <PostDeniedModal
                open={showRejectModal}
                setOpen={setShowRejectModal}
                outPressed={() => setShowRejectModal(false)}
                onPressOk={(t) => {
                    setShowRejectModal(false)
                    rejectPost(rejectDetail, t)
                }}
            />
        </Box>
    )
}