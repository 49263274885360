import { createSlice } from "@reduxjs/toolkit";

const initialState={list:[]}

const loadSlice = createSlice({
    name: "miscDateRanges",
    initialState: initialState,
    reducers: {
        setDateRange: (state, action) => {
            state.list=action.payload
        }
    }
})

export const { setDateRange } = loadSlice.actions

export default loadSlice.reducer