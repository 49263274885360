import { createSlice } from "@reduxjs/toolkit";
import hit from "../services/apiManager";
import { friendsApi } from "../services/endpoints";

const friendsSlice=createSlice({
    name:"friends",
    initialState:{list:[]},
    reducers:{
        setFriendList:(state,action)=>{
            state.list=action.payload
        }
    }
})

export const {setFriendList}=friendsSlice.actions

export const getFriendListThunk = () => {
    return async (dispatch) => {
        try {
            let res = await hit(friendsApi, "get")
            if (res?.data) {
                dispatch(setFriendList(res?.data))
            }
        } catch (err) {
            console.error(err)
        }
    }
}

export default friendsSlice.reducer