import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Box, Typography } from "@mui/material";
import { pxToRem } from "../../theme/typography";

export default function LandingPageSwipper() {

    return (
        <Carousel showStatus={false} style={{ height: "100px" }} autoPlay={true} showThumbs={false} dynamicHeight={false}>
            <div style={{ position: "relative" }}>
                <img alt="1" src="assets/landing/back.png" style={{ height: "80vh", objectFit: "cover" }} />
                <LegendComponent />
            </div>
            <div style={{ position: "relative" }}>
                <img alt="1" src="assets/landing/back.png" style={{ height: "80vh", objectFit: "cover" }} />
                <LegendComponent />

            </div>
            <div style={{ position: "relative" }}>
                <img alt="1" src="assets/landing/back.png" style={{ height: "80vh", objectFit: "cover" }} />
                <LegendComponent />

            </div>
        </Carousel>
    );
}

const LegendComponent = () => {
    return (
        <Box sx={{
            display: "flex", position: "absolute", top: "120px", flexDirection: "column",
            backgroundImage: "linear-gradient(270deg, #26262626 0%, #26262680 20%, #26262626 100%)",
            justifyContent: "center", alignItems: "center", height: "70vh", width: "100vw"
        }}>
            <Typography sx={{ color: "white", fontWeight: "600" }} fontSize={pxToRem(45)} >Rock Culture LLC</Typography>
            <Typography sx={{ color: "white", textAlign: "center", maxWidth: "50vw" }} fontSize={pxToRem(20)} lineHeight={pxToRem(30)} >Building organizational strength and performance through aligned and scored actions.</Typography>
            <Box sx={{ display: "flex", alignItems: "center", marginTop: "20px" }}>
                <img alt="android" src="assets/landing/android.png" style={{ height: "70px", objectFit: "contain" }} />
                <img alt="ios" src="assets/landing/ios.png" style={{ height: "70px", objectFit: "contain", marginLeft: "20px" }} />
            </Box>
        </Box>
    )
}