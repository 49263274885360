import { Box, Typography, useTheme, } from "@mui/material";
import { Link, useLocation } from "react-router-dom";

export default function SideNav() {
    const theme=useTheme()
    const location=useLocation()
    const datas=[
        {title:"Home",link:"/user/home"},
        {title:"My Profile" ,link:"/user/profile"},
        // {title:"My Library" ,link:"/user/library"},
        {title:"ScoreBoard" ,link:"/user/scoreboard"},
        {title:"Drafts" ,link:"/user/draft"},
        {title:"Saved Quizzes" ,link:"/user/quizes"},
        {title:"Calendar" ,link:"/user/calendar"},
        {title:"Team/Groups" ,link:"/user/teams"},
        {title:"Chat" ,link:"/user/chat"},
        {title:"Settings" ,link:"/user/settings"},
    ]
    return (
        <Box sx={{ height: "100%", width: "300px", display: "flex", flexDirection: "column" }}>
            {/* logo content */}
            <Box sx={{ height: "140px", display: "flex", px: "10%", justifyContent: "flex-end", alignItems: "center", background: "#E5FAFF", width: "100%" }}>
                <Box component={"img"} src="assets/logo.png" sx={{ height: "80px", objectFit: "contain", width: "180px" }} />
            </Box>
            <Box sx={{ flex: 1, width: "100%", display: "flex", justifyContent: "flex-end", px: "10%", boxShadow: "5px 0px 20px #0000001A", background: "linear-gradient(153deg,#BBE9F2,#F1F7ED)" }}>
                <Box sx={{ width: "180px", textAlign: "left", marginTop: "30px" }}>
                    {datas?.map((x,i)=>{
                        return(
                            <Link onClick={()=>{
                                if(x.link=="/user/home"){
                                    const element = document.getElementById("topHome");
                                    if (element) {
                                      // 👇 Will scroll smoothly to the top of the next section
                                      element.scrollIntoView({ behavior: 'smooth' });
                                    }
                                }
                            }} to={x?.link!=""?x.link:"/user/home"} style={{ textDecoration: 'none' }}><Typography sx={{ marginTop:i!=0? "30px":"0px", fontWeight: location?.pathname==x.link?"600":"500" ,color:location?.pathname==x.link?theme.palette.primary.main: theme.palette.text.primary }}>{x.title}</Typography></Link>
                        )
                    })}
                     </Box>
            </Box>
        </Box>
    )
}