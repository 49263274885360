import { Avatar, Badge, Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import hit from "../services/apiManager";
import { faq } from "../services/endpoints";
import { setLoading } from "../store/load";
import showToast from "../utils/showToast";

export default function FAQ() {
    const navigate = useNavigate()
    const [list, setList] = React.useState([])
    const dispatch=useDispatch()
    React.useEffect(() => {
        getAllList()
    }, [])

    const getAllList = async () => {
        try {
            dispatch(setLoading(true))
            let res = await hit(faq.getFaqs, "get")
            if (res?.err) {
                showToast(res?.msg,"error")
            } else {
                setList(res?.data)
            }
        } catch (e) {
        } finally {
            dispatch(setLoading(false))
        }
    }
    return (
        <Box sx={{ px: "5%", pt: "20px", display: "flex", pr: "10%", overflowY: "auto", flex: 1, width: "100%", background: "linear-gradient(119deg,#E6F6F9,white,rgb(253,243,213))" }}>
            <Box sx={{ flex: 1, minHeight: "100px" }}>
                <Typography sx={{textAlign:"left",fontWeight:"600"}}>FAQ</Typography>
                <Box sx={{ display: "flex", px: "10px", py: "20px",marginTop:"10px", flexDirection: "column", background: "white", boxShadow: "0px 5px 20px #26262626", borderRadius: "5px", minHeight: "100px" }}>
                    {list?.map(x=><FAQComp item={x} />)}
                </Box>
            </Box>
        </Box>
    )
}


const FAQComp = ({ item}) => {
    const [show, setShow] = useState(false)
    return (
        <>
            <Box

                sx={{
                    objectFit: "cover",
                    // flex: 1,
                    height: "50px",
                    display: "flex",
                    alignItems: "center",
                    background: "#0DB1D41A",
                    justifyContent: "space-between",
                    px: "10px",
                    marginTop: "5px"
                }}
                onClick={() => {
                    setShow(!show)
                }}
            >
                <Typography>{item?.qn}</Typography>
                <img src="assets/landing/plus.png" style={{ height: "18px", width: "18px", objectFit: "contain" }} />
            </Box>
            {show && <Typography sx={{ textAlign: "left", marginY: "10px" }}>{item?.ans}</Typography>}
        </>
    )
}