import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { setLoading } from '../store/load';
import hit from '../services/apiManager';
import { image_url, scores, userDetail as udetail } from '../services/endpoints';
import showToast from "../utils/showToast";
// import { appcolors } from '../theme/';
// import { fonts } from '../../constants/AppFonts';
import moment from 'moment';
import { Button, Typography, Box, Avatar, useTheme } from '@mui/material';

import { useLocation } from 'react-router-dom';
import { pxToRem } from '../theme/typography';

export default function ProfilePoint({ }) {
    const location = useLocation()
    const uid = location?.state?.id;
    const gid = location?.state?.gId;
    const dispatch = useDispatch()
    const [allPoints, setAllPoints] = useState([])
    const [pointsHis, setPointsHis] = useState([])
    const [userDetail, setUserDetail] = useState(null)
    const [totalPoints, setTotalPoints] = useState(0)
    const [selectedQuarter, setSelectedQuarter] = useState("")
    const [categories, setCategories] = useState([])
    const quarter = ["Daily", "Weekly", "Monthly", "Quarterly"]

    useEffect(() => {
        if (uid != undefined) {
            getuidPoints(uid)
            getUserDetail(uid)
        }
    }, [uid])
    useEffect(() => {
        if (allPoints?.length > 0) {
            let temp = []
            allPoints?.forEach(e => {
                if (e?.type == 4) {
                    if (!temp?.includes(e?.manualId?.category)) {
                        temp.push(e?.manualId?.category)
                    }
                } else {
                    if (!temp?.includes(e?.postId?.category)) {
                        temp.push(e?.postId?.category)
                    }
                }
            })
            setCategories(temp)
        }
    }, [allPoints])


    const getUserDetail = async (id) => {
        try {
            let res = await hit(`${udetail}/${id}`, "get")
            if (res?.err) {
                showToast(res?.msg)
            } else {
                setUserDetail(res?.data)
            }
        } catch (e) {
        } finally {
        }
    }
    function splitArray(array, part) {
        var tmp = [];
        for (var i = 0; i < array.length; i += part) {
            tmp.push(array.slice(i, i + part));
        }
        return tmp;
    }
    const getuidPoints = async (id) => {
        try {
            dispatch(setLoading(true))
            let res = await hit(`${scores?.user}/${id}`, "get")
            if (res?.err) {
                showToast(res?.msg)
            } else {
                let c = 0
                let temp = res?.data?.filter(x => x?.groups?.includes(gid) && (x?.postId != null || x?.type == 4))
                setAllPoints(temp)
                setPointsHis(temp)
                temp.forEach(e => {
                    c += e?.point
                });
                setTotalPoints(c)
            }
        } catch (e) {
        } finally {
            dispatch(setLoading(false))
        }
    }
    let subGroupDetail = ""
    return (
        <Box sx={{ px: "5%", pt: "20px", overflowY: "auto", display: "flex", pr: "8%", flex: 1, width: "100%", background: "linear-gradient(119deg,#E6F6F9,white,rgb(253,243,213))" }}>
            <Box sx={{ flex: 1, minHeight: "100px" }}>
                <>
                    {/* <Typography sx={{ fontSize: pxToRem(16), fontWeight: "600", marginBottom: "15px", textAlign: "left" }}>Profile</Typography>  */}
                    <Box sx={{ borderRadius: "5px", px: "10px", py: "20px", background: "white", boxShadow: "0px 5px 20px #26262626", minHeight: "100px" }}>
                        <Box sx={[{ display: "flex", flexDirection: "column", alignItems: "center", padding: "5px", flex: 1, }]}>
                            <Avatar src={image_url + userDetail?.profilePic} sx={{ height: "100px", border: "3px solid white", width: "100px", }} />
                            <Typography sx={{ marginTop: "10px", fontWeight: "600", fontSize: pxToRem(24), textAlign: "center" }}>{userDetail?.firstName}</Typography>
                            <Button sx={{ fontWeight: "400", width: "40%" }} variant="outlined">
                                <Box>
                                    <Typography sx={{ fontWeight: "600", fontSize: pxToRem(18) }}>{totalPoints}</Typography>
                                    <Typography sx={{ color: "black", fontSize: pxToRem(10) }}>Total Points</Typography>
                                </Box>
                            </Button>
                        </Box>
                        <br />
                        <Typography sx={{ fontWeight: "600", textAlign: "left" }}>Per Quarter Points</Typography>
                        {splitArray(quarter, 2).map(items => {
                            return (
                                <Box sx={{ display: "flex", marginTop: "10px" }}>
                                    {items?.map((item, index) => {
                                        let temp = 0
                                        let newa = []
                                        if (item == "Daily") {
                                            newa = pointsHis.filter(x => {
                                                let date1 = moment().format("YYYYMMDD");
                                                if (x?.type == 3) {
                                                    let date2 = moment(x?.updatedAt).format("YYYYMMDD");
                                                    if (date1 == date2) {
                                                        return x
                                                    }
                                                } else {
                                                    let date2 = moment(x?.createdAt).format("YYYYMMDD");
                                                    if (date1 == date2) {
                                                        return x
                                                    }
                                                }
                                            })
                                        } else if (item == "Weekly") {
                                            newa = pointsHis.filter(x => {
                                                moment.updateLocale('en', {
                                                    week: {
                                                        dow: 1
                                                    }
                                                });
                                                let weekStart = moment().startOf('week')
                                                let weekEnd = moment().endOf('week')
                                                if (x?.type == 3) {
                                                    const dateIsAfter = moment(x?.updatedAt).isAfter(moment(weekStart));
                                                    const dateIsBefore = moment(x?.updatedAt).isBefore(moment(weekEnd));
                                                    if (dateIsAfter && dateIsBefore) {
                                                        return x
                                                    }
                                                } else {
                                                    const dateIsAfter = moment(x?.createdAt).isAfter(moment(weekStart));
                                                    const dateIsBefore = moment(x?.createdAt).isBefore(moment(weekEnd));
                                                    if (dateIsAfter && dateIsBefore) {
                                                        return x
                                                    }
                                                }
                                            })
                                        } else if (item == "Monthly") {
                                            newa = pointsHis.filter(x => {
                                                let weekStart = moment().startOf('month')
                                                let weekEnd = moment().endOf('month')
                                                if (x?.type == 3) {
                                                    const dateIsAfter = moment(x?.updatedAt).isAfter(moment(weekStart));
                                                    const dateIsBefore = moment(x?.updatedAt).isBefore(moment(weekEnd));
                                                    if (dateIsAfter && dateIsBefore) {
                                                        return x
                                                    }
                                                } else {
                                                    const dateIsAfter = moment(x?.createdAt).isAfter(moment(weekStart));
                                                    const dateIsBefore = moment(x?.createdAt).isBefore(moment(weekEnd));
                                                    if (dateIsAfter && dateIsBefore) {
                                                        return x
                                                    }
                                                }
                                            })
                                        } else if (item == "Quarterly") {
                                            newa = pointsHis.filter(x => {
                                                let weekStart = moment().startOf('quarter')
                                                let weekEnd = moment().endOf('quarter')
                                                if (x?.type == 3) {
                                                    const dateIsAfter = moment(x?.updatedAt).isAfter(moment(weekStart));
                                                    const dateIsBefore = moment(x?.updatedAt).isBefore(moment(weekEnd));
                                                    if (dateIsAfter && dateIsBefore) {
                                                        return x
                                                    }
                                                } else {
                                                    const dateIsAfter = moment(x?.createdAt).isAfter(moment(weekStart));
                                                    const dateIsBefore = moment(x?.createdAt).isBefore(moment(weekEnd));
                                                    if (dateIsAfter && dateIsBefore) {
                                                        return x
                                                    }
                                                }
                                            })
                                        }
                                        newa.forEach(e => {
                                            temp += e?.point
                                        })
                                        return (
                                            <ScoreButtons
                                                style={{marginLeft:index!=0?"20px":"0px"}}
                                                item={item} 
                                                index={index}
                                                selected={selectedQuarter}
                                                point={temp}
                                                onPress={() => {
                                                    setSelectedQuarter(item)
                                                    setAllPoints(newa)
                                                }}
                                            />
                                        )
                                    })}
                                </Box>
                            )
                        })}
                        <br />
                        <Typography sx={{ textAlign: "left", fontWeight: "600", fontSize: pxToRem(18) }}>My Categories</Typography>
                        <br />
                        {
                            categories?.map((i, j) => {
                                let temp = allPoints.filter(x => (x?.postId?.category == i || x?.type == 4))
                                let points = 0
                                temp.forEach(e => {
                                    points += e?.point
                                })
                                if (points == 0) {
                                    return <></>
                                }
                                return (
                                    <>
                                        <SubComponent title={i ? i : "Results"} points={points} />
                                    </>
                                )
                            })
                        }

                    </Box>
                </>
            </Box>
        </Box>

    )
}

const ScoreButtons = ({ 
    item, index, styleView, selected, onPress, point,style
 }) => {
    let isSelected = selected == item
    const theme=useTheme()
    return (
        <Box  onClick={onPress} sx={[{ boxShadow: "0px 5px 20px #26262626", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start", px: "10px", py: "20px", borderRadius: "5px", minHeight: "100px", flex: 1, background: isSelected ? theme.palette.primary.main : "white" }, style]}>
            <Typography sx={{ fontWeight: "600" ,color:isSelected ?"white" : "black"}}>{item}</Typography>
            <Button variant="outlined"  sx={{ minWidth: "150px", marginTop: "5px" ,borderColor:isSelected?"white": theme.palette.primary.main,color:isSelected?"white":theme.palette.primary.main  }}>{point}</Button>
        </Box>
    )
}



const SubComponent = ({ title, points }) => {
    const [show, setShow] = useState(true)
    return (
        <Box sx={{ width: "100%", padding: "10px", borderRadius: "5px", marginTop: "10px", textAlign: "left", background: "white", boxShadow: "0px 5px 20px #26262626" }}>
            <Typography sx={{ fontSize: pxToRem(18), fontWeight: "600" }}>{title}</Typography>
            <Typography sx={{ fontSize: pxToRem(14), marginY: "7px", fontWeight: "400" }}>Points</Typography>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Typography color={"primary"} sx={{ fontSize: pxToRem(22), fontWeight: "600" }}>{show ? points : "****"}</Typography>
            </Box>
        </Box>
    )
}
