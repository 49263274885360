import { Box } from "@mui/material";
import LandingDownloadPageSection from "../components/Landing/LandingDownloadPageSection";
import LandingHeader from "../components/Landing/LandingHeader";
import LandingPageAboutSection from "../components/Landing/LandingPageAboutSection";
import LandingPageContactUsSection from "../components/Landing/LandingPageContactUsSection";
import LandingPageFAQSection from "../components/Landing/LandingPageFAQSection";
import LandingPageFooterSection from "../components/Landing/LandingPageFooterSection";
import LandingPageSwipper from "../components/Landing/LandingPageSwipper";
import LandingPageWhySection from "../components/Landing/LandingPageWhySection";
export default function LandingPage() {
    return (
        <Box sx={{ width: "100vw" }}>
            <Box position={"relative"}>
                <LandingPageSwipper />
                <LandingHeader />
            </Box>
            <div id="about">
                <LandingPageAboutSection />
            </div>
            <div id="why">
                <LandingPageWhySection />
            </div>
            <div id="download">
            <LandingDownloadPageSection />
            </div>
            <div id="faq">
            <LandingPageFAQSection />
            </div>
            <div id="contact">
            <LandingPageContactUsSection />
            </div>
            <br />
            <LandingPageFooterSection />
        </Box >
    )
}

