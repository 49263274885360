import { createSlice } from "@reduxjs/toolkit";

const chatsSlice = createSlice({
    name: "chats",
    initialState: { list: [] },
    reducers: {
        setGroupsChats: (state, action) => {
            state.list = action.payload
        },
    }
})

export const { setGroupsChats} = chatsSlice.actions



export default chatsSlice.reducer

