import { Box, Typography, Button } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import hit from "../services/apiManager";
import { setLoading } from "../store/load";
import { pxToRem } from "../theme/typography";
import showToast from "../utils/showToast";
import { image_url } from "../services/endpoints";
import { setQuizSavedStateData } from "../store/savedQuizes";
import { setPostData } from "../store/post";
import DeleteModal from "../components/DeleteModal";
import moment from "moment/moment";

export default function Quizes() {
    const location = useLocation()
    const dispatch = useDispatch()
    const userMe = useSelector(state => state.auth?.user)
    const navigate = useNavigate()
    const quizes = useSelector(state => state.savedQuizes?.list)
    const setQuizes = (v) => {
        dispatch(setQuizSavedStateData({ key: "list", value: v }))
    }
    const totalPage = useSelector(state => state.savedQuizes?.totalPage)
    const setTotalPage = (v) => {
        dispatch(setQuizSavedStateData({ key: "totalPage", value: v }))
    }
    const count = useSelector(state => state.savedQuizes?.count)
    const setCount = (value) => {
        dispatch(setQuizSavedStateData({ key: "count", value: value }))
    }




    useEffect(() => {
        getAllData(1)
        dispatch(setPostData({ key: "scoreStartTime", value:moment()  }))
        dispatch(setPostData({ key: "scoreEndTime", value: moment().add(2,"days").add(2,"hours") }))
    }, [location])

    const deleteQuiz = async (id) => {
        try {
            dispatch(setLoading(true))

            let res = await hit("/quizSaved/" + id, "delete", {})
            if (!res.err) {
                setQuizes(quizes.filter(x => x._id != id))
                showToast("data deleted successfully!")
            } else {
                showToast(res.msg, "error")
            }
        } catch (err) {

        } finally {
            dispatch(setLoading(false))
        }
    }

    const getAllData = async (pg) => {
        try {
            dispatch(setLoading(true))
            let res = await hit(`/quizSaved?page=${pg}`, "get")
            if (res?.err) {
                showToast(res?.msg, "error")
            } else {
                if (pg == 1) {
                    setQuizes(res?.data?.results)
                    setCount(1)
                } else {
                    setCount(pg)
                    let temp = []
                    let x = quizes
                    let result = []
                    result = res?.data?.results
                    temp = x.concat(result)
                    setQuizes(temp)
                }
                setTotalPage(res?.data?.totalPages)
            }
        } catch (err) {

        } finally {
            dispatch(setLoading(false))

        }
    }
    return (
        <Box sx={{ px: "5%", pt: "20px", display: "flex", pr: "10%", overflowY: "auto", flex: 1, width: "100%", background: "linear-gradient(119deg,#E6F6F9,white,rgb(253,243,213))" }}>
            <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
                <Typography sx={{ fontWeight: "600", textAlign: "left", fontSize: pxToRem(18) }}>Saved Quizzes</Typography>
                {
                    quizes?.map((item, index) => {
                        return (
                            <QuizDataComponent deleteQuiz={() => {
                                deleteQuiz(item._id)
                            }} item={item} index={index} />
                        )
                    })
                }
                {totalPage > count && <Button onClick={() => {
                    getAllData(count + 1)
                }} sx={{ marginTop: "20px", color: "#58C0DC" }} variant="outline">
                    Load More
                </Button>}

            </Box>
            <Box sx={{ flex: 1 }}>
            </Box>
            <Box sx={{ height: "40px" }} />
        </Box>
    )
}

const QuizDataComponent = ({ item, index, deleteQuiz }) => {
    const [show, setShow] = useState(false)
    const [open, setOpen] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()


    const handleClose = () => {
        setOpen(false)
    }
    return (
        <Box sx={{ background: "white", boxShadow: "10px 10px 30px 0px rgba(0,0,0,0.4)", borderRadius: "5px", marginTop: "20px", paddingY: "20px", textAlign: "left", padding: "10px" }}>
            <Typography variant="h5">{item?.quizTitle}</Typography>
            <Typography variant="subtitle1">{item?.quizDesc}</Typography>
            {show && item?.quizData?.map((data, index) => {
                return (
                    <>
                        <Typography variant="body2" marginTop={"10px"}>Q{index + 1}. {data?.qn}</Typography>
                        <Box display={"flex"} >
                            {data?.photos?.map((x, id) => {
                                return <Box marginTop={"10px"} sx={{ position: "relative", marginRight: "10px" }}>
                                    <Box component={"img"} sx={{ height: "150px", width: "150px", borderRadius: "10px", border: "1px solid", borderColor: "rgb(16,176,212)", objectFit: "contain" }} src={typeof x === 'string' ? image_url + x : URL.createObjectURL(x)} />
                                </Box>
                            })}
                        </Box>
                        <Typography variant="body2">Options.</Typography>
                        <Box display={"flex"} marginLeft={"20px"} flexDirection={"column"} >
                            {data?.options?.map((x, index) => {
                                return (
                                    <Typography variant="body2">{index + 1}. {x}</Typography>
                                )
                            })}
                        </Box>
                    </>
                )
            })}

            <Box sx={{ marginTop: "10px" }}>
                <Button onClick={() => {
                    setShow(!show)
                }} variant="contained" >{show ? "Hide" : "Preview"}</Button>
                <Button
                    onClick={() => setOpen(true)}
                    color="error" sx={{ marginX: "10px" }} variant="contained">Delete</Button>
                <Button
                    onClick={() => {
                        dispatch(setPostData({ key: "isQuizPost", value: true }))
                        dispatch(setPostData({ key: "quizTitle", value: item?.quizTitle }))
                        dispatch(setPostData({ key: "quizDesc", value: item?.quizDesc }))
                        dispatch(setPostData({ key: "quizData", value: item?.quizData }))
                        dispatch(setPostData({ key: "quizScoreCount", value: item?.quizScoreCount }))
                        navigate('/user/post1')
                    }}
                    variant="contained">Export</Button>
            </Box>

            <DeleteModal open={open} handleClose={handleClose} onPressNo={handleClose} onPressYes={deleteQuiz} title="You want to delete this Quiz?" />
        </Box>
    )
}