import { Avatar, Badge, Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Input from "../components/Login/Input";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { useEffect, useState } from "react";
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { useDispatch } from "react-redux";
import { setPostData } from "../store/post";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';

 function MapContainer(props) {
    const navigate = useNavigate()
    const [value, setValue] = useState(undefined)
    const dispatch = useDispatch()
    const [latLong, setLatLong] = useState({
        lat: 0,
        lng: 0
    })
    useEffect(() => {
        if (value) {
            geocodeByAddress(value?.label)
                .then(results => getLatLng(results[0]))
                .then(({ lat, lng }) =>
                    setLatLong({
                        lat,
                        lng
                    })
                );
        }
    }, [value])
    return (
        <Box sx={{ px: "5%", pt: "20px", flexDirection: "column", display: "flex", pr: "10%", overflowY: "auto", flex: 1, width: "100%",height:"70vh", background: "linear-gradient(119deg,#E6F6F9,white,rgb(253,243,213))" }}>
            <Box sx={{ flex: 1, position: "relative", borderRadius: "5px", overflow: "hidden" }}>
                {/* <Box component={"img"} src="assets/maps/mapback.png" sx={{ height: "100%", position: "absolute", top: 0, width: "100%" }} /> */}
                <Map google={props.google} center={{
                    lat:latLong.lat,
                    lng:latLong.lng
                }}   zoom={14} containerStyle={{height:"60vh"}}>
             
                    <Marker 
                        position={{
                            lat:latLong.lat,
                            lng:latLong.lng 
                        }}
                        name={'Current location'} />

                </Map>
                <Box sx={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                    <Box sx={{ width: "90%", margin: "20px", borderRadius: "5px", position: "relative", display: "flex", justifyContent: "flex-start" }}>
                        {/* <Input placeholder={"Location"} inputContainerStyle={{ borderRadius: "50px", width: "300px" }} /> */}
                        <GooglePlacesAutocomplete
                            selectProps={{
                                value: value,
                                onChange: setValue,
                                styles: {
                                    input: (provided) => ({
                                        ...provided,
                                        width: '200px',
                                    })
                                }
                            }}
                            apiKey="AIzaSyAPOiIA8anXQrrCQQXamQ_60HOkDHtri_Q"
                        />
                    </Box>
                    <Box sx={{ width: "90%", margin: "20px", borderRadius: "5px", position: "relative", display: "flex", justifyContent: "flex-end" }}>
                        <Button onClick={() => {
                            dispatch(setPostData({
                                key: "eventLocation", value: {
                                    address: value?.label,
                                    lat: latLong.lat,
                                    lng: latLong.lng,
                                }
                            }))
                            navigate(-1)
                        }} variant="contained" sx={{ borderRadius: "0px", width: "150px" }}>
                            Post
                        </Button>
                    </Box>
                </Box>
            </Box>
            <Box sx={{ height: "20px" }} >

            </Box>
        </Box>
    )
}

export default GoogleApiWrapper({
    apiKey: "AIzaSyAPOiIA8anXQrrCQQXamQ_60HOkDHtri_Q"
  })(MapContainer)


