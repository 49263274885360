import { createSlice } from "@reduxjs/toolkit";
import hit from "../services/apiManager";
import { clearAll, storeData } from "../services/asyncStore";
import { userDetail } from "../services/endpoints";
import { setSelectGroupStore, setSingleDataStore } from "./chat";

const initialState = {
    user: null,
    authorize: false,
    token: "",
}

const authSlice = createSlice({
    name: "auth",
    initialState: initialState,
    // as AuthState,
    reducers: {
        authorize: (state, action) => {
            state.authorize = true
            state.token = action.payload.token
            state.user = action.payload.user
        },
        setUser: (state, action) => {
            state.user = action.payload.user
        },
        signout: (state, action) => {
            return initialState
        },
    }
})

export const { authorize, setUser, signout } = authSlice.actions



export default authSlice.reducer

export const getUserDetailThunk = (id) => {
    return async (dispatch) => {
        try {
            let res = await hit(userDetail + "/" + id, "get",)
            if (res.data) {
                dispatch(setUser({ user: res.data }))
            }
        } catch (err) {
            console.error(err)
        }
    }
}
export const logoutThunk = () => {
    return async (dispatch) => {
        try {
            clearAll()
            dispatch(signout({}))
            dispatch(setSingleDataStore(null))
            dispatch(setSelectGroupStore(null))
        } catch (err) {
            console.error(err)
        }finally{
            dispatch(signout({}))
        }
    }
}

// export type AuthState = {
//     user: object | null | undefined
//     authorize: boolean | null | undefined,
//     token: string,
//     social_data:any,
//     subscriptions:Array<any>
// }