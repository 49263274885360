import { Avatar, Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { image_url } from "../../services/endpoints";

export default function ScoreCard({ style, title, item, isTeam, onSelect }) {
    // const scores = useSelector(state => state.scores)?.list
    const user = useSelector(state => state?.auth?.user)?._id
    // const [totalPoint, setTotalPoints] = useState(0)
    let isAdmin = item?.admins.includes(user)
    // let totalMembers = item?.members?.length
    // let totalAdmins = item?.admins?.length
    // let ableToScores = item?.ableToScores?.length

    // setTotalPoints(!isAdmin?(item?.myTotalPoints):(item?.avg_score))

    // useEffect(() => {
    //     if (scores?.length > 0) {
    //         let filteredList = scores?.filter(x => x.groups?.includes(item?._id))
    //         if (!isAdmin) {
    //             let t = 0
    //             let tot = (filteredList?.filter(x => x?.user?._id == user))
    //             for (let i of tot) {
    //                 t += i?.point
    //             }
    //             setTotalPoints(t)
    //         } else {
    //             let c = 0
    //             filteredList = filteredList?.filter(x => {
    //                 return true
    //             })
    //             for (let i of filteredList) {
    //                 c += i?.point
    //             }
    //             let memb = totalMembers + ableToScores - totalAdmins
    //             setTotalPoints(Math.round(c / (memb <= 0 ? 1 : memb)))
    //         }
    //     } else {
    //         setTotalPoints(0)
    //     }
    // }, [scores])
    return (
        <Box sx={[{ boxShadow: "0px 5px 20px #26262626", position: "relative", background: "white", borderRadius: "5px", padding: "5px", flex: 1, }, style]}>
            <Box sx={{ height: "80px", borderRadius: "5px", width: "100%", objectFit: "cover" }} component="img" src={image_url + item?.coverPic} />
            <Avatar src={image_url + item?.profilePic} sx={{ height: "50px", border: "2px solid white", width: "50px", position: "absolute", left: "38%", top: "55px" }} />
            <Typography sx={{ marginTop: "30px", fontWeight: "600" }}>{title}</Typography>
            {isTeam ? <Button onClick={() => {
                if (onSelect) {
                    onSelect()
                }
            }} sx={{ borderRadius: "5px", marginTop: "10px", marginBottom: "10px", minWidth: "150px" }} variant="contained">Know More</Button> : <Button sx={{ borderRadius: "5px", marginTop: "10px", marginBottom: "10px", minWidth: "150px" }} onClick={() => {
                if (onSelect) {
                    onSelect()
                }
            }} variant="outlined">{!isAdmin?(item?.myTotalPoints)??0:(item?.avg_score)??0}</Button>}
        </Box>
    )
}