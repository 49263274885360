import { createSlice } from "@reduxjs/toolkit";

const currentlocationSlice=createSlice({
    name:"currentlocation",
    initialState:{
        lat:0,
        lng:0
    },
    reducers:{
        setCurrentLocation:(state,action)=>{
           state.lat=action.payload.lat
           state.lng=action.payload.lng
        }
    }
})

export const {setCurrentLocation}=currentlocationSlice.actions

export default currentlocationSlice.reducer