import { create } from 'apisauce'
import { getData } from './asyncStore'
import { base_url } from './endpoints'

export const api = create({
    baseURL: base_url,
})

const hit = async (endpoint = "", method = "GET", body = {}, axiosConfig = {}) => {
    let x = ""
    const tokens = await getData("@tokens");
    let accessToken = tokens?.access
    if (accessToken) {
        api.headers['Authorization'] = `bearer ${accessToken.token}`;
    }
   
    switch (method.toLowerCase()) {
        case "get":
            x = await api.get(endpoint, body,axiosConfig)
            break
        case "post":
            x = await api.post(endpoint, body,axiosConfig)
            break
        case "patch":
            x = await api.patch(endpoint, body,axiosConfig)
            break
        case "delete":
            x = await api.delete(endpoint, body,axiosConfig)
            break
        case "put":
            x = await api.put(endpoint, body,axiosConfig)
            break
    }
    switch (x.problem) {
        case null:
            return { err: false, data: x.data, status: x.status }
        case "CLIENT_ERROR":
            return { err: true, msg: x.data.message, status: x.status }
        case "SERVER_ERROR":
            return { err: true, msg: "SERVER_ERROR", status: x.status }
        default:
            return { err: true, msg: x.problem, status: x.status }
    }
}

export default hit;