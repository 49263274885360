import { Box, Button, Divider, Typography } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { pxToRem } from "../theme/typography";

export default function EventComponent({ i, j, onPressYes, onPressNo, onPressM, onPressDelete, onPressPost }) {
    const user = useSelector(state => state?.auth?.user)
    const teamList = useSelector(state => state?.team?.team)
    const [isPost, setIsPost] = useState(false)
    const [gNames, setGNames] = useState([])
    let [isEvent, setIsEvent] = useState(i?.eventType == "event")
    useEffect(() => {
        if (i?.isScorePost) {
            setIsPost(i?.isScorePost)
        }
        if (i?.groups) {
            // let temp = i?.groups?.filter(x=>x?.)

        }
        setIsEvent(i?.eventType == "event")
    }, [i])
    useEffect(() => {
        if (i?.isScorePost) {
            if (i?.groups) {
                let temp = [...teamList]
                let newArr = []
                i?.groups?.forEach((e) => {
                    temp.map(team => {
                        if (e == team?._id) {
                            newArr?.push(team?.name)
                        }
                    })
                })
                setGNames(newArr)
            }
        }
    }, [i, teamList])
    if (isPost) {
        return (
            <Box sx={{ width: "100%", textAlign: "left", background: "white", marginBottom: "15px", borderRadius: "5px", boxShadow: "0px 5px 20px #26262626", p: "10px" }}>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Box sx={{ display: "flex", alignItems: "center", wordWrap: "break-word" }}>
                        <Box sx={{ height: "30px", background: "rgb(16,176,212)", width: "30px", borderRadius: "30px", alignItems: "center", display: "flex", justifyContent: "center" }}>
                            <Box sx={{ height: "10px", width: "10px" }} component={"img"} src="assets/teams/calendar.svg" />
                        </Box>
                        <Box sx={{ wordWrap: "break-word", flex: 1, maxWidth: "300px" }}>
                            <Typography sx={{ fontWeight: "600", marginLeft: "10px" }}>{i?.category}</Typography>
                        </Box>
                    </Box>
                    <Box onClick={onPressPost} sx={{ display: "flex", alignItems: "center" }}>
                        <Button variant="contained" sx={{ borderRadius: "50px", height: "30px", background: "rgb(16,176,212)", textTransform: "capitalize" }}>Post</Button>
                    </Box>
                </Box>
                <Divider sx={{ marginY: "10px" }} />
                <Typography sx={{ fontSize: pxToRem(14), fontWeight: "600" }}>{gNames?.length > 1 ? "Groups" : "Group"}{":"}</Typography>
                {gNames?.map((item, index) => {
                    return (<Typography sx={{ fontSize: pxToRem(12), marginTop: "5px" }}>{item}</Typography>
                    )
                })}
                <Typography sx={{ fontSize: pxToRem(12), marginTop: "5px" }}>Scroing period</Typography>
                <Typography sx={{ fontSize: pxToRem(12), marginTop: "5px" }}>{moment(i?.scoreStartTime).format("Do MMM hh:mm a")} - {moment(i?.scoreEndTime).format("Do MMM hh:mm a")}</Typography>
            </Box>
        )
    }
    return (
        <Box sx={{ width: "100%", textAlign: "left", background: "white", marginBottom: "15px", borderRadius: "5px", boxShadow: "0px 5px 20px #26262626", p: "10px" }}>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box sx={{ height: "30px", background: isEvent ? "rgb(16,176,212)" : "#F5BA16", width: "30px", borderRadius: "30px", alignItems: "center", display: "flex", justifyContent: "center" }}>
                        <Box sx={{ height: "10px", width: "10px" }} component={"img"} src="assets/teams/calendar.svg" />
                    </Box>
                    <Box sx={{ wordWrap: "break-word", flex: 1 }}>
                        <Typography sx={{ fontWeight: "600", marginLeft: "10px" }}>{i?.title}</Typography>
                    </Box>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Button variant="contained" sx={{ borderRadius: "50px", height: "30px", background: isEvent ? "rgb(16,176,212)" : "#F5BA16", textTransform: "capitalize" }}>{i?.eventType}</Button>
                    {
                        i?.createdBy?._id == user?._id && <Box onClick={onPressDelete} component={"img"} sx={{ height: "25px", width: "25px", marginLeft: "5px", cursor: "pointer" }} src="assets/teams/delete.svg" />
                    }
                </Box>
            </Box>
            <Divider sx={{ marginY: "10px" }} />
            <Typography sx={{ fontSize: pxToRem(14), fontWeight: "600" }}>{i?.description}</Typography>
            <Typography sx={{ fontSize: pxToRem(12), marginTop: "5px" }}>{moment(i?.startDateTime).format("Do MMM")}</Typography>
            <Typography sx={{ fontSize: pxToRem(12), marginTop: "5px" }}>{moment(i?.startDateTime).format("hh:mm a")} - {moment(i?.endDateTime).format("hh:mm a")}</Typography>
            <Typography sx={{ fontSize: pxToRem(12), marginTop: "5px", fontWeight: "600" }}>Location: {i?.address}</Typography>
            {/* <Typography sx={{fontSize:pxToRem(12),marginTop:"5px",fontWeight:"600"}}>Online Meeting link :- <Typography sx={{fontSize:pxToRem(12)}} component={"span"} color={"primary"}>www.culturecode/meeting/123456</Typography></Typography> */}
            <Divider sx={{ marginY: "10px" }} />
            {
                user?._id != i?.createdBy?._id &&
                <>
                    <Typography sx={{ fontWeight: "600", marginLeft: "10px" }}>Attending ?</Typography>
                    <Box sx={{ display: "flex", marginY: "10px" }}>
                        <Button onClick={onPressYes} variant="outlined" color={isEvent ? "primary" : "warning"} sx={{ borderRadius: "50px", flex: 1, height: "30px" }}>Yes</Button>
                        <Button onClick={onPressNo} variant="outlined" color={isEvent ? "primary" : "warning"} sx={{ borderRadius: "50px", marginX: "10px", flex: 1, height: "30px" }}>No</Button>
                        {/* <Button variant="outlined" sx={{borderRadius:"50px",flex:1,height:"30px"}}>Maybe</Button>   */}
                    </Box>
                </>
            }
        </Box>
    )
}