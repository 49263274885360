import { Box, Typography, Button, useMediaQuery, useTheme, List, ListItem, ListItemText, ListItemButton, ListItemIcon, Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { pxToRem } from "../../theme/typography";
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { useEffect, useState } from "react";
import MenuIcon from '@mui/icons-material/Menu';

export default function LandingHeader() {
    const navigate = useNavigate()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("md"))
    const [open, setOpen] = useState(false)
    const handleClickScroll = (id) => {
        const element = document.getElementById(id);
        if (element) {
          // 👇 Will scroll smoothly to the top of the next section
          element.scrollIntoView({ behavior: 'smooth' });
        }
      };


    const list = () => (
        <Box
            sx={{ width: 200 }}
            role="presentation"
            onClick={() => setOpen(false)}
            onKeyDown={() => setOpen(false)}
        >
            <List sx={{marginTop:"20px"}}>
                {['Home', 'About Us', 'Why Us', 'FAQ',"Contact Us"].map((text, index) => (
                    <Typography sx={{marginLeft:"10px",fontWeight:"600",height:"45px"}}>{text}</Typography>
                ))}
            </List>
            <Button onClick={() => {
                navigate("/login")
            }} sx={{ width: "140px",marginLeft:"10px", fontSize: pxToRem(12) }} variant="contained">
                Login
            </Button>
        </Box>
    );
    return (
        <Box sx={{ background: "rgba(255,255,255,0.9)", position: "absolute", top: 0, display: "flex", height: "120px", width: "100vw", alignItems: "center", px: { xs: "10px", md: "8%" } }}>
            <img src="assets/logo.png" style={{ objectFit: "contain" }} height={"70px"} width={"210px"} />
            {!isMobile && <Box display={"flex"} flex={1} alignItems="center" justifyContent="flex-end">
                <Typography   sx={{ fontStyle: "italic",color:"black", fontSize: pxToRem(14), fontWeight: "600" }}>Home</Typography>
                <Typography onClick={()=>{
                    handleClickScroll("about")
                }} sx={{ fontStyle: "italic",cursor:"pointer",color:"black", fontSize: pxToRem(14), fontWeight: "600", mx: "5%" }}>About Us</Typography>
                <Typography onClick={()=>{
                    handleClickScroll("why")
                }}  sx={{ fontStyle: "italic",cursor:"pointer",color:"black", fontSize: pxToRem(14), fontWeight: "600" }}>Why Us</Typography>
                <Typography onClick={()=>{
                    handleClickScroll("faq")
                }}  sx={{ fontStyle: "italic",cursor:"pointer",color:"black", fontSize: pxToRem(14), fontWeight: "600", mx: "5%" }}>FAQ</Typography>
                <Typography  onClick={()=>{
                    handleClickScroll("contact")
                }}  sx={{ fontStyle: "italic",cursor:"pointer",color:"black", fontSize: pxToRem(14), fontWeight: "600", mr: "5%" }}>ContactUs</Typography>
                <Button onClick={() => {
                    navigate("/login")
                }} sx={{ width: "140px", fontSize: pxToRem(12) }} variant="contained">
                    Login
                </Button>
            </Box>}
            {isMobile && <Box sx={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
                <Button onClick={() => {
                    setOpen(true)
                }} sx={{  fontSize: pxToRem(12) }} variant="contained">
                    <MenuIcon/>
                </Button>
            </Box>}
            {isMobile && <SwipeableDrawer
                anchor={"right"}
                open={open}
                onClose={() => {
                    setOpen(false)
                }}
                onOpen={() => {
                    setOpen(true)
                }}
            >
                {list()}

            </SwipeableDrawer>}
        </Box>
    )
}

