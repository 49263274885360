import { Box } from "@mui/material";
import Image from "mui-image";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function Splash() {
    const navigate = useNavigate()
    const auth = useSelector(state => state.auth)
    useEffect(() => {
        if (!auth?.authorize) {
            setTimeout(() => {
                navigate("/landing", { replace: true })
            }, 2000)
        }
    }, [])
    return (
        <>
            <Helmet>
                <title>CultureScore</title>
            </Helmet>
            <Box sx={{ height: "100vh", width: "100vw", overflow: "hidden" }}>
                <Image src="assets/splash/struct.png"
                    height="100%"
                    width="100%"
                    fit="cover"
                    duration={100}
                ></Image>
                <Image
                    src="assets/splash/back.svg"
                    height="100%"
                    width="100%"
                    fit="cover"
                    duration={100}
                    style={{ position: "absolute", top: 0, }}
                />
                <Box style={{ position: "absolute", height: "100%", width: "100%", top: 0, justifyContent: "center", alignItems: "center", display: "flex" }}>
                    <Image

                        style={{
                            height: "30%",
                            width: "35%",
                            objectFit: "contain",
                            minWidth: "233px",
                        }}

                        alt="logo"
                        src="assets/logo.png"
                        fit="contain"
                        duration={1500}
                    />
                </Box>
            </Box >
        </>
    )
}

