import {createSlice} from '@reduxjs/toolkit'

const initialState={
    showTeams:false
}

const testSlice=createSlice({
    name:"test",
    initialState,
    reducers:{
        setShowTeams:(state,action)=>{
            state.showTeams=action.payload
        }
    }
})

export const {setShowTeams}=testSlice.actions

export default testSlice.reducer