import { createSlice } from "@reduxjs/toolkit";

const intialState={
    feeds:[],
    totalPage:0,
    count:1
}

const userFeedsSlice=createSlice({
    name:"userFeeds",
    initialState:intialState,
    reducers:{
        setUserFeedStateData:(state,action)=>{
            state[`${action.payload.key}`]=action.payload.value
        }
    }
})

export const {setUserFeedStateData}=userFeedsSlice.actions

export default userFeedsSlice.reducer