import { Avatar, Box, Button, Typography } from "@mui/material";
import { image_url } from "../../services/endpoints";

export default function GroupSelect({ style, isSelected, id, selecteds, title,item, setSelecteds }) {
    return (
        <Box sx={[{ boxShadow: "0px 5px 20px #26262626", position: "relative", background: "white", borderRadius: "5px", padding: "5px", flex: 1, }, style]}>
            <Box sx={{ height: "80px", borderRadius: "5px", width: "100%", objectFit: "cover" }} component="img" src={image_url+item?.coverPic}/>
            <Avatar src={image_url+item?.profilePic} sx={{ height: "50px", border: "2px solid white", width: "50px", position: "absolute", left: "38%", top: "55px" }} />
            <Typography sx={{ marginTop: "30px", fontWeight: "600" }}>{title}</Typography>
            <Button onClick={() => {
                if (isSelected) {
                    setSelecteds(selecteds.filter(x => x != id))
                } else {
                    setSelecteds([...selecteds, id])

                }
            }} sx={{ borderRadius: "5px", marginTop: "10px", color: isSelected ? "white" : "rgb(13,177,212)", marginBottom: "10px", background: isSelected ? "rgb(13,177,212)" : "transparent", minWidth: "150px" }} variant="outlined">{isSelected ? "Selected" : "Select"}</Button>
        </Box>
    )
}