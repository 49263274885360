import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const initialState = {
    id: null,
    isScorePost: false,
    isThemedPost: false,
    isPollPost: false,
    isQuizPost: false,
    txt: "",
    groups: [],
    themeColor: "#1F5FC8",
    pollEndTime: moment(),
    scoreStartTime: moment(),
    scoreEndTime: moment().add(2,"days").add(2,"hours"),
    photos: [],
    thumbnails: [],
    videos: [],
    attachments: [],
    pollOptions: ["", ""],
    category: "",
    scoreBy: [],
    verifyBy: [],
    isApproved: 0,
    scorePoint: 1,
    scorePoint1: 1,
    scorePoint2: 1,
    scoreCount: 1,
    eventLocation: {
        address: "",
        lat: 0,
        lng: 0
    },
    quizTitle: "",
    quizDesc: "",
    quizData: [],
    quizScoreCount:1,
}
// [{ qn: "", ansKey: "", options: ["", ""], point: 1 }]
// {
//     qn: { type: String, default: "" },
//     ansKey: { type: String, default: "" },
//     options: [{ type: String, default: "" }],
//     point: { type: Number, default: 0 }
// }

const postSlice = createSlice({
    name: "post",
    initialState,
    reducers: {
        setPostData: (state, action) => {
            state[`${action.payload.key}`] = action.payload.value
        },
        setToInitalPostData: (state, action) => {
            return initialState
        }
    }
})

export const { setPostData, setToInitalPostData } = postSlice.actions

export default postSlice.reducer