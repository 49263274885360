import { Avatar, Badge, Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import hit from "../services/apiManager";
import { faq } from "../services/endpoints";
import { setLoading } from "../store/load";
import showToast from "../utils/showToast";
import FileViewer from 'react-file-viewer';
import { CustomErrorComponent } from 'custom-error';

export default function FileView() {
    const navigate = useNavigate()
    const [list, setList] = React.useState([])
    const dispatch = useDispatch()
    const { url } = useLocation().state
    // const url = "https://d1jfvl8f1yx3kr.cloudfront.net/files/1700575034873-RNFetchBlobTmp_pdhsakxbkfg2jck3ut11yn.pdf"
    return (
        <Box sx={{ px: "5%", pt: "20px", display: "flex", pr: "10%", overflowY: "auto", flex: 1, width: "100%", background: "linear-gradient(119deg,#E6F6F9,white,rgb(253,243,213))" }}>
            <FileViewer
                fileType={url.split(".").pop()}
                filePath={url}
                errorComponent={CustomErrorComponent}
                onError={(err) => {
                    console.error(err, 'error in file-viewer');
                }} />
        </Box>
    )
}


