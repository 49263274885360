import { Box, Typography, Button, useMediaQuery, useTheme } from "@mui/material";
import { pxToRem } from "../../theme/typography";

export default function LandingPageAboutSection() {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("md"))

    return (
        <Box sx={{ display: "flex", width: "100vw", alignItems: "center", marginTop: "50px", px: { xs: "10px", md: "8%" } }}>
            <Box sx={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>

                <Typography sx={{ fontSize: pxToRem(30), fontWeight: "600", textAlign: "left" }}>About <Typography component={"span"} color="primary" sx={{ fontSize: pxToRem(30), fontWeight: "600" }}>Culture Score?</Typography></Typography>
                <br />
                {isMobile && <Box display={"flex"} flex={1}>
                    <img src="assets/landing/ball.png" alt="about logo" style={{ flex: 1, width: "30vw", objectFit: "contain", height: "30vw" }} />
                </Box>}
                <Typography lineHeight={"30px"} textAlign={"left"}>Culture Score understands that culture encompasses the customs, arts, social institutions, and achievements of a specific nation, people, or social group. We firmly believe that exceptional organizations, teams, and groups maintain their effectiveness and foster success by intentionally cultivating a shared language, aligned actions, and celebrated accomplishments that reflect their values, vision, and mission.</Typography>
                <br />
                <Typography lineHeight={"30px"} textAlign={"left"}>To facilitate the seamless implementation of these essential elements of a quality culture, Culture Score provides a user-friendly digital platform available both on the web and as a mobile app. This platform empowers leaders and team members to communicate, collaborate, and engage in actions that nurture a thriving culture.</Typography>
                <br />
                <Typography lineHeight={"30px"} textAlign={"left"}>What sets us apart is our innovative approach of gamifying culture. We introduce a scoring system that rewards and tracks engagement in cultural activities and specific types of communication. Users can monitor their scores, compare their rankings within the team, and collaborate to elevate the team's average score. Leaders can leverage this data to identify areas for culture improvement based on team engagement.</Typography>
                <br />
                <Typography lineHeight={"30px"} textAlign={"left"}>As your team's culture score climbs and the scored actions align harmoniously, the desired culture will flourish. We envision our tool as a catalyst for enhancing the effectiveness and success of your organization in boundless ways.</Typography>
                <br />
                <Typography lineHeight={"30px"} textAlign={"left"}>Culture Score: Enabling your organization to reach new heights of effectiveness and success!</Typography>
                
                <Button onClick={()=>{
                          const element = document.getElementById("contact");
                          if (element) {
                            // 👇 Will scroll smoothly to the top of the next section
                            element.scrollIntoView({ behavior: 'smooth' });
                          }
                        
                }} sx={{ width: "200px", marginTop: "20px", height: "50px", fontSize: pxToRem(12), borderRadius: 0 }} variant="contained">
                    Contact us
                </Button>
            </Box>
            {!isMobile && <Box display={"flex"} flex={1}>
                <img src="assets/landing/ball.png" alt="about logo" style={{ flex: 1, width: "30vw", objectFit: "contain", height: "30vw" }} />
            </Box>}
        </Box>
    )
}