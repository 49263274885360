import { Box, Typography, Button } from "@mui/material";
import { pxToRem } from "../../theme/typography";

export default function LandingDownloadPageSection() {
    return (
        <Box sx={{ display: "flex", flexDirection: "column", py: { sm: "10px", md: "2%" }, width: "100vw", alignItems: "center", marginTop: "50px", px: { sm: "10px", md: "8%" } }}>
            <Typography sx={{ fontSize: pxToRem(30), fontWeight: "600" }}>Download <Typography component={"span"} color="primary" sx={{ fontSize: pxToRem(30), fontWeight: "600" }}>Culture Score </Typography><Typography component={"span"} sx={{ fontSize: pxToRem(30), fontWeight: "600" }}>App!</Typography></Typography>
            {/* <br /> */}
            <Typography textAlign={"center"} sx={{ px: { sm: "0px", md: "5%" }, fontWeight: "600", fontSize: pxToRem(14) }}>Building organizational strength and performance through aligned and scored actions.</Typography>
            <br />
            <Box sx={{ display: "flex", alignItems: "center", marginTop: "20px" }}>
                <img alt="android" src="assets/landing/android.png" style={{ height: "70px", objectFit: "contain" }} />
                <img alt="ios" src="assets/landing/ios.png" style={{ height: "70px", objectFit: "contain", marginLeft: "20px" }} />
            </Box>
            <img src="assets/landing/mob.png"  alt="grp images" style={{width:"100%"}} />
            <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
            </Box>
            <br />
        </Box>
    )
}
