import { Avatar, Badge, Box, Button, Typography } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Input from "../components/Login/Input";
import hit from "../services/apiManager";
import { teams } from "../services/endpoints";
import { setLoading } from "../store/load";
import { getAllTeamThunk } from "../store/team";
import { setShowTeams } from "../store/test";
import { pxToRem } from "../theme/typography";
import showToast from "../utils/showToast";

export default function JoinGroup() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const user = useSelector(state => state?.auth?.user)
    const [code, setCode] = useState("")



    const joinTeam = async () => {
        if (code.trim() == "") {
            showToast("Access code is required.","warning")
            return
        }
        try{
            dispatch(setLoading(true))
            let res = await hit(teams?.joinTeam, "post", { accessCode: code })
            if (res?.err) {
                showToast(res?.msg,"error")
            } else {
                showToast("Team joined sucessfully.")
                dispatch(getAllTeamThunk())
                navigate(-1)
            }
        }catch(err){

        }finally{
            dispatch(setLoading(false))
        }
       
    }
    return (
        <Box sx={{ px: "5%", pt: "20px", display: "flex", pr: "10%", overflowY: "auto", flex: 1, width: "100%", background: "linear-gradient(119deg,#E6F6F9,white,rgb(253,243,213))" }}>
            <Box sx={{ flex: 1, minHeight: "100px" }}>
                <Typography sx={{ textAlign: "left", fontWeight: "600" }}>Join Team/Group</Typography>
                <Box sx={{ display: "flex", px: "10px", py: "20px", marginTop: "10px", flexDirection: "column", background: "white", boxShadow: "0px 5px 20px #26262626", borderRadius: "5px", minHeight: "100px" }}>
                    <Typography sx={{ textAlign: "left", fontSize: pxToRem(14) }}>Do you have a team/group Access Code</Typography>
                    <br />
                    <Input value={code} onChange={v=>setCode(v)} isPass={true} titleStyle={{ textAlign: "left" }} title="Access Code" />
                    <br />
                    <br />
                    <Button onClick={() => {
                       joinTeam()
                        
                    }} sx={{ fontSize: pxToRem(16), borderRadius: "5px", marginX: "auto", height: "45px", width: "200px" }} variant="contained">
                        Join
                    </Button>
                    <br />
                </Box>
            </Box>
            <Box sx={{ flex: 1, marginLeft: "5%", }}>

            </Box>
        </Box>
    )
}