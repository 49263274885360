import { Avatar, Badge, Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Input from "../components/Login/Input";
import hit from "../services/apiManager";
import { supports } from "../services/endpoints";
import { setLoading } from "../store/load";
import { pxToRem } from "../theme/typography";
import showToast from "../utils/showToast";

export default function ContactUs() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const user = useSelector(state => state?.auth?.user)

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [desc, setDesc] = useState("")

    useEffect(() => {
        if (user?.firstName) {
            setName(user?.firstName)
        }
        if (user?.email) {
            setEmail(user?.email)
        }
    }, [user])

    const submit = async () => {
        if (desc?.trim() == "") {
            showToast("Type your message...","warning")
            return
        }
        try {
            dispatch(setLoading(true))
            const res = await hit(supports, "post", { "name": name, "email": email, "message": desc })
            if (res?.err) {
                showToast(res?.msg,"error")
            } else {
                showToast("Message recorded.")
               navigate(-1)
            }
        } catch (e) {
        } finally {
            dispatch(setLoading(false))
        }
    }
    return (
        <Box sx={{ px: "5%", pt: "20px", display: "flex", pr: "10%", overflowY: "auto", flex: 1, width: "100%", background: "linear-gradient(119deg,#E6F6F9,white,rgb(253,243,213))" }}>
            <Box sx={{ flex: 1, minHeight: "100px" }}>
                <Typography sx={{ textAlign: "left", fontWeight: "600" }}>Contact Us</Typography>
                <Box sx={{ display: "flex", px: "10px", py: "20px", marginTop: "10px", flexDirection: "column", background: "white", boxShadow: "0px 5px 20px #26262626", borderRadius: "5px", minHeight: "100px" }}>

                    <Input value={name} onChange={v=>setName(v)} titleStyle={{ textAlign: "left" }} title="Name" img={"assets/signup/user.svg"} />
                    <br />
                    <Input  value={email} onChange={v=>setEmail(v)} titleStyle={{ textAlign: "left" }} title="Email" img={"assets/login/mail.svg"} />
                    <br />
                    <Input value={desc} onChange={v=>setDesc(v)}  placeholder={"Type your message..."} inputContainerStyle={{ height: "100px" }}  multiline={true} titleStyle={{ textAlign: "left" }} title="Message" img={"assets/signup/user.svg"} />
                    <br />
                    <Button onClick={() => {
                        submit(-1)
                    }} sx={{ fontSize: pxToRem(16), borderRadius: "5px", marginX: "auto", height: "45px", width: "200px" }} variant="contained">
                        Send
                    </Button>
                    <br />
                </Box>
            </Box>
            <Box sx={{ flex: 1, marginLeft: "5%", }}>

            </Box>
        </Box>
    )
}