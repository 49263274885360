import { Box, Typography } from "@mui/material";

export default function Footer() {
    const currentYear = new Date().getFullYear();
    return (
        <Box sx={{ background: "#262626", height: "5vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Typography textAlign={"center"} fontSize={"14px"} fontWeight={"600"} color="white">© {currentYear} - All Rights Reserved - Culture Score | Privacy and Terms of Use | Designed & Developed by</Typography>
            <Box sx={{ height: "20px", marginLeft: "5px" }} component="img" src="assets/landing/solidappmaker.png" />
        </Box>
    )
}