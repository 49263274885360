import { createSlice } from "@reduxjs/toolkit";
import hit from "../services/apiManager";
import { teams } from "../services/endpoints";

const teamSlice = createSlice({
    name: "team",
    initialState: { team: [], selectedTeam: null },
    reducers: {
        setTeam: (state, action) => {
            state.team = action.payload
        },
        setSelectedTeam: (state, action) => {
            state.selectedTeam = action.payload
        },
        clearTeam: (state, action) => {
            state.team = []
        }
    }
})

export const { setTeam, clearTeam, setSelectedTeam } = teamSlice.actions

export const getAllTeamThunk = (callback) => {
    return async (dispatch) => {
        try {
            let res = await hit(teams.createTeam, "get")
            if (res?.data) {
                let newA = res?.data.filter(x => x?.active != 0)
                dispatch(setTeam(newA))
            }
        } catch (err) {
            console.error(err)
        } finally {
            if (callback) {
                callback()
            }
        }
    }
}

export default teamSlice.reducer

