import { Box, Typography, Button } from "@mui/material";
import { pxToRem } from "../../theme/typography";

export default function LandingPageWhySection() {
    return (
        <Box sx={{ display: "flex", flexDirection: "column", background: "#0BB2D020", py: { xs: "10px", md: "2%" }, width: "100vw", alignItems: "center", marginTop: "50px", px: { xs: "10px", md: "8%" } }}>
            <Typography sx={{ fontSize: pxToRem(30), fontWeight: "600" }}>Why <Typography component={"span"} color="primary" sx={{ fontSize: pxToRem(30), fontWeight: "600" }}>Culture Score?</Typography></Typography>
            <br />
            <Typography textAlign={"left"} sx={{ px: { xs: "0px", md: "5%" } ,marginBottom:"10px"}}>Discover why Culture Score is the ideal solution for intentionally cultivating a thriving team culture that enhances group effectiveness and success:</Typography>
            <Box sx={{textAlign:"left", px: { xs: "0px", md: "5%" } }}>
            <Typography lineHeight={"24px"}>1. Seamless Communication: Culture Score offers a user-friendly platform that enables clear and positive communication within the group, fostering a shared understanding and a harmonious environment.</Typography>
            <Typography lineHeight={"24px"}>2. Scored Culture Development: Leaders can develop a comprehensive plan to assess and score the team's culture based on their communications, actions, and other activities. This structured approach allows for targeted improvements and measurable progress.</Typography>
            <Typography lineHeight={"24px"}>3. Real-time Engagement Tracking: Team members can monitor their engagement scores in real time and see where they rank within the team. This gamified aspect encourages friendly competition and personal development, creating a sense of achievement and motivation.</Typography>
            <Typography lineHeight={"24px"}>4. Data-driven Adjustments: Leaders have access to comprehensive reports that can be disaggregated based on specific cultural categories. This valuable insight allows for targeted adjustments and addressing areas of improvement to further enhance the team's culture.</Typography>
            <Typography lineHeight={"24px"}>5. Timely Announcements: Culture Score enables important and urgent information to be shared through SMS text messaging, ensuring that critical announcements reach team members promptly.</Typography>
            <Typography lineHeight={"24px"}>6. Versatile Media Interaction: With features like a shared calendar displaying scoring opportunities and organizational events, group and individual chat with admin controls, and diverse media integration, Culture Score caters to various communication preferences and engagement styles.</Typography>
            <Typography lineHeight={"24px"}>7. Positive and Engaging Social Platform: Culture Score provides a fun, positive, and engaging social media platform suitable for users of all ages. It fosters a culture that is not only effective and successful but also promotes a sense of enjoyment and connection.</Typography>
            <Typography sx={{marginTop:"10px"}}  textAlign={"left"}>Choose Culture Score to cultivate a team culture that thrives, elevates effectiveness, and fuels success!</Typography>
            </Box>
            <br />
            <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                <Comp file={"assets/landing/team.png"} name="Team/Groups" />
                <Comp file={"assets/landing/chat.png"} name="Chat" />
                <Comp file={"assets/landing/score.png"} name="Scoreboard" />
            </Box>
            <br />
            <Button sx={{ width: "200px", marginTop: "20px", height: "50px", fontSize: pxToRem(12), borderRadius: 0 }} variant="contained">
                Download
            </Button>
        </Box>
    )
}

const Comp = ({ file, name, style }) => {
    return (
        <Box >
            <Box
                component={"img"}
                src={file}
                sx={{
                    objectFit: "cover",
                    borderRadius: "10px",
                    flex: 1,
                    width: "24vw",
                    // width:"30%"
                    ...style
                }}
            />
            <Typography sx={{ fontSize: pxToRem(18), fontWeight: "600", marginTop: "10px" }}>{name}</Typography>
        </Box>
    )
}