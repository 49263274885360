import { createSlice } from "@reduxjs/toolkit";

const draftSlice=createSlice({
    name:"drafts",
    initialState:{
        feeds:[],
        totalPage:0,
        count:1
    },
    reducers:{
        setDraftStateData:(state,action)=>{
            state[`${action.payload.key}`]=action.payload.value
        },
        clearDraftData:(state,action)=>{
            return {
                feeds:[],
                totalPage:0,
                count:1
            }
        }
    }
})

export const {setDraftStateData,clearDraftData}=draftSlice.actions

export default draftSlice.reducer