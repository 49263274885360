import React, { useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { pxToRem } from '../../theme/typography'
import PointsComp from '../../components/ScoreBoard/PointsComp'
import { scores } from '../../services/endpoints'
import moment from 'moment'
import hit from '../../services/apiManager'

const RankingHeader = ({ isAdmin }) => {
    return (
        <Box sx={{ height: "40px", display: "flex", px: "10px", justifyContent: "space-between", textAlign: "left", alignItems: "center", border: "1px solid #0BB2D0", background: "#0BB2D00D", width: "100%", borderRadius: "5px" }}>
            <Typography color={"primary"} sx={{ width: "20%", fontWeight: "600", fontSize: pxToRem(14) }}>Ranking</Typography>
            {isAdmin && <Typography color={"primary"} sx={{ width: "40%", fontWeight: "600", fontSize: pxToRem(14) }}>Name</Typography>}
            <Typography color={"primary"} sx={{ width: "40%", fontWeight: "600", fontSize: pxToRem(14) }}>Points</Typography>
        </Box>
    )
}

const RankingRowApi = ({ adminView, isAdmin, i, j, user, groupId, scoringMode, sdate, edate }) => {
    
    const [showPoint, setShowPoint] = useState(false)
    const [scoringList, setScoringList] = useState([])
    
    const getScores = async () => {
        scoringMode = scoringMode?.toString().toLowerCase()
        let query = `?groups=${groupId}&mode=${scoringMode}&page=1&pageSize=1000&user=${i?._id}`;
        if (scoringMode == 'miscellaneous') {
            query = query.concat(
                `&startDate=${moment(sdate).format('DD-MM-YYYY')}&endDate=${moment(edate).format('DD-MM-YYYY')}`,
            );
        }
        let res = await hit(`${scores?.history}${query}`);
        if (!res?.err) {
            let scoringData = res?.data?.list;
            let allScorings = [...scoringList, ...scoringData];
            setScoringList(allScorings);
        }
    }

    useEffect(() => {
        if(showPoint){
            getScores()
        }
    },[showPoint])
    
    let role = i?.role == 'ableToScores' ? '(A) (S)' : i?.role == 'admin' ? '(A)' : '';
    return (
        <>
            <Box 
                onClick={() => {
                    if(i?.points>0)
                        if(showPoint){
                            setScoringList([])
                        }
                        setShowPoint(!showPoint)
                }}
                sx={{ height: "40px", cursor: "pointer", display: "flex", px: "10px", justifyContent: "space-between", textAlign: "left", alignItems: "center", marginTop: "15px", background: (user == i?._id) ? "#98fb98" : "#0DB1D41A", width: "100%", borderRadius: "5px" }}>
                    <Typography sx={{ width: "20%", fontWeight: "600", fontSize: pxToRem(14) }}>{j + 1}</Typography>
                    {<Typography sx={{ width: "40%", fontWeight: "600", fontSize: pxToRem(14) }}>{(isAdmin || user == i?._id) ? (i?.firstName + " " + i?.lastName + " ") : " "+role}</Typography>}
                    <Typography sx={{ width: "40%", fontSize: pxToRem(14), fontWeight: "600" }}>{i?.points}</Typography>
            </Box>
            {
                showPoint && scoringList?.map((i, j) => (
                    <PointsComp 
                        i={i} 
                        j={j} 
                        style={{ marginTop: "15px" }} 
                        admin={isAdmin} 
                        key={i?._id} 
                        adminView={adminView} 
                    />
                ))
            }
        </>
    )
}

export {
    RankingHeader,
    RankingRowApi
}