import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { pxToRem } from "../../theme/typography";

export default function UserTypeSelect({ type, onSelect }) {
    const [selected, setSelected] = useState(0)
    useEffect(() => {
        if (onSelect) {
            if (type == 0) {
                if (selected == 0) {
                    onSelect("User")
                } else {
                    onSelect("Parent")
                }
            } else {
                if (selected == 0) {
                    onSelect("Business")
                } else {
                    onSelect("Student")
                }
            }
        }
    }, [selected])
    return (
        <Box sx={{ display: "flex", background: "#0DB1D410", borderRadius: "60px", marginTop: "10px", marginX: "auto", height: "40px", width: "90%", boxShadow: "0px 5px 10px #2626260D" }}>
            <Box onClick={(e) => {
                setSelected(0)
            }} sx={{ flex: 1, borderRadius: "60px", display: "flex", background: selected == 0 ? "#0DB1D4" : "transparent", justifyContent: "center", alignItems: "center" }}>
                <Typography sx={{ color: selected == 0 ? "white" : "black", fontSize: pxToRem(14), fontWeight: selected == 0 ? "600" : "400" }}>{type == 0 ? "User" : "Business"}</Typography>
            </Box>
            <Box onClick={(e) => {
                setSelected(1)
            }} sx={{ flex: 1, borderRadius: "60px", display: "flex", background: selected == 1 ? "#0DB1D4" : "transparent", justifyContent: "center", alignItems: "center" }}>
                <Typography sx={{ color: selected == 1 ? "white" : "black", fontSize: pxToRem(14), fontWeight: selected == 1 ? "600" : "400" }}>{type == 0 ? "Parent" : "Student"}</Typography>
            </Box>
        </Box>
    )
}