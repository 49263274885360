import { Box, Typography, Button, useTheme, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { pxToRem } from "../../theme/typography";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../store/load";
import hit from "../../services/apiManager";
import { faq } from "../../services/endpoints";
import showToast from "../../utils/showToast";

export default function LandingPageFAQSection() {
    const theme = useTheme()
    const location = useLocation()
    const isMobile = useMediaQuery(theme.breakpoints.down("md"))
    const [list, setList] = useState([])
    const dispatch = useDispatch()
    useEffect(() => {
        getAllList()
    }, [])

    const getAllList = async () => {
        try {
            let res = await hit(faq.getFaqs, "get")
            if (res?.err) {
                showToast(res?.msg, "error")
            } else {
                setList(res?.data)
            }
        } catch (e) {
        } finally {
        }
    }
    return (
        <Box sx={{ display: "flex", flexDirection: "row", background: "#0BB2D020", py: { xs: "10px", md: "2%" }, width: "100vw", alignItems: "flex-start", marginTop: "50px", px: { xs: "10px", md: "8%" } }}>
            <Box sx={{ display: "flex", flexDirection: "column", width: "100%", minHeight: 60 * 7, justifyContent: "space-between" }} >
                <Typography sx={{ fontSize: pxToRem(30), fontWeight: "600", textAlign: "left" }}>FREQUENTLY ASKED QUESTIONS</Typography>
                {isMobile && <Box sx={{ display: "flex", flexDirection: "column", width: "100%", }}>
                    {list?.map(x => (
                        <Comp item={x} />
                    ))}
                </Box>}
                <Box>
                    <Typography lineHeight={"30px"} textAlign={"left"} sx={{ px: { xs: "0px", md: "5%" }, fontSize: pxToRem(20) }}>More questions?</Typography>

                    <Typography lineHeight={"30px"} textAlign={"left"} sx={{ px: { xs: "0px", md: "5%" }, fontSize: pxToRem(20), fontWeight: "600" }}>Contact Support</Typography>
                </Box>
            </Box>
            {!isMobile && <Box sx={{ display: "flex", flexDirection: "column", width: "100%", }}>
                {list?.map(x => (
                    <Comp item={x} />
                ))}
            </Box>}
        </Box>
    )
}

const Comp = ({item}) => {
    const [show, setShow] = useState(false)
    return (
        <>
            <Box

                sx={{
                    height: "50px",
                    display: "flex",
                    alignItems: "center",
                    background: "#0DB1D41A",
                    justifyContent: "space-between",
                    px: "10px",
                    marginTop: "5px"
                }}
                onClick={() => {
                    setShow(!show)
                }}
            >
                <Typography sx={{textAlign:"left"}}>{item?.qn}</Typography>
                <img src="assets/landing/plus.png" style={{ height: "18px", width: "18px", objectFit: "contain" }} />
            </Box>
            {show && <Typography sx={{ textAlign: "left", marginY: "10px" }}>{item?.ans}</Typography>}
        </>
    )
}