import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { pxToRem } from '../theme/typography';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "25vw",
    minHeight: "15vh",
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: "5px",
    p: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
};

export default function ScoreAccessModal({ open,setOpen, onPressOk, detail }) {
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    let name = (detail?.body)?.split(" ")[0]
    let point = detail?.postId?.scorePoint1
    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box sx={{ display: "flex", justifyContent: "space-between",width:"100%", alignItems: "center" }}>
                        <Box sx={{width:"20px"}} />
                        <Box onClick={()=>{
                            handleClose()
                        }} sx={{width:"20px",height:"20px",cursor:"pointer"}} component="img" src='assets/android-cancel.png' />
                    </Box>
                    <Box sx={{ flex: 1, minHeight: "100px" }}>
                        <Box sx={{ display: "flex", py: "20px", marginTop: "10px", flexDirection: "column",alignItems:"center", background: "white", borderRadius: "5px", minHeight: "100px" }}>
                            <Box component={"img"} src="assets/modal/congif.gif" sx={{ height: "100px", objectFit: "contain" }} />
                            <Typography sx={{fontSize:"18px",fontWeight:"600",textAlign:"center"}}>Points Scored</Typography>
                            <Typography sx={{fontSize:"14px",fontWeight:"500",textAlign:"center"}}>{name} has Scored {point} Point{point>1?"s":""}</Typography>

                            <br />
                            <Button onClick={()=>{
                            onPressOk(detail?._id)
                        }} sx={{ fontSize: pxToRem(14), fontWeight: "400", marginTop: "5px", borderRadius: "5px", marginX: "auto", height: "45px", width: "300px" }} variant="contained">
                               Ok
                            </Button>
                        </Box>
                        <br />
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}