import { Avatar, Badge, Box, Button, Typography } from "@mui/material";
import { minHeight } from "@mui/system";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Input from "../components/Login/Input";
import hit from "../services/apiManager";
import { image_url, subgroups } from "../services/endpoints";
import { setLoading } from "../store/load";
import { setShowTeams } from "../store/test";
import { pxToRem } from "../theme/typography";
import showToast from "../utils/showToast";
import SearchBar from "../components/Home/SearchBar";
import _ from 'lodash'
export default function CreateSubGroup() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [name, setName] = useState("")
    const { group } = useLocation().state ?? {}
    const [subGroup, setSubGroup] = useState(null)
    const [sMembers, setSMembers] = useState([])
    const [search, setSearch] = useState("")
    useEffect(() => {
        if (subGroup?.members) {
            setSMembers(subGroup?.members)
        }
    }, [subGroup])

    const submit = async () => {
        if (name.trim() == "") {
            showToast("Subgroup name is required.", "warning")
            return
        }
        try {
            dispatch(setLoading(true))
            let res = await hit(subgroups.create, "post", { parentId: group?._id, name: name })
            if (res?.err) {
                showToast(res?.msg, "error")
            } else {
                showToast("Sub-group created successfully.")
                setSubGroup(res?.data)
                setSMembers(res?.data?.members)
                // navigation.navigate("AddmembersSubgroup", { groupDetail, subgroupDetail: res?.data })
            }
        } catch (e) {

        } finally {
            dispatch(setLoading(false))
        }
    }
    const addMem = async (id) => {
        try {
            dispatch(setLoading(true))
            let res = await hit(subgroups?.addMember, "post", { gId: subGroup?._id, userId: id })
            if (res?.err) {
                showToast(res?.msg, "error")
            } else {
                showToast("Member added successfully.")
                setSMembers(res?.data?.members)
            }
        } catch (e) {
        } finally {
            dispatch(setLoading(false))
        }
    }
    const removeMem = async (id) => {
        try {
            dispatch(setLoading(true))
            let res = await hit(subgroups?.removeMember, "post", { gId: subGroup?._id, userId: id })
            if (res?.err) {
                showToast(res?.msg, "error")
            } else {
                showToast("Member removed successfully.")
                setSMembers(res?.data?.members)
            }
        } catch (e) {
        } finally {
            dispatch(setLoading(false))
        }
    }
    // console
    return (
        <Box sx={{ px: "5%", pt: "20px", display: "flex", pr: "10%", overflowY: "auto", flex: 1, width: "100%", background: "linear-gradient(119deg,#E6F6F9,white,rgb(253,243,213))" }}>
            <Box sx={{ flex: 1, minHeight: "100px" }}>
                <Typography sx={{ textAlign: "left", fontWeight: "600" }}>Create Subgroup</Typography>
                <Box sx={{ display: "flex", px: "10px", minHeight: "100px", py: "20px", marginTop: "10px", flexDirection: "column", background: "white", boxShadow: "0px 5px 20px #26262626", borderRadius: "5px", }}>
                    <Typography sx={{ textAlign: "left", fontWeight: "500", fontSize: pxToRem(14) }}>Name your SubGroup under {group?.name}</Typography>
                    <br />
                    <Input value={name} onChange={v => {
                        if (subGroup == null) {
                            setName(v)
                        }
                    }} isPass={false} titleStyle={{ textAlign: "left" }} title="Subgroup Name" />
                    <br />
                    <br />
                    {subGroup == null && <Button onClick={() => {
                        // navigate(-1)
                        submit()
                        // dispatch(setShowTeams(true))
                    }} sx={{ fontSize: pxToRem(16), borderRadius: "5px", marginX: "auto", height: "45px", width: "200px" }} variant="contained">
                        Next
                    </Button>}
                    <br />
                </Box>
            </Box>
            <Box sx={{ flex: 1, marginLeft: "5%", }}>
                {
                    subGroup != null && <><Typography sx={{ textAlign: "left", fontWeight: "600" }}>Add Members In Sub group</Typography>
                        <br />
                        <SearchBar value={search} onChange={r => { setSearch(r) }} placeholder={"Search...."} />
                        <Box sx={{ display: "flex", minHeight: "400px", px: "10px", py: "20px", marginTop: "10px", flexDirection: "column", background: "white", boxShadow: "0px 5px 20px #26262626", borderRadius: "5px", }}>
                            <br />
                            {_.sortBy(group?.members, [function (o) { return String(o?.lastName)?.toLowerCase() }])?.filter(x => {
                                if (search?.trim() != "") {
                                    return (String(x?.firstName + x?.lastName + x?.email).toLowerCase()?.includes(search?.toLowerCase()))
                                } else {
                                    return true
                                }
                            })?.map(x => <CheckComponent onPressChecked={() => {
                                if (sMembers.includes(x?._id)) {
                                    removeMem(x?._id)
                                } else {
                                    addMem(x?._id)
                                }
                            }} isChecked={sMembers.includes(x?._id)} item={x} title={x?.firstName} />)}
                            <br />
                            <br />
                            <Button onClick={() => {
                                navigate(-1)
                            }} sx={{ fontSize: pxToRem(16), borderRadius: "5px", marginX: "auto", height: "45px", width: "200px" }} variant="contained">
                                Done
                            </Button>
                            <br />
                        </Box></>}
            </Box>
        </Box>
    )
}


const CheckComponent = ({ title, isChecked, onPressChecked, item }) => {
    return (
        <Box sx={{ minHeight: "60px", width: "100%", marginBottom: "20px", p: "10px", display: "flex", alignItems: "center", background: "white", boxShadow: "0px 5px 20px #26262626" }}>

            <Avatar
                src={image_url + item?.profilePic}
                style={{ height: "40px", width: "40px" }}
            />
            <Box sx={{ marginLeft: "15px", flex: 1, display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                <Typography sx={{ fontWeight: "600", fontSize: pxToRem(14) }}>{title} {item?.lastName}</Typography>
                <Typography sx={{ fontWeight: "400", fontSize: pxToRem(12) }}>{item?.email}</Typography>

            </Box>
            <Box onClick={() => {
                // setIsChecked(!isChecked)
                onPressChecked()
            }} component={"img"} sx={{ cursor: "pointer" }} src={isChecked ? "assets/groups/ios-checkbox.svg" : "assets/groups/ios-checkbox-outline.svg"} />
        </Box>
    )
}

