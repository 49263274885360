import { createSlice } from "@reduxjs/toolkit";
import hit from "../services/apiManager";
import { childs } from "../services/endpoints";

const childSlice=createSlice({
    name:"childs",
    initialState:{list:[]},
    reducers:{
        setChildList:(state,action)=>{
            state.list=action.payload
        }
    }
})

export const {setChildList}=childSlice.actions

export const getChildListThunk = () => {
    return async (dispatch) => {
        try {
            let res = await hit(childs, "get")
            if (res?.data) {
                dispatch(setChildList(res?.data))
            }
        } catch (err) {
            console.error(err)
        }
    }
}

export default childSlice.reducer