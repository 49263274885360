import { Box } from '@mui/material'
import { useSelector } from 'react-redux'

export default function Loader() {
    const loader=useSelector(state=>state.load)?.isLoading
    return (
        <>
        {loader?<Box sx={{ height: "100vh", display: "flex",background:"#0007", justifyContent: "center", alignItems: "center", width: "100vw",position:"absolute",top:0,left:0 ,zIndex:10000}}>
           <img src='assets/loader1.gif' style={{height:"40px"}} />
        </Box>:null}
        </>
    )
}