import { Avatar, Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import hit from "../../services/apiManager";
import { image_url, teams, uploads } from "../../services/endpoints";
import { setLoading } from "../../store/load";
import { getAllTeamThunk } from "../../store/team";
import { pxToRem } from "../../theme/typography";
import showToast from "../../utils/showToast";
import lodash from 'lodash'

export default function GroupScoreProfile({ style, team, isAdmin, avgPoint, yourPoint, isScore, fin, adminView, totalTemp }) {
    const dispatch = useDispatch()
    const [profilePic, setProfilePic] = useState(team?.profilePic)
    const [coverPic, setCoverPic] = useState(team?.coverPic)

    console.log("isAdmin>>>>>", isAdmin, "isScore", isScore)

    useEffect(() => {
        setProfilePic(team?.profilePic)
        setCoverPic(team?.coverPic)
    }, [team])

    const uploadPictures = async (image, imgType) => {
        try {
            dispatch(setLoading(true))
            let formdata = new FormData()
            formdata.append("file", image)
            let res = await hit(uploads, "post", formdata)
            if (!res.err) {
                if (res?.data?.length > 0) {
                    if (imgType == "cover") {
                        submit({ coverPic: res?.data[0] })
                        setCoverPic(res?.data[0])
                    } else {
                        submit({ profilePic: res?.data[0] })
                        setProfilePic(res?.data[0])
                    }
                } else {
                    dispatch(setLoading(false))
                }
            } else {
                showToast(res?.msg, "error")
                dispatch(setLoading(false))
            }
        } catch (err) {
            dispatch(setLoading(false))
        } finally {

        }
    }

    const submit = async (data) => {
        try {
            dispatch(setLoading(true))
            let res = await hit(`${teams?.createTeam}/${team?._id}`, "patch", data)
            if (res?.err) {
                showToast(res?.msg)
            } else {
                showToast("Updated sucessfully")
                setTimeout(() => {
                    dispatch(getAllTeamThunk())
                }, 400);
            }
        } catch (e) {
        } finally {
            dispatch(setLoading(false))
        }
    }

    const handleFile = async (e, type) => {
        try {
            if (e.target.files[0]) {
                let file = e.target.files[0]
                if (file?.size <= 10000000) {
                    uploadPictures(e.target.files[0], type)
                } else {
                    showToast("Image cannot be more than 10mb", "warning")
                }

            }
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <Box sx={[{ position: "relative", padding: "5px", flex: 1, }, style]}>
            <Box
                sx={{ height: "270px", borderRadius: "5px", width: "100%", objectFit: "cover" }}
                component="img" src={image_url + coverPic}
            />
            {(isAdmin && !isScore) &&
                <input
                    type="file"
                    id="file-selector"
                    accept="image/*"
                    onChange={(e) => handleFile(e, "cover")}
                    style={{ height: "270px", cursor: "pointer", width: "100%", top: 0, position: "absolute", left: 0, opacity: 0, zIndex: 111 }}>
                </input>
            }
            <Avatar
                src={image_url + profilePic}
                sx={{ height: "100px", border: "3px solid white", width: "100px", position: "absolute", left: "41%", top: "220px" }}
            />
            {(isAdmin && !isScore) &&
                <input
                    type="file"
                    id="file-selector1"
                    accept="image/*"
                    onChange={(e) => handleFile(e, "profile")}
                    style={{ height: "100px", border: "3px solid white", width: "100px", cursor: "pointer", position: "absolute", left: "41%", top: "220px", opacity: 0, zIndex: 112 }}>
                </input>
            }
            <Typography
                sx={{ marginTop: "52px", fontWeight: "600", fontSize: pxToRem(24), textAlign: "center" }}>
                {team?.name}
            </Typography>
            <Button sx={{ fontWeight: "400" }} variant="outlined">
                {!isScore ? <Box>
                    <Typography sx={{ fontWeight: "600", fontSize: pxToRem(18) }}>{isAdmin ? avgPoint : yourPoint}</Typography>
                    <Typography sx={{ color: "black", fontSize: pxToRem(10) }}>{isAdmin ? "Average Points" : "Total Points"}</Typography>
                </Box> :
                    <Box>
                        <Typography sx={{ fontWeight: "600", fontSize: pxToRem(18) }}>{lodash.round(fin, 0)}{(!isAdmin || adminView == "User")}</Typography>
                        <Typography sx={{ color: "black", fontSize: pxToRem(10) }}>{(isAdmin ? adminView == "User" ? "Total" : "Average" : "Total") + " Points"}</Typography>
                    </Box>}
            </Button>
        </Box>
    )
}