import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { pxToRem } from '../theme/typography';
import Input from './Login/Input';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "25vw",
    minHeight: "15vh",
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: "5px",
    p: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
};

export default function ScoreAccessDeniedModal({ open, setOpen ,detail,onPressOk}) {
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [reason, setReason] = React.useState("")

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box sx={{ display: "flex", justifyContent: "space-between",width:"100%", alignItems: "center" }}>
                        <Box sx={{width:"20px"}} />
                        <Box onClick={()=>{
                            handleClose()
                        }} sx={{width:"20px",height:"20px",cursor:"pointer"}} component="img" src='assets/android-cancel.png' />
                    </Box>
                    <Box sx={{ flex: 1, minHeight: "100px" }}>
                        <Box sx={{ display: "flex", py: "20px", marginTop: "10px", flexDirection: "column", background: "white", borderRadius: "5px", minHeight: "100px" }}>
                           <Box component={"img"} src="assets/modal/denied.gif" sx={{height:"90px",marginX:"auto",width:"90px"}} />
                            <Typography sx={{ fontSize: pxToRem(18) ,fontWeight:"600",textAlign:"center"}}>
                                Request denied
                            </Typography>
                            <Typography sx={{ fontSize: pxToRem(14) ,marginTop:"10px",textAlign:"center"}}>
                            {(detail?.body)?.split(" ")[0]}'s request has been denied.
                            </Typography>
                            <br/>
                                <Input
                                    multiline={true}
                                    rows={4}
                                    title="Please mention the reason to deny this user."
                                    inputContainerStyle={{height:"100px"}}
                                    placeholder="Type your reason..."
                                    value={reason}
                                    onChange={v=>setReason(v)}
                                />
                           
                            <br />
                            <Button onClick={()=>{
                            onPressOk(reason.trim(), detail)
                            setReason("")
                        }} sx={{ fontSize: pxToRem(14), fontWeight: "400", marginTop: "5px", borderRadius: "5px", marginX: "auto", height: "45px", width: "300px" }} variant="contained">
                               Send
                            </Button>
                        </Box>
                        <br />
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}