import { createSlice } from "@reduxjs/toolkit";
import hit from "../services/apiManager";
import { notifications } from "../services/endpoints";

const notificationSlice = createSlice({
    name: "notification",
    initialState: { list: [], load: false, page: 1, limit: 10, totalResults: 0 },
    reducers: {
        setNotifications: (state, action) => {
            if (action.payload?.page == 1) {
                state.list = action.payload.data
            } else {
                let notilistdata = state.list.slice(0, ((action.payload?.page - 1) * 10) + 1)
                state.list = [...notilistdata, ...action.payload.data]
            }

        },
        setNotiLoad: (state, action) => {
            state.load = action.payload
        },
        setDataNoti: (state, action) => {
            state[`${action.payload.key}`] = action.payload.value
        }

    }
})

export const { setNotifications, setNotiLoad, setDataNoti } = notificationSlice.actions

export const getAllNotifications = (limit = 10, page = 1) => {
    return async (dispatch) => {
        try {

            let res = await hit(notifications + `/paginate?limit=${limit}&page=${page}`, "get")
            if (res?.data) {
                dispatch(setNotifications({ data: res?.data?.results, page }))
                dispatch(setDataNoti({ key: "page", value: page }))
                dispatch(setDataNoti({ key: "limit", value: limit }))
                dispatch(setDataNoti({ key: "totalResults", value: res?.data?.totalResults }))
            }
        } catch (err) {
            console.error(err)
        } finally {
            dispatch(setNotiLoad(false))
        }
    }
}

export default notificationSlice.reducer

