import { createSlice } from "@reduxjs/toolkit";

const quizedSlice=createSlice({
    name:"savedQuizes",
    initialState:{
        list:[],
        totalPage:0,
        count:1
    },
    reducers:{
        setQuizSavedStateData:(state,action)=>{
            state[`${action.payload.key}`]=action.payload.value
        },
        clearQuizSavedtData:(state,action)=>{
            return {
                list:[],
                totalPage:0,
                count:1
            }
        }
    }
})

export const {setQuizSavedStateData,clearQuizSavedtData}=quizedSlice.actions

export default quizedSlice.reducer