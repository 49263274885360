import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { pxToRem } from '../theme/typography';
import Input from './Login/Input';
import showToast from '../utils/showToast';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "25vw",
    minHeight: "15vh",
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: "5px",
    p: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
};

export default function AddLinkModal({ open, setOpen, onSubmit }) {
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [title, setTitle] = React.useState("")
    const [link, setLink] = React.useState("")
    const isValidUrl = urlString=> {
        try { 
            return Boolean(new URL(urlString)); 
        }
        catch(e){ 
            return false; 
        }
    }
    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", width: "100%", alignItems: "center" }}>
                        <Typography sx={{ fontSize: pxToRem(16), marginTop: "10px", fontWeight: "600" }}>Add HyperLink</Typography>
                    </Box>
                    <Box sx={{ flex: 1, minHeight: "100px" }}>
                        <Box sx={{ display: "flex", py: "20px", marginTop: "10px", flexDirection: "column", background: "white", borderRadius: "5px", minHeight: "100px" }}>
                            <Input value={title} onChange={v => setTitle(v)} title={"Title"} />
                            <Input value={link} onChange={v => setLink(v)} containerStyle={{ marginTop: "20px" }} title={"Link"} />
                            <br />
                            <Button onClick={() => {
                                if(title?.trim()==""){
                                    showToast("Please add title","warning")
                                    return
                                }
                                if(link?.trim()==""){
                                    showToast("Please add link","warning")
                                    return
                                }
                                if(!isValidUrl(link)){
                                    showToast("Please enter valid link","warning")
                                    return
                                }
                                onSubmit({ title, link })
                            }} sx={{ fontSize: pxToRem(14), fontWeight: "400", marginTop: "5px", borderRadius: "5px", marginX: "auto", height: "45px", width: "300px" }} variant="contained">
                                Submit
                            </Button>
                        </Box>
                        <br />
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}