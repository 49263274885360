import { Box, Button, TextField } from "@mui/material";

export default function SearchBar({placeholder,value,onChange,onClickSearch}) {
    return (
        <Box sx={{ height: "45px",display:"flex",px:"2px",borderRadius:"5px",minWidth:"100px",alignItems:"center",background:"white",boxShadow:"0px 5px 20px #26262626" }}>
            <TextField variant="standard"
                placeholder={placeholder}
                value={value}
                onChange={e=>{
                    onChange(e.target.value)
                }}
                InputProps={{
                    disableUnderline: true,
                    justifyContent: 'center',
                    sx: [{ fontSize: "14px", fontWeight: "500" }],

                }} sx={{ borderColor: "white", display: "flex", flex: 1 ,px:"5px"}} />
                <Box onClick={()=>{
                    if(onClickSearch){
                        onClickSearch()
                    }
                }} sx={{background:"rgb(9,178,208)",height:"42px",width:"42px",cursor:"pointer",borderRadius:"5px",display:"flex",justifyContent:"center",alignItems:"center"}}>
                    <Box component={"img"} src="assets/home/search.svg" sx={{height:"15px",width:"15px",objectFit:"contain"}} />
                </Box>
        </Box>
    )
}