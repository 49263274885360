import { Box, Button, Typography } from "@mui/material";
import Input from "../components/Login/Input";
import { pxToRem } from "../theme/typography";
import '../css/signup.css';
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { setLoading } from '../store/load'
import hit from '../services/apiManager'
import showToast from '../utils/showToast'
import { auth } from "../services/endpoints";
export default function ForgotPassword() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [email, setEmail] = useState("")

    const submit = async () => {
        try {
            dispatch(setLoading(true))
            let res = await hit(auth.forgotpass, "post", { email })
            if (res?.err) {
                showToast(res?.msg, "error")
            } else {
                showToast("Otp send on your email", "success")
                navigate("/fotp", { state: { from: "forgotpass", user: email } })
            }
        } catch (e) {
        } finally {
            dispatch(setLoading(false))
        }
    }
    return (
        <Box sx={{ width: "100vw", position: "relative", height: "100vh", justifyContent: "center", alignItems: "center", display: "flex" }}>
            <Box component={"img"} src="assets/login/fullback.png" sx={{ width: "100vw", position: "absolute", top: 0, height: "100vh", objectFit: "cover" }} />
            <Box component={"img"} src="assets/login/fullback1.svg" sx={{ width: "100vw", position: "absolute", top: 0, height: "100vh", objectFit: "cover" }} />
            <Box className="noScrollBar" sx={{ width: { xs: "90vw", md: "40vw" }, overflowY: "auto", scrollbarWidth: "none", minWidth: { md: "550px" }, borderRadius: "10px", position: "relative", backgroundImage: "linear-gradient(150deg,rgb(212,241,247), white ,rgb(254,243,214))" }}>
                <Box sx={{ height: "100%", width: "100%", paddingY: "7%", paddingX: { xs: "5%", md: "12%" }, position: "relative" }}>
                    <Box component={"img"} src="assets/logo.png" sx={{ width: { md: "200px", sm: "200px" }, mx: "auto", height: "60px", objectFit: "contain" }} />
                    <Typography sx={{ fontSize: pxToRem(26), fontWeight: "700", marginTop: "10px" }}>Forgot Password</Typography>
                    <Typography sx={{ fontSize: pxToRem(16), fontWeight: "500", marginTop: "4px", paddingX: "10%" }}>Enter your email address to reset your password.</Typography>
                    <br />
                    <Box sx={{ width: "90%", marginX: "auto" }}>
                        <Input value={email} placeholder="Email" onChange={v => setEmail(v)} titleStyle={{ textAlign: "left" }} title={"Email"} img="assets/login/mail.svg" />
                        <br />
                        <br />
                        <Button onClick={() => {
                            submit()
                        }} sx={{ borderRadius: "5px", height: "50px", marginTop: "16px", width: "80%" }} variant="contained" >
                            Continue
                        </Button>
                        <br />
                    </Box>
                </Box>
                <Box onClick={() => {
                    navigate(-1)
                }} component={"img"} src="assets/arrow-left.png" style={{ height: "25px", width: "25px", position: "absolute", top: 20, left: 20 }} />
            </Box>
        </Box >
    )
}

