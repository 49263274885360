import { Avatar, Badge, Box, Button, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Input from "../components/Login/Input";
import { pxToRem } from "../theme/typography";
import { QRCodeCanvas } from "qrcode.react";
import showToast from "../utils/showToast";

export default function MyAccessCode() {
    const navigate = useNavigate()
    const user = useSelector(state => state.auth?.user)
    return (
        <Box sx={{ px: "5%", pt: "20px", display: "flex", pr: "10%", overflowY: "auto", flex: 1, width: "100%", background: "linear-gradient(119deg,#E6F6F9,white,rgb(253,243,213))" }}>
            <Box sx={{ flex: 1, minHeight: "100px" }}>
                <Box sx={{ display: "flex", px: "10px", py: "20px", marginTop: "10px", flexDirection: "column", background: "white", boxShadow: "0px 5px 20px #26262626", borderRadius: "5px", minHeight: "100px" }}>
                    <Typography sx={{ fontSize: pxToRem(14) ,marginBottom:"15px"}}>
                    Here is your unique access code that anyone can use to add you as a member by copying and pasting the code or scanning the QR code.
                    </Typography>
                    {/* <Box component={"img"} src="assets/access/qr.png" sx={{ height: "200px", objectFit: "contain" }} /> */}
                    <QRCodeCanvas
                        id="qrCode"
                        value={user?.accessCode}
                        size={200}
                        bgColor={"white"}
                        level={"H"}
                        style={{ marginTop: "20px", marginBottom: "10px", margin: "auto" }}
                    />
                    <Box sx={{ width: "80%", marginX: "auto", marginTop: "20px" }}>
                        <Typography sx={{ fontWeight: "400", marginLeft: "5px", fontSize: pxToRem(10), fontWeight: "600", textAlign: "left" }}>Access Code</Typography>
                        <Box sx={{ height: "45px", display: "flex", alignItems: "center", marginTop: "5px", borderRadius: "5px", background: "rgb(232,232,232)" }}>
                            <Typography sx={{ fontWeight: "500", flex: 1, textAlign: "left", fontWeight: "500", paddingLeft: "10px" }}>{user?.accessCode}</Typography>
                            <Box onClick={() => {
                                if (navigator?.clipboard) {
                                    navigator.clipboard.writeText(user?.accessCode).then(() => {
                                        showToast('Access code copied to clipboard');
                                        /* Resolved - text copied to clipboard successfully */
                                    }, () => {
                                        console.error('Failed to copy');
                                        /* Rejected - text failed to copy to the clipboard */
                                    });
                                } else {
                                    showToast('Clipboard not supported', "error");

                                }
                            }} sx={{ height: "45px", cursor: "pointer", width: "100px", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "5px", background: "#F5BA16" }}>
                                <Typography sx={{ color: "white", fontSize: pxToRem(14), fontWeight: "500" }}>Copy Code</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <br />
                    <br />
                    {Boolean(navigator.share) && <Button onClick={() => {
                        // navigate(-1)
                        if (navigator.share) {
                            navigator.share({
                                title: 'User Access Code',
                                url: user?.accessCode
                            }).then(() => {
                                console.log('Thanks for sharing!');
                            }).catch(console.error);
                        } else {
                            showToast('Share not supported', "error");

                        }
                    }} sx={{ fontSize: pxToRem(16), fontWeight: "400", marginTop: "5px", borderRadius: "5px", marginX: "auto", height: "45px", width: "250px" }} variant="contained">
                        Share
                    </Button>}
                    <br />
                </Box>
                <br />
            </Box>
            <Box sx={{ flex: 1, marginLeft: "5%", }}>
            </Box>
        </Box>
    )
}

