import { Box, Typography } from "@mui/material";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import SideNav from "./SideNav";

export default function DashboardLayout() {
    return (
        <Box sx={{}}>
            <Box sx={{ display: "flex", height: "95vh" }}>
                {/* side drawer layout */}
                <SideNav />
                <Box sx={{ height: "100%", flex: 1 ,display:"flex",flexDirection:"column"}}>
                    {/* header layout */}
                    <Header />
                    <Outlet />
                </Box>
            </Box>
            {/* footer data */}
            <Footer />
        </Box>
    )
}