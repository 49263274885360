import { Avatar, Badge, Box, Button, Typography } from "@mui/material";
import { minHeight } from "@mui/system";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import SearchBar from "../components/Home/SearchBar";
import EnterTime from "../components/EnterTime";
import { pxToRem } from "../theme/typography";
import DenyReasonModal from "../components/DenyReasonModal";
import Input, { DatePickerInput } from "../components/Login/Input";
import { setPostData } from "../store/post";
import showToast from "../utils/showToast";
import DatePickerModal from "../components/DatePickerModal";
import moment from "moment";
import TimePickerModal from "../components/TimePickerModal";
import { image_url } from "../services/endpoints";
import lodash from 'lodash'
export default function Post1() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const post = useSelector(state => state.post)
    const location = useLocation()
    const [openDate, setOpenDate] = useState(false)
    const [openDate1, setOpenDate1] = useState(false)
    const [showScoring, setShowScoring] = useState(true)
    const colors = [
        "#1F5FC8",
        "rgb(71,38,125)",
        "rgb(232,51,65)",
        "rgb(219,65,166)",
        "rgb(218,84,31)",
        "rgb(3,110,67)",
        "rgb(21,69,115)",
        "rgb(33,33,35)"
    ]
    const [selectedType, setSelectedType] = useState("")
    useEffect(() => {
        if (location?.state?.groupDetail?._id) {
            if (location?.state?.isAdmin) {
                setShowScoring(true)
            } else {
                setShowScoring(false)
            }
        }
    }, [location])
    return (
        <Box sx={{ px: "5%", pt: "20px", display: "flex", pr: "10%", overflowY: "auto", flex: 1, width: "100%", background: "linear-gradient(119deg,#E6F6F9,white,rgb(253,243,213))" }}>
            <Box sx={{ flex: 1, minHeight: "100px" }}>
                <Box sx={{ display: "flex", minHeight: "570px", px: "10px", py: "20px", marginTop: "10px", flexDirection: "column", background: "white", boxShadow: "0px 5px 20px #26262626", borderRadius: "5px", }}>
                    {(!post?.isThemedPost && !post?.isPollPost && !post.isQuizPost) && <Input value={post?.txt} placeholder="Write Something here..." onChange={v => dispatch(setPostData({ key: "txt", value: v }))} multiline={true} rows={6} inputContainerStyle={{ height: "140px", background: "#EBEBEB" }} />}
                    {post?.isThemedPost &&
                        <>
                            <Input multiline={true} value={post?.txt} placeholder="Write Something here..." onChange={v => dispatch(setPostData({ key: "txt", value: v }))} inputStyle={{ color: "white", fontSize: pxToRem(20), textAlign: "center" }} rows={5} inputContainerStyle={{ height: "140px", background: post?.themeColor }} />
                            <Box sx={{ display: "flex", alignItems: "center", marginTop: "20px" }}>
                                {colors.map((x, i) => {
                                    return (
                                        <Box onClick={() => {
                                            dispatch(setPostData({ key: "themeColor", value: x }))
                                        }} sx={{ height: "25px", cursor: "pointer", marginRight: "10px", width: "25px", borderRadius: "25px", background: x }}>
                                        </Box>
                                    )
                                })}
                            </Box>
                        </>
                    }
                    {post?.isPollPost &&
                        <>
                            <Input multiline={true} value={post?.txt} onChange={v => dispatch(setPostData({ key: "txt", value: v }))} rows={3} placeholder="Write Something here..." inputContainerStyle={{ height: "80px", background: "#EBEBEB" }} />
                            {
                                post?.pollOptions?.map((x, i) => {
                                    return (
                                        <Input value={x} onChange={v => {
                                            let newarr = [...post?.pollOptions]
                                            newarr[i] = v
                                            dispatch(setPostData({ key: "pollOptions", value: newarr }))
                                        }} placeholder={"Options " + (i + 1)} containerStyle={{ marginTop: "10px" }} inputContainerStyle={{ background: "#EBEBEB" }} />
                                    )
                                })
                            }
                            <Box onClick={() => {
                                console.log(">>>>>>>>",post?.pollOptions)
                                if (post?.pollOptions?.filter(x => x?.trim() == "")?.length > 0) {
                                    showToast("Please write options before adding", "warning")
                                    return
                                }
                                dispatch(setPostData({ key: "pollOptions", value: [...post?.pollOptions].concat("")}))
                                // dispatch(setPostData({ key: "pollOptions", value: [...post?.pollOptions].concat({ id: (post?.pollOptions?.length + 1), v: "" }) }))
                            }} sx={{ display: "flex", alignItems: "center", marginTop: "10px", cursor: "pointer" }}>
                                <Typography color={"primary"} fontSize={"20px"} fontWeight="600">+</Typography>
                                <Typography color={"primary"} fontWeight="600">Add Another Option</Typography>
                            </Box>
                            <Box sx={{ display: "flex", textAlign: "left", marginTop: "10px" }}>
                                <DatePickerInput onClick={() => {
                                    setOpenDate(true)
                                    setSelectedType("poll")
                                }} inputContainerStyle={{ background: "#EBEBEB" }} title={"Poll End Date"} value={moment(post?.pollEndTime).format("MMM, DD, YYYY")} containerStyle={{ flex: 1 }} />
                                <DatePickerInput onClick={() => {
                                    setOpenDate1(true)
                                    setSelectedType("poll")
                                }} inputContainerStyle={{ background: "#EBEBEB" }} title={"Polls End Time"} value={moment(post?.pollEndTime).format("hh:mm A")} containerStyle={{ flex: 1, marginLeft: "4%" }} />

                                {/* <Input  inputContainerStyle={{ background: "#EBEBEB" }} title={"Poll End Date"} value="Wed, Nov 30" containerStyle={{ flex: 1 }} />
                                <Input inputContainerStyle={{ background: "#EBEBEB" }} title={"Polls End Time"} value="11:00 AM" containerStyle={{ flex: 1, marginLeft: "15px" }} /> */}
                            </Box>
                        </>
                    }
                    {post?.isQuizPost &&
                        <>
                            <Input value={post?.quizTitle} onChange={v => dispatch(setPostData({ key: "quizTitle", value: v }))} placeholder="Quiz Title" inputContainerStyle={{ background: "#EBEBEB" }} />
                            <Input multiline={true} value={post?.quizDesc} onChange={v => dispatch(setPostData({ key: "quizDesc", value: v }))} rows={3} placeholder="Description" inputContainerStyle={{ height: "80px", marginTop: "10px", background: "#EBEBEB" }} />
                            {post?.quizData?.map((x, i) => {
                                return (
                                    <>
                                        <Input multiline={true} value={x?.qn} onChange={v => {
                                            let newarr = lodash.cloneDeep([...post?.quizData])
                                            newarr[i].qn = v
                                            dispatch(setPostData({ key: "quizData", value: newarr }))
                                        }} rows={3} placeholder="Here is the question" containerStyle={{ marginTop: "10px", }} inputContainerStyle={{ height: "80px", background: "#EBEBEB" }} />
                                        <Box display={"flex"} marginTop={"10px"}>
                                            {x?.photos?.map((x, id) => {
                                                return <Box sx={{ position: "relative", marginRight: "10px" }}>
                                                    <Box component={"img"} sx={{ height: "150px", width: "150px", borderRadius: "10px", border: "1px solid", borderColor: "rgb(16,176,212)", objectFit: "cover" }} src={typeof x === 'string' ? image_url + x : URL.createObjectURL(x)} />
                                                    <Box
                                                        onClick={() => {
                                                            let newarr = [...post?.quizData]
                                                            let currentQ = lodash.cloneDeep(newarr[i])
                                                            let photos = lodash.cloneDeep(newarr[i].photos ?? [])
                                                            currentQ.photos = photos.filter((x, ind) => ind != id)
                                                            newarr[i] = currentQ
                                                            dispatch(setPostData({ key: "quizData", value: newarr }))
                                                        }}
                                                        component={"img"} sx={{ height: "25px", cursor: "pointer", width: "25px", marginLeft: "5px", position: "absolute", top: 0, right: 0 }} src="assets/teams/delete.svg" />

                                                </Box>
                                            })}
                                            <Box onClick={() => {
                                            }} sx={{ cursor: "pointer", height: "80px", width: "80px", position: "relative", borderRadius: "5px", display: "flex", background: "#E6F7FB", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                                <Box component={"img"} sx={{ height: "25px", width: "25px" }} src="assets/posts/camera.svg" />
                                                <Typography sx={{ fontSize: pxToRem(14), marginTop: "5px", fontWeight: "600" }}>Photos</Typography>
                                                <input type="file" id="file-selector" accept="image/*" onChange={(e) => {
                                                    let totals = x?.photos?.length
                                                    if (totals >= 2) {
                                                        showToast("Maximum 2 files allowed", "warning")
                                                        return
                                                    }
                                                    if (e.target.files[0]) {
                                                        let file = e.target.files[0]
                                                        if (file?.size <= 10000000) {
                                                            let newarr = [...post?.quizData]
                                                            let currentQ = lodash.cloneDeep(newarr[i])
                                                            let photos = lodash.cloneDeep(newarr[i].photos ?? [])
                                                            photos.push(e.target.files[0])
                                                            currentQ.photos = photos
                                                            newarr[i] = currentQ
                                                            dispatch(setPostData({ key: "quizData", value: newarr }))
                                                        } else {
                                                            showToast("Image cannot be more than 10mb", "warning")
                                                        }
                                                    }
                                                }} style={{ height: "80px", width: "100%", marginX: "auto", top: 0, position: "absolute", left: 0, opacity: 0, zIndex: 111 }}></input>
                                            </Box>
                                        </Box>
                                        {x?.options?.map((op, id) => {
                                            let isChecked = op == x.ansKey && x.ansKey?.trim() != ""
                                            return (
                                                <Box display={"flex"} alignItems="center" marginTop={"10px"}>
                                                    <Input value={op} onChange={v => {
                                                        let newarr = [...post?.quizData]
                                                        let currentQ = lodash.cloneDeep(newarr[i])
                                                        let options = lodash.cloneDeep(newarr[i].options)
                                                        options[id] = v
                                                        currentQ.options = options
                                                        newarr[i] = currentQ
                                                        dispatch(setPostData({ key: "quizData", value: newarr }))
                                                    }} rightText={
                                                        <Box onClick={() => {
                                                            if (op?.trim() == "") {
                                                                return
                                                            }
                                                            let newarr = lodash.cloneDeep([...post?.quizData])
                                                            newarr[i].ansKey = op
                                                            dispatch(setPostData({ key: "quizData", value: newarr }))
                                                        }} component={"img"} sx={{ cursor: "pointer", height: "18px", width: "18px" }} src={isChecked ? "assets/groups/ios-checkbox.svg" : "assets/groups/ios-checkbox-outline.svg"} />

                                                    } placeholder={"Options " + (id + 1)} containerStyle={{ flex: 1 }} inputContainerStyle={{ background: "#EBEBEB" }} />
                                                    {post?.quizData[i]["options"]?.length > 1 && <Box onClick={() => {
                                                        let newarr = lodash.cloneDeep([...post?.quizData])
                                                        newarr[i]["options"] = newarr[i]["options"].filter((o, ind) => ind != id)
                                                        dispatch(setPostData({ key: "quizData", value: newarr }))
                                                    }} component={"img"} sx={{ cursor: "pointer", marginLeft: "5px" }} src="assets/library/delete.svg" />}
                                                </Box>
                                            )
                                        })}
                                        <Box onClick={() => {
                                            for (let q of post?.quizData) {
                                                if (q?.options?.filter(x => x?.trim() == "")?.length > 0) {
                                                    showToast("Please write options before adding another option", "warning")
                                                    return
                                                }
                                            }
                                            let newarr = lodash.cloneDeep([...post?.quizData])
                                            newarr[i]["options"] = newarr[i]["options"].concat([""])
                                            dispatch(setPostData({ key: "quizData", value: newarr }))
                                        }} sx={{ display: "flex", alignItems: "center", marginTop: "10px", cursor: "pointer" }}>
                                            <Typography color={"primary"} fontSize={"20px"} fontWeight="600">+</Typography>
                                            <Typography color={"primary"} fontWeight="600">Add Option</Typography>
                                        </Box>
                                        <PointInput value={x?.point} onChange={v => {
                                            let newArr = lodash.cloneDeep([...post.quizData])
                                            newArr[i].point = v
                                            dispatch(setPostData({ key: "quizData", value: newArr }))
                                        }} title={"How many points people can score from this Question?"} />

                                    </>
                                )
                            })}
                            <Box display={"flex"} alignItems="center" marginTop={"10px"} justifyContent={"space-between"}>
                                <Box onClick={() => {
                                    if (post?.quizData?.filter(x => x?.qn?.trim() == "")?.length > 0) {
                                        showToast("Please write question before adding another question", "warning")
                                        return
                                    }
                                    if (post?.quizData?.filter(x => x?.ansKey?.trim() == "" || x?.ansKey == undefined)?.length > 0) {
                                        showToast("Please select answer before adding another question", "warning")
                                        return
                                    }
                                    for (let q of post?.quizData) {
                                        if (q?.options?.filter(x => x?.trim() == "")?.length > 0) {
                                            showToast("Please write options before adding another question", "warning")
                                            return
                                        }
                                    }
                                    for (let q of post?.quizData) {
                                        if (q?.point == "" || q?.point == 0) {
                                            showToast("Please add point before adding another question", "warning")
                                            return
                                        }
                                    }
                                    dispatch(setPostData({ key: "quizData", value: [...post?.quizData].concat({ qn: "", ansKey: "", photos: [], options: ["", ""], point: 1 }) }))
                                }} sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
                                    <Typography color={"primary"} fontSize={"20px"} fontWeight="600">+</Typography>
                                    <Typography color={"primary"} fontWeight="600">Add Question</Typography>
                                </Box>
                                {post?.quizData?.length > 0 && <Box onClick={() => {
                                    let newArr = lodash.cloneDeep(post?.quizData)
                                    newArr.pop()
                                    dispatch(setPostData({ key: "quizData", value: newArr }))
                                }} sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
                                    <Typography color={"error"} fontWeight="600">Remove Question</Typography>
                                </Box>}
                            </Box>
                            <PointInput value={post?.quizScoreCount} showPoints={false} onChange={v => {
                                // let newArr = lodash.cloneDeep([...post.quizData])
                                // newArr[i].point = v
                                dispatch(setPostData({ key: "quizScoreCount", value: v }))
                            }} title={"How many times may a person take the quiz to improve their score?"} />
                        </>
                    }

                    <br />

                    <Box sx={{ display: "flex" }}>
                        <Box onClick={() => {
                            dispatch(setPostData({ key: "isPollPost", value: false }))
                            dispatch(setPostData({ key: "isThemedPost", value: false }))
                            dispatch(setPostData({ key: "isQuizPost", value: false }))
                        }} sx={{ flex: 1, cursor: "pointer", height: "80px", position: "relative", borderRadius: "5px", display: "flex", background: "#E6F7FB", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                            <Box component={"img"} sx={{ height: "25px", width: "25px" }} src="assets/posts/camera.svg" />
                            <Typography sx={{ fontSize: pxToRem(14), marginTop: "5px", fontWeight: "600" }}>Photos</Typography>
                            <input type="file" id="file-selector" accept="image/*" onChange={(e) => {
                                let totals = post?.photos?.length + post?.videos?.length
                                if (totals >= 3) {
                                    showToast("Maximum 3 files allowed", "warning")
                                    return
                                }
                                if (e.target.files[0]) {
                                    let file = e.target.files[0]
                                    if (file?.size <= 10000000) {
                                        dispatch(setPostData({ key: "photos", value: [...post?.photos, e.target.files[0]] }))
                                    } else {
                                        showToast("Image cannot be more than 10mb", "warning")
                                    }
                                }
                            }} style={{ height: "80px", width: "100%", marginX: "auto", top: 0, position: "absolute", left: 0, opacity: 0, zIndex: 111 }}></input>
                        </Box>
                        <Box onClick={() => {
                            dispatch(setPostData({ key: "isThemedPost", value: false }))
                            dispatch(setPostData({ key: "isPollPost", value: false }))
                            dispatch(setPostData({ key: "isQuizPost", value: false }))
                        }} sx={{ flex: 1, cursor: "pointer", position: "relative", height: "80px", borderRadius: "5px", display: "flex", marginLeft: "10px", background: "#E6F7FB", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                            <Box component={"img"} sx={{ height: "25px", width: "25px" }} src="assets/posts/videos.svg" />
                            <Typography sx={{ fontSize: pxToRem(14), marginTop: "5px", fontWeight: "600" }}>Videos</Typography>
                            <input type="file" id="file-selector" accept="video/*" onChange={(e) => {
                                let totals = post?.photos?.length + post?.videos?.length
                                if (totals >= 3) {
                                    showToast("Maximum 3 files allowed", "warning")
                                    return
                                }
                                if (e.target.files[0]) {
                                    let file = e.target.files[0]
                                    if (file?.size <= 50000000) {
                                        dispatch(setPostData({ key: "videos", value: [...post?.videos, e.target.files[0]] }))
                                    } else {
                                        showToast("Video cannot be more than 50 MB", "warning")
                                    }
                                }
                            }} style={{ height: "80px", width: "100%", marginX: "auto", top: 0, position: "absolute", left: 0, opacity: 0, zIndex: 111 }}></input>

                        </Box>
                        <Box onClick={() => {
                            dispatch(setPostData({ key: "isThemedPost", value: true }))
                            dispatch(setPostData({ key: "isPollPost", value: false }))
                            dispatch(setPostData({ key: "isQuizPost", value: false }))
                        }} sx={{ flex: 1, cursor: "pointer", height: "80px", borderRadius: "5px", display: "flex", marginLeft: "10px", background: "#E6F7FB", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                            <Box component={"img"} sx={{ height: "25px", width: "20px" }} src="assets/posts/color.svg" />
                            <Typography sx={{ fontSize: pxToRem(14), marginTop: "5px", fontWeight: "600" }}>Themed Post</Typography>
                        </Box>
                        <Box onClick={() => {
                            dispatch(setPostData({ key: "isThemedPost", value: false }))
                            dispatch(setPostData({ key: "isPollPost", value: true }))
                            dispatch(setPostData({ key: "isQuizPost", value: false }))
                        }} sx={{ flex: 1, cursor: "pointer", height: "80px", borderRadius: "5px", marginLeft: "10px", display: "flex", background: "#E6F7FB", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                            <Box component={"img"} sx={{ height: "25px", width: "20px" }} src="assets/posts/poll.svg" />
                            <Typography sx={{ fontSize: pxToRem(14), marginTop: "5px", fontWeight: "600" }}>Poll</Typography>
                        </Box>
                        <Box onClick={() => {
                            dispatch(setPostData({ key: "isThemedPost", value: false }))
                            dispatch(setPostData({ key: "isPollPost", value: false }))
                            dispatch(setPostData({ key: "isQuizPost", value: true }))
                            dispatch(setPostData({ key: "isScorePost", value: false }))
                        }} sx={{ flex: 1, cursor: "pointer", height: "80px", borderRadius: "5px", marginLeft: "10px", display: "flex", background: post?.isQuizPost ? "#10B0D4" : "#E6F7FB", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                            <Box component={"img"} sx={{ height: "25px", width: "20px", objectFit: "contain" }} src={post?.isQuizPost ? "assets/posts/quiz.svg" : "assets/posts/quiz1.png"} />
                            <Typography sx={{ fontSize: pxToRem(14), marginTop: "5px", fontWeight: "600", color: post?.isQuizPost ? "white" : "black" }}>Quiz</Typography>
                        </Box>
                    </Box>
                    <br />
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        {post?.photos?.map((x, i) => {
                            return <Box sx={{ position: "relative" }}>
                                <Box component={"img"} sx={{ height: "150px", width: "150px", borderRadius: "10px", border: "1px solid", borderColor: "rgb(16,176,212)", objectFit: "cover" }} src={typeof x === 'string' ? image_url + x : URL.createObjectURL(x)} />
                                <Box
                                    onClick={() => {
                                        dispatch(setPostData({ key: "photos", value: post?.photos?.filter((v, j) => j != i) }))
                                    }}
                                    component={"img"} sx={{ height: "25px", cursor: "pointer", width: "25px", marginLeft: "5px", position: "absolute", top: 0, right: 0 }} src="assets/teams/delete.svg" />

                            </Box>
                        })}
                        {post?.videos?.map((x, i) => {
                            return <Box sx={{ position: "relative" }}>
                                <Box component={"video"} sx={{ height: "150px", width: "150px", borderRadius: "10px", border: "1px solid", borderColor: "rgb(16,176,212)", objectFit: "cover" }} src={typeof x === 'string' ? image_url + x : URL.createObjectURL(x)} />
                                <Box onClick={() => {
                                    dispatch(setPostData({ key: "videos", value: post?.videos?.filter((v, j) => j != i) }))
                                }} component={"img"} sx={{ height: "25px", cursor: "pointer", width: "25px", marginLeft: "5px", position: "absolute", top: 0, right: 0 }} src="assets/teams/delete.svg" />

                            </Box>
                        })}
                    </Box>
                    <br />
                    {post?.attachments?.map(x => {
                        return <Box sx={{ display: "flex", marginBottom: "5px", position: "relative", alignItems: "center", alignSelf: "flex-start" }}>
                            <Box component={"img"} sx={{ objectFit: "contain", height: "20px", width: "20px" }} src="assets/library/doc.svg" />
                            <Typography sx={{ fontWeight: "600", fontSize: pxToRem(14), marginLeft: "5px", textAlign: "justify" }}>{x?.name ?? x?.fileName}</Typography>
                            <Box onClick={() => {
                                let attachments = post.attachments.filter(pf => pf.name !== x?.name)
                                dispatch(setPostData({ key: "attachments", value: attachments }))
                            }} sx={{ height: "20px", marginLeft: "10px", width: "20px", display: "flex", justifyContent: "center", alignItems: "center", background: "rgb(254,90,89)", borderRadius: "60px" }}>
                                <Box component={"img"} sx={{ objectFit: "contain" }} src="assets/library/delete.svg" />
                            </Box>
                        </Box>
                    })}
                    <Box sx={{ display: "flex", marginTop: "10px", position: "relative", alignItems: "center", alignSelf: "flex-start" }}>
                        <Box component={"img"} src="assets/chat/attach.svg" />
                        <Typography color={"primary"} sx={{ fontWeight: "600", fontSize: pxToRem(14), marginLeft: "10px" }}>Add Attachment</Typography>
                        <input type="file" id="file-selector" accept="application/*" onChange={(e) => {
                            let totals = post?.attachments?.length
                            if (totals >= 2) {
                                showToast("Maximum 2 file allowed", "warning")
                                return
                            }
                            if (e.target.files[0]) {
                                let file = e.target.files[0]
                                if (file?.size <= 10000000) {
                                    dispatch(setPostData({ key: "attachments", value: [...post?.attachments, e.target.files[0]] }))
                                } else {
                                    showToast("file cannot be more than 10mb", "warning")

                                }
                            }
                        }} style={{ height: "50px", width: "200px", marginX: "auto", top: 0, background: "red", position: "absolute", left: 0, opacity: 0, zIndex: 111 }}></input>
                    </Box>
                    <br />
                    {(showScoring && !post?.isQuizPost) && <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Checks isChecked={post?.isScorePost} setIsChecked={v => {
                            dispatch(setPostData({ key: "isScorePost", value: v }))
                            dispatch(setPostData({ key: "scoreStartTime", value: moment() }))
                            dispatch(setPostData({ key: "scoreEndTime", value: moment().add(24, "hour") }))
                        }} />
                        <Typography color={"primary"} sx={{ fontWeight: "600", fontSize: pxToRem(14), marginLeft: "10px" }}>This is a Scoring Post</Typography>
                    </Box>}

                    {/* Quize */}
                    {post?.isQuizPost &&
                        <>
                            <Box sx={{ display: "flex", textAlign: "left", }}>
                                <DatePickerInput onClick={() => {
                                    setOpenDate(true)
                                    setSelectedType("scoreStart")
                                }} inputContainerStyle={{ background: "#EBEBEB" }} title={"Start Date"} value={moment(post?.scoreStartTime).format("MMM, DD, YYYY")} containerStyle={{ flex: 1 }} />
                                <DatePickerInput onClick={() => {
                                    setOpenDate1(true)
                                    setSelectedType("scoreStart")
                                }} inputContainerStyle={{ background: "#EBEBEB" }} title={"Start Time"} value={moment(post?.scoreStartTime).format("hh:mm A")} containerStyle={{ flex: 1, marginLeft: "4%" }} />
                            </Box>
                            <Box sx={{ display: "flex", textAlign: "left", marginTop: "10px" }}>
                                <DatePickerInput onClick={() => {
                                    setOpenDate(true)
                                    setSelectedType("scoreEnd")
                                }} inputContainerStyle={{ background: "#EBEBEB" }} title={"End Date"} value={moment(post?.scoreEndTime).format("MMM, DD, YYYY")} containerStyle={{ flex: 1 }} />
                                <DatePickerInput onClick={() => {
                                    setOpenDate1(true)
                                    setSelectedType("scoreEnd")
                                }} inputContainerStyle={{ background: "#EBEBEB" }} title={"End Time"} value={moment(post?.scoreEndTime).format("hh:mm A")} containerStyle={{ flex: 1, marginLeft: "4%" }} />

                            </Box>
                        </>
                    }
                    {post?.isScorePost &&
                        <>
                            <Box sx={{ display: "flex", textAlign: "left", marginTop: "10px" }}>
                                <DatePickerInput onClick={() => {
                                    setOpenDate(true)
                                    setSelectedType("scoreStart")
                                }} inputContainerStyle={{ background: "#EBEBEB" }} title={"Start Date"} value={moment(post?.scoreStartTime).format("MMM, DD, YYYY")} containerStyle={{ flex: 1 }} />
                                <DatePickerInput onClick={() => {
                                    setOpenDate1(true)
                                    setSelectedType("scoreStart")
                                }} inputContainerStyle={{ background: "#EBEBEB" }} title={"Start Time"} value={moment(post?.scoreStartTime).format("hh:mm A")} containerStyle={{ flex: 1, marginLeft: "4%" }} />
                            </Box>
                            <Box sx={{ display: "flex", textAlign: "left", marginTop: "10px" }}>
                                <DatePickerInput onClick={() => {
                                    setOpenDate(true)
                                    setSelectedType("scoreEnd")
                                }} inputContainerStyle={{ background: "#EBEBEB" }} title={"End Date"} value={moment(post?.scoreEndTime).format("MMM, DD, YYYY")} containerStyle={{ flex: 1 }} />
                                <DatePickerInput onClick={() => {
                                    setOpenDate1(true)
                                    setSelectedType("scoreEnd")
                                }} inputContainerStyle={{ background: "#EBEBEB" }} title={"End Time"} value={moment(post?.scoreEndTime).format("hh:mm A")} containerStyle={{ flex: 1, marginLeft: "4%" }} />

                            </Box>
                        </>
                    }
                    {!post?.isScorePost && <Button onClick={() => {
                        if (post?.txt?.trim() == "" && !post?.isQuizPost) {
                            showToast("Please write something ", "warning")
                            return
                        }
                        if (post?.isQuizPost) {
                            if (post?.quizData?.length == 0) {
                                showToast("Please add atleast one question before posting", "warning")

                                return
                            }
                            if (post?.quizTitle?.trim() == "") {
                                showToast("Please add quiz title before posting", "warning")
                                return
                            }
                            // if (post?.quizDesc?.trim() == "") {
                            //     showToast("Please add quiz description before posting", "warning")
                            //     return
                            // }
                            if (post?.quizData?.filter(x => x?.qn?.trim() == "")?.length > 0) {
                                showToast("Please write question before posting", "warning")
                                return
                            }
                            if (post?.quizData?.filter(x => x?.ansKey?.trim() == "" || x?.ansKey == undefined)?.length > 0) {
                                showToast("Please select answer before posting", "warning")
                                return
                            }
                            for (let q of post?.quizData) {
                                if (q?.options?.filter(x => x?.trim() == "")?.length > 0) {
                                    showToast("Please write options before posting", "warning")
                                    return
                                }
                            }
                            for (let q of post?.quizData) {
                                if (q?.point == "" || q?.point == 0) {
                                    showToast("Please add point before posting", "warning")
                                    return
                                }
                            }
                            for (let q of post?.quizData) {
                                if((new Set(q.options)).size !== (q.options).length){
                                    showToast("Remove duplicate answers and try again", "error")
                                    return;
                                }
                            }
                                                        
                            if (moment(post?.scoreStartTime).toDate() > moment(post?.scoreEndTime).toDate() || moment(post?.scoreStartTime).format("YYYY-MM-DD HH:mm") == moment(post?.scoreEndTime).format("YYYY-MM-DD HH:mm")) {
                                showToast("please check score start and end date time", "warning")
                                return
                            }
                        }
                        if (post?.isPollPost) {
                            if (post?.pollOptions?.filter(x => x?.trim() == "")?.length > 0) {
                                showToast("Please donot leave options empty ", "warning")
                                return
                            }
                            if (moment(post?.pollEndTime).toDate() < moment().toDate()) {
                                showToast("poll end time must be a in future", "warning")
                                return
                            }
                        }
                        navigate("/user/postingTo", { state: location.state })
                    }} sx={{ fontSize: pxToRem(16), borderRadius: "5px", marginX: "auto", marginTop: "20px", height: "45px", width: "200px" }} variant="contained">
                        Post
                    </Button>}
                    {post?.isScorePost &&
                        <Box component={"img"} src="assets/posts/1.svg" sx={{ marginY: "20px", marginX: "auto", width: "50px", height: "20px" }} />
                    }
                </Box>
                <Box sx={{ height: "20px" }} />
            </Box>
            <Box sx={{ flex: 1, marginLeft: "4%" }}>
                {post?.isScorePost && <Box sx={{ display: "flex", minHeight: "570px", px: "10px", py: "20px", marginTop: "10px", flexDirection: "column", background: "white", boxShadow: "0px 5px 20px #26262626", borderRadius: "5px", }}>
                    <Typography color={"primary"} sx={{ fontSize: pxToRem(16), fontWeight: "600" }}>How do you want to score in this post?</Typography>
                    <br />
                    <CheckComponent isChecked={post?.scoreBy?.includes("likes")} onChange={v => {
                        if (v) {
                            dispatch(setPostData({ key: "scoreBy", value: [...post?.scoreBy, "likes"] }))
                        } else {
                            dispatch(setPostData({ key: "scoreBy", value: [...post?.scoreBy].filter(x => x != "likes") }))

                        }
                    }} title={"By Liking"} />
                    <br />
                    <CheckComponent onChange={v => {
                        if (v) {
                            dispatch(setPostData({ key: "scoreBy", value: [...post?.scoreBy, "geo"] }))
                        } else {
                            dispatch(setPostData({ key: "scoreBy", value: [...post?.scoreBy].filter(x => x != "geo") }))

                        }
                    }} isChecked={post?.scoreBy?.includes("geo")} title={"By Geo-fencing"} />
                    <br />
                    <CheckComponent onChange={v => {
                        if (v) {
                            dispatch(setPostData({ key: "scoreBy", value: [...post?.scoreBy, "verification"] }))
                        } else {
                            dispatch(setPostData({ key: "scoreBy", value: [...post?.scoreBy].filter(x => x != "verification") }))

                        }
                    }} isChecked={post?.scoreBy?.includes("verification")} title={"By Admin Verification"} />
                    <br />
                    <Button onClick={() => {
                        if (post?.txt?.trim() == "" && !post?.isQuizPost) {
                            showToast("Please write something ", "warning")
                            return
                        }
                        if (post?.isQuizPost) {
                            if (post?.quizTitle?.trim() == "") {
                                showToast("Please add quiz title before posting", "warning")
                                return
                            }
                            // if (post?.quizDesc?.trim() == "") {
                            //     showToast("Please add quiz description before posting", "warning")
                            //     return
                            // }
                            if (post?.quizData?.filter(x => x?.qn?.trim() == "")?.length > 0) {
                                showToast("Please write question before posting", "warning")
                                return
                            }
                            if (post?.quizData?.filter(x => x?.ansKey?.trim() == "" || x?.ansKey == undefined)?.length > 0) {
                                showToast("Please select answer before posting", "warning")
                                return
                            }
                            for (let q of post?.quizData) {
                                if (q?.options?.filter(x => x?.trim() == "")?.length > 0) {
                                    showToast("Please write options before posting", "warning")
                                    return
                                }
                            }
                            for (let q of post?.quizData) {
                                if (q?.point == "" || q?.point == 0) {
                                    showToast("Please add point before posting", "warning")
                                    return
                                }
                            }
                        }
                        if (post?.isPollPost) {
                            if (post?.pollOptions?.filter(x => x?.trim() == "")?.length > 0) {
                                showToast("Please donot leave options empty ", "warning")
                                return
                            }
                            if (moment(post?.pollEndTime).toDate() < moment().toDate()) {
                                showToast("poll end time must be a in future", "warning")
                                return
                            }
                        }
                        if (moment(post?.scoreStartTime).toDate() > moment(post?.scoreEndTime) || moment(post?.scoreStartTime).format("YYYY-MM-DD HH:mm") == moment(post?.scoreEndTime).format("YYYY-MM-DD HH:mm")) {
                            showToast("please check score start and end date time", "warning")
                            return
                        }
                        if (post?.scoreBy?.length == 0) {
                            showToast("please check atleast one score type", "warning")
                            return
                        }
                        navigate("/user/post2", { state: location.state })
                    }} sx={{ fontSize: pxToRem(16), borderRadius: "5px", marginX: "auto", height: "45px", width: "200px" }} variant="contained">
                        Next
                    </Button>
                    <Box sx={{ flex: 1, display: "flex", justifyContent: "center", alignItems: "flex-end" }}>
                        {post?.isScorePost &&
                            <Box component={"img"} src="assets/posts/2.svg" sx={{ marginY: "20px", marginX: "auto", width: "50px", height: "20px" }} />
                        }
                    </Box>
                </Box>}
            </Box>
            <DatePickerModal open={openDate} setOpen={setOpenDate} value={selectedType == "poll" ? post?.pollEndTime : (selectedType == "scoreStart" ? post?.scoreStartTime : post?.scoreEndTime)} onChange={v => {
                if (selectedType == "poll") {
                    dispatch(setPostData({ key: "pollEndTime", value: v }))
                } else if (selectedType == "scoreStart") {
                    dispatch(setPostData({ key: "scoreStartTime", value: v }))
                } else {
                    dispatch(setPostData({ key: "scoreEndTime", value: v }))
                }
            }} typeDate={(selectedType == "scoreStart" || selectedType == "poll") ? "start" : "end"} />
            <TimePickerModal open={openDate1} setOpen={setOpenDate1} value={selectedType == "poll" ? post?.pollEndTime : (selectedType == "scoreStart" ? post?.scoreStartTime : post?.scoreEndTime)} onChange={v => {
                if (selectedType == "poll") {
                    dispatch(setPostData({ key: "pollEndTime", value: v }))
                } else if (selectedType == "scoreStart") {
                    dispatch(setPostData({ key: "scoreStartTime", value: v }))
                } else {
                    dispatch(setPostData({ key: "scoreEndTime", value: v }))
                }
            }} />
        </Box>
    )
}


const Checks = ({ isChecked, setIsChecked }) => {
    return (
        <Box onClick={() => {
            setIsChecked(!isChecked)
        }} component={"img"} sx={{ cursor: "pointer", width: "18px", height: "18px" }} src={isChecked ? "assets/groups/ios-checkbox.svg" : "assets/groups/ios-checkbox-outline.svg"} />
    )
}

const CheckComponent = ({ title, isChecked, onChange }) => {
    return (
        <Box sx={{ minHeight: "60px", width: "100%", p: "10px", display: "flex", alignItems: "center", background: "#0DB1D41A" }}>
            <Box sx={{ marginLeft: "15px", flex: 1, display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                <Typography sx={{ fontWeight: "600", fontSize: pxToRem(14) }}>{title}</Typography>
            </Box>
            <Box onClick={() => {
                onChange(!isChecked)
            }} component={"img"} sx={{ cursor: "pointer", width: "18px", height: "18px" }} src={isChecked ? "assets/groups/ios-checkbox.svg" : "assets/groups/ios-checkbox-outline.svg"} />
        </Box>
    )
}
const PointInput = ({ title, showPoints, value, onChange }) => {
    return (
        <Box sx={{ width: "100%" }}>
            <Typography color={"primary"} textAlign="left" ml={"5px"} fontWeight="600" fontSize={pxToRem(14)}>{title}</Typography>
            <Box sx={{ height: "45px", display: "flex", alignItems: "center", background: "#F6F6F6", marginTop: "5px", borderRadius: "5px", boxShadow: "0px 3px 10px #00000026" }}>
                <Typography sx={{ marginLeft: "5px", flex: 1, textAlign: "left", fontSize: pxToRem(14), fontWeight: "500" }}>
                    {value} {showPoints != false ? "Points" : ""}
                </Typography>
                <Box sx={{ height: "45px", display: "flex", flexDirection: "column", position: "relative", width: "20px" }}>
                    <Box sx={{ height: "25px", position: "absolute", top: 10, width: "20px", objectFit: "contain" }} component="img" src="assets/posts/updown.svg">
                    </Box>
                    <Box onClick={() => {
                        onChange(value + 1)
                    }} sx={{ width: "20px", position: "relative", cursor: "pointer", height: "22.5px" }}>

                    </Box>
                    <Box onClick={() => {
                        if (value > 1) {
                            onChange(value - 1)
                        }

                    }} sx={{ width: "20px", position: "relative", cursor: "pointer", height: "22.5px" }}>

                    </Box>
                </Box>
            </Box>
        </Box>
    )
}