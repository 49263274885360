import { createSlice } from "@reduxjs/toolkit";
import hit from "../services/apiManager";
import { notifications, scores } from "../services/endpoints";

const scoresSlice = createSlice({
    name: "scores",
    initialState: { list: [] },
    reducers: {
        setScoresData: (state, action) => {
            state.list = action.payload
        }
    }
})

export const { setScoresData } = scoresSlice.actions

export const getAllScoreData = () => {
    // return async (dispatch) => {
    //     try {
    //         let res = await hit(scores.all, "get")
    //         if (res?.data) {
    //             let temp = res?.data?.filter(x => (x?.type == 4 || x?.postId != null))
    //             dispatch(setScoresData(temp))
    //         }
    //     } catch (err) {
    //         console.error(err)
    //     }
    // }
}

export default scoresSlice.reducer

