import { Avatar, Box, Button, Typography } from "@mui/material";
import { image_url } from "../../services/endpoints";

export default function GroupCard({style,item,onPressItem}) {
    return (
        <Box sx={[{ boxShadow: "0px 5px 20px #26262626",position:"relative",background:"white",borderRadius:"5px", padding: "5px",width:"50%"},style]}>
            <Box sx={{ height: "80px", borderRadius: "5px", width: "100%", objectFit: "cover" }} component="img" src={image_url+item?.coverPic} />
            <Avatar src={image_url+item?.profilePic} sx={{ height: "50px",border:"2px solid white", width: "50px",position:"absolute",left:"38%" ,top:"55px"}} />
            <Typography sx={{marginTop:"30px",fontWeight:"600"}}>{item?.name}</Typography>
            <Button onClick={onPressItem} sx={{borderRadius:0,marginTop:"10px",marginBottom:"10px"}} variant="contained">Know More</Button>
       </Box>
    )
}