import { Avatar, Box, Button, Typography } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { image_url } from "../../services/endpoints";
import { logoutThunk } from "../../store/auth";
import { clearTeam } from "../../store/team";
import { pxToRem } from "../../theme/typography";
import NotificationPop from "./NotificationPop";
import { clearDraftData } from "../../store/drafts";
import showToast from "../../utils/showToast";
import Modal from '@mui/material/Modal';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "25vw",
    minHeight: "25vh",
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: "5px",
    p: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
};
export default function Header() {
    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const user = useSelector(state => state.auth)?.user
    const notifications = useSelector(state => state?.notification?.list)
    const [looutModalOpen, setLooutModalOpen] = useState(false)
    return (
        <>
            <Box sx={{ height: "140px", px: "5%", pr: "10%", justifyContent: "space-between", display: "flex", boxShadow: "0px 5px 20px #0000001A", background: "linear-gradient(96deg,#BBE9F2,#F1F7ED)", width: "100%" }}>
                <Box sx={{ display: "flex", height: "100%", alignItems: "center" }}>
                    <Avatar
                        src={image_url + user?.profilePic}
                        style={{ height: "80px", width: "80px" }}
                    />
                    <Box sx={{ marginLeft: "15px", display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                        <Typography sx={{ fontWeight: "600", fontSize: pxToRem(20) }}>Hi, {user?.firstName} {user?.lastName}</Typography>
                        <Typography sx={{ fontSize: pxToRem(14) }}>{user?.email}</Typography>
                    </Box>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    {/* <Box component={"img"} sx={{height:"20px"}} src="assets/home/user.png" /> */}
                    <Box aria-describedby={id} onClick={e => {
                        setAnchorEl(e.currentTarget)
                    }} component={"img"} sx={{ height: "20px", marginX: "15%", cursor: "pointer" }} src={notifications?.filter(x => x?.seen == false)?.length > 0 ? "assets/home/noti.png" : "assets/home/notic.png"} />
                    <Button onClick={() => setLooutModalOpen(true)}
                        sx={{ height: "35px", display: "flex", width: "200px", borderRadius: "5px", justifyContent: "center", alignItems: "center" }} variant="contained">
                        <Box component={"img"} src="assets/home/logout.png" sx={{ height: "15px", width: "15px" }} />
                        <Typography sx={{ fontSize: pxToRem(14), marginLeft: "5px", fontWeight: "500" }}>Logout</Typography>
                    </Button>
                </Box>
                <NotificationPop open={open} id={id} anchorEl={anchorEl} handleClose={() => { setAnchorEl(null) }} />
            </Box>

            <Modal
                open={looutModalOpen}
                onClose={() => setLooutModalOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <h6 style={{ marginTop: 20 }}>Are you sure want to Logout?</h6>
                    <div className="d-flex" style={{ marginTop: 15 }}>
                        <Button sx={{ fontSize: pxToRem(14), fontWeight: "400", marginTop: "5px", borderRadius: "5px", marginX: "auto", height: "45px", width: "100px", }} variant="contained"
                            onClick={() => {
                                dispatch(logoutThunk())
                                dispatch(clearTeam())
                                dispatch(clearDraftData())
                                navigate("/landing", { replace: true })
                                showToast("Logout successfully", "success")
                            }}
                        >
                            Yes
                        </Button>
                        <Button style={{ marginLeft: 23 }} sx={{ fontSize: pxToRem(14), fontWeight: "400", marginTop: "5px", borderRadius: "5px", marginX: "auto", height: "45px", width: "100px", backgroundColor: 'red' }} variant="contained" onClick={() => setLooutModalOpen(false)}>
                            No
                        </Button>
                    </div>
                </Box>
            </Modal>
        </>
    )
}