import { Avatar, Badge, Box, Button, Typography } from "@mui/material";
import { minHeight } from "@mui/system";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import SearchBar from "../components/Home/SearchBar";
import EnterTime from "../components/EnterTime";
import { pxToRem } from "../theme/typography";
import DenyReasonModal from "../components/DenyReasonModal";
import Input, { DropDownInput } from "../components/Login/Input";
import { setPostData } from "../store/post";
import hit from "../services/apiManager";
import { categories } from "../services/endpoints";
import showToast from "../utils/showToast";

export default function Post2() {
    const navigate = useNavigate()
    const [isScorePost, setIsScorePost] = useState(false)
    const post = useSelector(state => state.post)
    const dispatch = useDispatch()
    const [categoriesOpt, setCategoriesOpt] = useState([])
    const location=useLocation()
    useEffect(() => {
        getCategories()
    }, [])

    const getCategories = async () => {
        try {
            let res = await hit(categories, "get")
            if (res?.err) {
                showToast(res?.msg)
            } else {
                setCategoriesOpt(res?.data)
            }
        } catch (e) {
        } finally {
        }
    }

    return (
        <Box sx={{ px: "5%", pt: "20px", display: "flex", pr: "10%", overflowY: "auto", flex: 1, width: "100%", background: "linear-gradient(119deg,#E6F6F9,white,rgb(253,243,213))" }}>
            <Box sx={{ flex: 1, minHeight: "100px" }}>
                <Box sx={{ display: "flex", minHeight: "570px", px: "10px", py: "20px", marginTop: "10px", flexDirection: "column", background: "white", boxShadow: "0px 5px 20px #26262626", borderRadius: "5px", }}>

                    {post?.scoreBy?.includes("likes") && <><Typography sx={{ fontSize: pxToRem(18), marginBottom: "15px", fontWeight: "600", textAlign: "left" }}>By Liking</Typography>
                        <PointInput value={post?.scorePoint} onChange={v => {
                            dispatch(setPostData({ key: "scorePoint", value: v }))
                        }} title={"How many points do people get for liking this post?"} />
                    </>}
                    {post?.scoreBy?.includes("geo") && <><Typography sx={{ fontSize: pxToRem(18), marginY: "15px", fontWeight: "600", textAlign: "left" }}>By Geo-fencing</Typography>
                        <PointInput value={post?.scorePoint2} onChange={v => {
                            dispatch(setPostData({ key: "scorePoint2", value: v }))
                        }} title={"How many points do people get for geo-fencing this post?"} />
                        <Typography sx={{ fontWeight: "600", textAlign: "left", marginTop: "10px" }}>Location: {post?.eventLocation?.address}</Typography>
                        <Box sx={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                        <Button sx={{}} onClick={() => {
                            navigate("/user/map")
                        }}>Add Location</Button>
                        </Box>
                    </>
                    }
                    {post?.scoreBy?.includes("verification") && <>
                        <Typography sx={{ fontSize: pxToRem(18), marginY: "15px", fontWeight: "600", textAlign: "left" }}>By Admin Verification</Typography>
                        <PointInput value={post?.scorePoint1} onChange={v => {
                            dispatch(setPostData({ key: "scorePoint1", value: v }))
                        }} title={"How many points do people get for commenting on this post?"} />
                        <Box sx={{ marginY: "15px" }} />
                        <PointInput value={post?.scoreCount} onChange={v => {
                            dispatch(setPostData({ key: "scoreCount", value: v }))
                        }} showPoints={false} title={"How many times can people score on this post?"} />
                    </>}
                    {/* <Box sx={{ marginY: "15px", marginTop: "20px", display: "flex" }}>
                        <Button onClick={() => {
                            navigate(-1)
                        }} variant="contained" sx={{ flex: 1, height: "45px", background: "#F5BA16" }}>Go back</Button>
                        <Button variant="contained" sx={{ flex: 1, height: "45px", marginLeft: "20px" }}>Next</Button>
                    </Box> */}
                    {/* <Box component={"img"} src="assets/posts/1.svg" sx={{ marginY: "20px", marginX: "auto", width: "50px", height: "20px" }} /> */}

                </Box>
                <Box sx={{ height: "20px" }} />
            </Box>
            <Box sx={{ flex: 1, marginLeft: "4%" }}>
                <Box sx={{ display: "flex", minHeight: "570px", px: "10px", py: "20px", marginTop: "10px", flexDirection: "column", background: "white", boxShadow: "0px 5px 20px #26262626", borderRadius: "5px", }}>
                    <DropDownInput value={post?.category} onChange={v => {
                        dispatch(setPostData({ key: "category", value: v }))
                    }} options={categoriesOpt?.map(x => x.name)} img={"assets/posts/arrow_ios.svg"} title={"What category do you want the points to be in?"} />
                    <br />
                    <Typography sx={{ fontWeight: "600" }}>OR</Typography>

                    <br />
                    <Input value={post?.category} onChange={v => {
                        dispatch(setPostData({ key: "category", value: v }))
                    }} inputContainerStyle={{ background: "#F6F6F6" }} titleStyle={{ textAlign: "left", color: "#0BB2D0" }} placeholder="Type your own Category..." showPoints={false} title={"Category"} />
                    <br />
                    <Box sx={{ marginY: "15px", marginTop: "20px", display: "flex" }}>
                        <Button onClick={() => {
                            navigate(-1)
                        }} variant="contained" sx={{ flex: 1, height: "45px", background: "#F5BA16" }}>Go back</Button>
                        <Button onClick={() => {
                            if (post?.scoreBy?.includes("geo")) {
                                if (post?.eventLocation?.address == "") {
                                    showToast("Please select event location", "warning")
                                    return
                                }
                            }
                            if (post?.category?.trim() == "") {
                                showToast("Please select category", "warning")
                                return
                            }
                            if(location?.state?.groupDetail?._id){
                                dispatch(setPostData({ key: "groups", value: [location?.state?.groupDetail?._id] }))
                                navigate("/user/choose",{state:location.state})
                            }else{
                                navigate("/user/postingTo")
                            }
                            
                        }} variant="contained" sx={{ flex: 1, height: "45px", marginLeft: "20px" }}>Post</Button>
                    </Box>
                    <Box sx={{ flex: 1, display: "flex", justifyContent: "center", alignItems: "flex-end" }}>
                        {isScorePost &&
                            <Box component={"img"} src="assets/posts/2.svg" sx={{ marginY: "20px", marginX: "auto", width: "50px", height: "20px" }} />
                        }
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}




const PointInput = ({ title, showPoints, value, onChange }) => {
    return (
        <Box sx={{ width: "100%" }}>
            <Typography color={"primary"} textAlign="left" ml={"5px"} fontWeight="600" fontSize={pxToRem(14)}>{title}</Typography>
            <Box sx={{ height: "45px", display: "flex", alignItems: "center", background: "#F6F6F6", marginTop: "5px", borderRadius: "5px", boxShadow: "0px 3px 10px #00000026" }}>
                <Typography sx={{ marginLeft: "5px", flex: 1, textAlign: "left", fontSize: pxToRem(14), fontWeight: "500" }}>
                    {value} {showPoints != false ? "Points" : ""}
                </Typography>
                <Box sx={{ height: "45px", display: "flex", flexDirection: "column", position: "relative", width: "20px" }}>
                    <Box sx={{ height: "25px", position: "absolute", top: 10, width: "20px", objectFit: "contain" }} component="img" src="assets/posts/updown.svg">
                    </Box>
                    <Box onClick={() => {
                        onChange(value + 1)
                    }} sx={{ width: "20px", position: "relative", cursor: "pointer", height: "22.5px" }}>

                    </Box>
                    <Box onClick={() => {
                        if (value > 1) {
                            onChange(value - 1)
                        }

                    }} sx={{ width: "20px", position: "relative", cursor: "pointer", height: "22.5px" }}>

                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

const PointInput2 = ({ title, showPoints }) => {
    const [count, setCount] = useState(0)
    return (
        <Box sx={{ width: "100%" }}>
            <Typography color={"primary"} textAlign="left" ml={"5px"} fontWeight="600" fontSize={pxToRem(14)}>{title}</Typography>
            <Box sx={{ height: "45px", display: "flex", alignItems: "center", background: "#F6F6F6", marginTop: "5px", borderRadius: "5px", boxShadow: "0px 3px 10px #00000026" }}>
                <Typography sx={{ marginLeft: "5px", flex: 1, textAlign: "left", fontSize: pxToRem(14), fontWeight: "500" }}>
                    Community/Relationship Building
                </Typography>
                <Box sx={{ height: "45px", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", position: "relative", width: "20px" }}>
                    <Box sx={{ height: "15px", width: "15px", objectFit: "contain" }} component="img" src="assets/posts/arrow_ios.svg" />
                </Box>
            </Box>
        </Box>
    )
}
