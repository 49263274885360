import { Avatar, Badge, Box, Button, Typography, useTheme } from "@mui/material";
import { collection, doc, getDoc, onSnapshot, setDoc, updateDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import hit from "../services/apiManager";
import { image_url, updateUser } from "../services/endpoints";
import { getUserDetailThunk } from "../store/auth";
import { setLoading } from "../store/load";
import { getAllTeamThunk } from "../store/team";
import { pxToRem } from "../theme/typography";
import { firebaseDb } from "../utils/firebaseInit";
import showToast from "../utils/showToast";
import moment from "moment";

export default function ChatSettings() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const user = useSelector(state => state?.auth.user)
    const groups = useSelector(state => state?.team?.team)
    const [allGroups, setAllGroups] = useState([]) //todisplay
    const [selectedGroup, setSelectedGroup] = useState(null)

    useEffect(() => {
        dispatch(getAllTeamThunk())
    }, [])

    useEffect(() => {
        let teamsMe = groups?.filter(x => x.createdBy == user?._id)
        setAllGroups(teamsMe)
        if (teamsMe?.length > 0) {
            setSelectedGroup(teamsMe[0])
        } else {
            setSelectedGroup(null)
        }
    }, [groups, user])
    return (
        <Box sx={{ px: "5%", pt: "20px", display: "flex", pr: "10%", overflowY: "auto", flex: 1, width: "100%", background: "linear-gradient(119deg,#E6F6F9,white,rgb(253,243,213))" }}>
            <Box sx={{ flex: 1, minHeight: "100px" }}>
                <Typography sx={{ textAlign: "left", fontWeight: "600" }}>Chat Settings</Typography>
                <Box sx={{ display: "flex", px: "10px", py: "20px", marginTop: "10px", flexDirection: "column", background: "white", boxShadow: "0px 5px 20px #26262626", borderRadius: "5px", minHeight: "100px" }}>
                    {allGroups?.map((item, index) => {
                        return <GNotiComp selectedGroup={selectedGroup} setSelectedGroup={setSelectedGroup} item={item} style={{ marginTop: "20px" }} />
                    })}
                </Box>
            </Box>
            <Box sx={{ flex: 1, marginLeft: "5%", }}>
                {selectedGroup != null && <GroupSettings team={selectedGroup} />}
            </Box>
        </Box>
    )
}



const GNotiComp = ({ item, style, setSelectedGroup, selectedGroup }) => {
    const theme = useTheme()
    return (
        <Box onClick={() => {
            setSelectedGroup(null)
            setTimeout(() => {
                setSelectedGroup(item)
            }, 100)

        }} sx={[{ height: "60px", cursor: "pointer", borderRadius: "5px", border: "2px solid", borderColor: selectedGroup?._id == item?._id ? theme.palette.primary?.main : "transparent", display: "flex", alignItems: "center", justifyContent: "space-between", px: "10px", background: "white", boxShadow: "0px 5px 20px #26262626" }, style]}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <Avatar src={image_url + item?.profilePic} />
                <Typography sx={{ fontWeight: "500", marginLeft: "10px" }}>{item?.name}</Typography>
            </Box>
        </Box>
    )
}

const GroupSettings = ({ team }) => {
    const userMe = useSelector(state => state?.auth)?.user
    const [item, setIteam] = useState(team)
    const [verifyAllMsg, setVerifyAllMsg] = useState(false)
    const [oneOne, setOneOne] = useState(true)
    const [oneAdmin, setOneAdmin] = useState(true)
    const [roomId, setRoomId] = useState(item?._id)
    const [members, setMembers] = useState([])
    const [access, setAccess] = useState(null)
    const [gChatFData, setgChatFData] = useState({})
    const dispatch = useDispatch()
    const [group, setGroup] = useState({})
    const getChatRef = (room) => {
        return doc(firebaseDb, "chats", room)
    }

    useEffect(() => {
        setIteam(team)
    }, [team])

    useEffect(() => {
        getAllData()
        if (item) {
            setRoomId(item?._id)
            getGroupDetails(item?._id)
        }
    }, [item])

    useEffect(() => {
        if (members?.length > 0 && item?._id) {
            if (gChatFData.accessUpdate) {
                let gaccess = [...members.map(x => x?._id)]
                let nona = gaccess.filter(x => !gChatFData.access?.map(x => x?._id).includes(x)).map(x => ({ _id: x, access: true }))
                setAccess([...gChatFData.access, ...nona])
            } else {
                setAccess(members.map(x => ({ _id: x?._id, access: true })))
            }
        }

    }, [gChatFData, item, members])


    useEffect(() => {
        let sub = onSnapshot(getChatRef(roomId),
            async (querySnapshot) => {
                let d = await getDoc(getChatRef(roomId))
                if (d.exists() && d?.data()) {
                    setgChatFData(d?.data())
                }
            }, // onNext
            (error) => console.error(error), // onError
        )
        return () => {
            sub()
        }
    }, [])

    const getGroupDetails = async (id) => {
        try {
            let res = await hit("/teams/" + id, "get")
            if (!res.err) {
                if (res?.data?.members) {
                    setMembers(res?.data?.members)
                    setGroup(res?.data)
                }
            } else {

            }
        } catch (err) {

        } finally {

        }
    }


    const getAllData = () => {
        let room = item?._id
        getDoc(getChatRef(room)).then(chatData => {
            let data = chatData?.data()
            if (data?.verifyAll == true) {
                setVerifyAllMsg(true)
            }
            if (data?.oneOne == false) {
                setOneOne(false)
            } else {
                setOneOne(true)
            }
            if (data?.oneAdmin == false) {
                setOneAdmin(false)
            } else {
                setOneAdmin(true)
            }


        })
    }


    const verifyAllMessage = async () => {

        if (verifyAllMsg == true) {
            setDoc(getChatRef(roomId), {
                verifyAll: false,
                verification: false,
                group: group,
                type: "group",
                lastMessage: gChatFData?.lastMessage ?? { text: "", type: "text", createdAt: moment().unix() },
                userIds: group?.members?.map(x => x?._id),
            }, { merge: true })

            onSnapshot(collection(firebaseDb, "chats", roomId, "messages"), (querySnapshot) => {
                for (let docy of querySnapshot.docs) {
                    updateDoc(doc(firebaseDb, "chats", roomId, "messages", docy.id), {
                        approved: 1
                    })
                }
            })
        } else {
            setDoc(getChatRef(roomId), {
                verifyAll: true,
                verification: true,
                type: "group",
                lastMessage: gChatFData?.lastMessage ?? { text: "", type: "text", createdAt: moment().unix() },
                userIds: group?.members?.map(x => x?._id),
            }, { merge: true })
        }
        setVerifyAllMsg(!verifyAllMsg)
    }

    const changeOneOne = async () => {
        if (oneOne == true) {

            setDoc(getChatRef(roomId), {
                oneOne: false,
                group: group,
                type: "group",
                lastMessage: gChatFData?.lastMessage ?? { text: "", type: "text", createdAt: moment().unix() },
                userIds: group?.members?.map(x => x?._id),
            }, { merge: true })
        } else {
            setDoc(getChatRef(roomId), {
                oneOne: true,
                group: group,
                type: "group",
                lastMessage: gChatFData?.lastMessage ?? { text: "", type: "text", createdAt: moment().unix() },
                userIds: group?.members?.map(x => x?._id),
            }, { merge: true })
        }
        setOneOne(!oneOne)
    }

    const changeOneAdmin = async () => {
        if (oneAdmin == true) {
            setDoc(getChatRef(roomId), {
                oneAdmin: false,
                group: group,
                type: "group",
                lastMessage: gChatFData?.lastMessage ?? { text: "", type: "text", createdAt: moment().unix() },
                userIds: group?.members?.map(x => x?._id),
            }, { merge: true })
        } else {
            setDoc(getChatRef(roomId), {
                oneAdmin: true,
                group: group,
                type: "group",
                lastMessage: gChatFData?.lastMessage ?? { text: "", type: "text", createdAt: moment().unix() },
                userIds: group?.members?.map(x => x?._id),
            }, { merge: true })
        }
        setOneAdmin(!oneAdmin)
    }
    const updateAccess = (data = []) => {
        dispatch(setLoading(true))
        setDoc(getChatRef(roomId), {
            access: data,
            group: group,
            type: "group",
            userIds: group?.members?.map(x => x?._id),
            accessUpdate: true,
            lastMessage: gChatFData?.lastMessage ?? { text: "", type: "text", createdAt: moment().unix() },
        }, { merge: true }).then(() => {
            setAccess(data)
        }).finally(() => {
            dispatch(setLoading(false))
        })
    }
    return (
        <><Typography sx={{ textAlign: "left", fontWeight: "600" }}>Group Chat Settings</Typography>
            <Box sx={{ display: "flex", px: "10px", py: "20px", marginTop: "10px", flexDirection: "column", background: "white", boxShadow: "0px 5px 20px #26262626", borderRadius: "5px", minHeight: "100px" }}>
                <NotiComp title={'Verify every message'} on={verifyAllMsg} onChange={() => { verifyAllMessage() }} />
                <NotiComp title={'1-1 chat with everyone'} style={{ marginTop: "20px" }} on={oneOne} onChange={() => { changeOneOne() }} />
                <NotiComp title={'1-1 chat with admin'} style={{ marginTop: "20px" }} on={oneAdmin} onChange={() => { changeOneAdmin() }} />
                <Typography sx={{ textAlign: "left", fontWeight: "600", marginTop: "30px", marginBottom: "10px" }}>Group Access</Typography>
                {members?.map((item) => {
                    return (<Box sx={{ marginBottom: "16px", paddingY: "8px", paddingX: "8px", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", background: "white", borderRadius: "4px", boxShadow: "0px 5px 20px #26262626", }}>
                        <Box sx={{ flexDirection: "row", display: "flex", alignItems: "center" }}>
                            <Box component={"img"} src={image_url + item?.profilePic} style={{ height: "60px", width: "60px", borderRadius: "60px" }} />
                            <Box sx={{ textAlign: "left" }}>
                                <Typography sx={{ fontSize: "14px", fontWeight: "600", marginLeft: "16px" }}>{item?.firstName} {item?.lastName}</Typography>
                                <Typography sx={{ fontSize: "12px", fontWeight: "400", marginLeft: "16px" }}>{item?.email}</Typography>

                            </Box>
                        </Box>
                        <Box sx={{ cursor: "pointer" }} onClick={() => {
                            if (access?.find(x => x._id == item?._id)?.access) {
                                updateAccess(access.map(x => {
                                    if (x?._id == item?._id) {
                                        return ({ ...x, access: false })
                                    }
                                    return x
                                }))
                            } else {
                                updateAccess(access.map(x => {
                                    if (x?._id == item?._id) {
                                        return ({ ...x, access: true })
                                    }
                                    return x
                                }))
                            }
                        }} component={"img"} src={access?.find(x => x._id == item?._id)?.access ? "assets/notis/on.svg" : "assets/notis/off.svg"} />

                    </Box>)
                })}
            </Box></>
    )
}

const NotiComp = ({ title, on, onChange, style }) => {
    return (
        <Box sx={[{ height: "55px", borderRadius: "5px", display: "flex", alignItems: "center", justifyContent: "space-between", px: "10px", background: "white", boxShadow: "0px 5px 20px #26262626" }, style]}>
            <Typography sx={{ fontWeight: "500" }}>{title}</Typography>
            <Box sx={{ cursor: "pointer" }} onClick={() => onChange()} component={"img"} src={on ? "assets/notis/on.svg" : "assets/notis/off.svg"} />
        </Box>
    )
}